<template>
  <div class="global-panel">
    <div class="title-row">
      <h1>
        Admin
      </h1>
    </div>
    <div class="impersonate">
      <h2 class="subtitle">
        Impersonate user
      </h2>
      <label for="email" class="global-label email-label">User email</label>
      <input
        class="global-input email-input"
        type="email"
        name="email"
        novalidate
        autofocus
        spellcheck="false"
        autocapitalize="off"
        v-model="email"
      >
      <swift-button
        color="blue"
        @click.native="impersonate()"
      >
        Impersonate
      </swift-button>
    </div>

  </div>
</template>

<script>
// import AuthenticationApi from '../api/AuthenticationApi.js'
import SwiftButton from './SwiftButton.vue'

export default {
  components: {
    SwiftButton
  },
  data () {
    return {
      email: ''
    }
  },
  methods: {
    async impersonate () {
      await this.$store.dispatch('impersonate', { email: this.email })
    }
  }
}
</script>

<style scoped>
.title-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.subtitle {
  margin-bottom: 20px;
}

.email-input {
  margin-bottom: 10px;
}

.impersonate {
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;
  max-width: 300px;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #e0e4eb;
  margin-bottom: 50px;
}

</style>
