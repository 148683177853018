<template>
  <div id="app" class="app">
    <transition name="notification">
      <swift-notification v-if="$store.state.notifications.notifications.length"/>
    </transition>
    <div id="portal-target"></div>
    <div class="app-after-modals" v-show="!$store.state.showModal || $store.state.showModal && !$store.state.userIsMobile">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import SwiftNotification from './components/SwiftNotification.vue'

export default {
  components: {
    SwiftNotification
  },
  mounted () {
    this.watchScreenWidth()

    // change title to easily identify dev env
    if (process.env.NODE_ENV === 'development') {
      document.title = 'Swiftbug - Development'
    }
    if (process.env.VUE_APP_MODE === 'staging') {
      document.title = 'Swiftbug - Staging'
    }
  },
  methods: {
    setUserDevice (mediaQuery) {
      if (mediaQuery.matches) { // If media query matches
        this.$store.commit('setUserToMobile')
      } else {
        this.$store.commit('setUserToDesktop')
      }
    },
    watchScreenWidth () {
      var mediaQuery = window.matchMedia('(max-width: 1025px)')
      this.setUserDevice(mediaQuery) // Callss listener function at first run
      mediaQuery.addListener(this.setUserDevice) // Attach listener to medai query that cals function on resize
    }
  }
}
</script>

<style scoped>
.app {
  height: 100%;
}
.app-after-modals {
  height: 100%;
}
.fade-enter-active {
  transition-property: opacity;
  transition-duration: 0.15s;
}

.fade-enter {
  opacity: 0
}

.notification-enter-active, .notification-leave-active {
  transition: all 0.5s ease;
}

.notification-enter, .notification-leave-to {
  opacity: 0;
  transform: translateX(500px)
}

</style>
