<template>
  <div class="register-positioner">
    <div class="logo">
      <icon-logo-full width="100px" height="69px" fill="#1764d9" />
    </div>
    <div class="register">
      <form class="register-form"
            id="register-form"
            novalidate
            @submit.prevent="register()">
        <div class="register-title">Join {{ organization }}</div>
        <label for="email" class="global-label email-label">Email</label>
        <div class="global-form-error" v-if="emailErrors.length">
          <div v-for="error in emailErrors" :key="error.index">
            {{error}}
          </div>
        </div>
        <input
          class="global-input"
          type="email"
          name="email"
          id="email"
          spellcheck="false"
          autocapitalize="off"
          disabled
          v-model="email"
          placeholder="name@company.com"
          :class=" { 'global-input-invalid': emailErrors.length}"
          autofocus
        >
        <label for="password" class="global-label password-label">Choose Password</label>
        <div class="global-form-error" v-if="passwordErrors.length">
          <div v-for="error in passwordErrors" :key="error.index">
            {{error}}
          </div>
        </div>
        <input
          class="global-input"
          type="password"
          name="password"
          id="password"
          novalidate
          spellcheck="false"
          autocapitalize="off"
          v-model="password"
          placeholder="Minimum 8 characters"
          :class=" { 'global-input-invalid': passwordErrors.length}"
        >

        <swift-button
          class="register-button"
          color="blue"
          size="large"
          shadow="shadow"
          type="submit"
          :is-loading="inviteIsLoading"
        >
          Accept invitation
        </swift-button>

      </form>
    </div>
  </div>
</template>

<script>
import SwiftButton from './SwiftButton.vue'
import IconLogoFull from './icons/IconLogoFull.vue'

export default {
  components: {
    SwiftButton,
    IconLogoFull
  },
  created () {
    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }
    if (this.$route.query.organization) {
      this.organization = this.$route.query.organization
    }
    if (this.$route.params.inviteToken) {
      this.inviteToken = this.$route.params.inviteToken
    }
  },
  data () {
    return {
      organization: '',
      email: '',
      password: '',
      inviteToken: '',
      emailErrors: [],
      passwordErrors: [],
      inviteIsLoading: false
    }
  },
  methods: {
    async register () {
      if (!this.checkPassword()) {
        return
      }

      this.inviteIsLoading = true
      try {
        await this.$store.dispatch('registerInvite', {
          email: this.email,
          password: this.password,
          inviteToken: this.inviteToken
        })
      } catch (error) {
        this.inviteIsLoading = false
      }
      this.inviteIsLoading = false
    },
    checkPassword () {
      this.passwordErrors = []

      if (!this.password) {
        this.passwordErrors.push('Password required')
        return false
      }

      if (this.password.length < 8) {
        this.passwordErrors.push('Password should be at least 8 characters long')
      }

      if (!this.passwordErrors.length) {
        return true
      }
    }
  }

}
</script>

<style scoped>

.register-positioner {
  margin-top: -5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  margin-bottom: 38Px;
}

.register {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e0e4eb;
  width: 360px;
  display: flex;
  flex-direction: column;
  padding: 36px;
  box-shadow: 0px 2px 12px hsla(216, 18%, 19%, 0.1);
}

.register-form {
  display: flex;
  flex-direction: column;
}

.register-title {
  display: flex;
  justify-content: center;
  color: #36404e;
  font-size: 26px;
  font-weight: 600;
}

.email-label {
  margin-top: 15px;
}

.password-label {
  margin-top: 15px;
}

.register-button {
  width: 100%;
  margin-top: 15px;
}

.login-remark {
  margin-top: 20px;
  padding-top: 10px;
}

</style>
