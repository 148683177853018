<template>
  <div class="browser-extension"  draggable="false">
    <!--img.src for screenshot will be injected by browser extension-->
    <img src='' id="image-from-extension" draggable="false" style="display:none"/>
    <browser-extension-drawing-canvas
      v-if="screenshotIsLoaded"
      :screenshot-uri="screenshotUri"
      :screenshot-info="screenshotInfo"
      :load-canvas-file="loadCanvasFile"
      @updated-canvas-file="createTicket($event)"
    />
    <div class="screenshot-loading" v-if="!screenshotIsLoaded">
      <!--placeholder if screenshot is not loaded-->
    </div>
    <div class="settings" v-if="!$store.state.authentication.isUserLoggedIn">
      Please login
    </div>
    <div class="settings" v-if="!$store.state.organization.organizationIsLoaded">
      loading...
    </div>
    <div class="settings" v-if="$store.state.organization.organizationIsLoaded">
      <div class="setting">
        <div class="global-label label">
          Title *
        </div>
        <div class="global-form-error" v-if="titleErrors.length">
          <div v-for="error in titleErrors" :key="error.index">
            {{error}}
          </div>
        </div>
        <input class="global-input title"
               v-model="title"
               placeholder="Add a title"
               ref="title"
               :class=" { 'global-input-invalid': titleErrors.length}">
      </div>

      <div class="setting">
        <div class="global-label label">
          Description
        </div>
        <textarea class="global-input-resizeable description" v-model="description" placeholder="Add a desription.." ref="textarea" @input="resizeTextarea()">
        </textarea>
      </div>

      <div class="setting" v-if="$store.state.projects.projectsAreLoaded">
        <div class="global-label label">
          Project *
        </div>
        <swift-select-project
          :initial-project="getCurrentProject"
          :set-initial-project="true"
          @selectedProject="setProjectIdAndReset($event.id)" />
      </div>

      <div class="setting" v-if="$store.state.status.statuses.length">
        <div class="global-label label">
          Status
        </div>
        <div class="setting-input">
          <swift-select-status
            v-if="$store.state.status.statusesAreLoaded"
            @selectedStatus="setCreateTicketStatus($event)" />
        </div>
      </div>

      <div class="setting" v-if="$store.state.status.statuses.length">
        <div class="global-label label">
          Assign to
        </div>
        <swift-select-assignee
          :initial-assignee="$store.state.user.user"
          :set-initial-assignee="true"
          @selectedAssignee="setCreateTicketAssignee($event)"  />
      </div>

      <div class="setting" v-if="$store.state.status.statuses.length">
        <div class="global-label label">
          Priority
        </div>
        <swift-select-priority
          @selectedPriority="setCreateTicketPriorityValue($event.value)" />
      </div>

      <div class="submit-container">
        <swift-button color="blue" size="large" shadow="shadow" @click.native="handleSubmit()" :is-loading="submitIsLoading">Submit ticket</swift-button>
      </div>
    </div>
  </div>
</template>
<script>
import SwiftButton from './SwiftButton.vue'
import SwiftSelectProject from './SwiftSelectProject.vue'
import SwiftSelectStatus from './SwiftSelectStatus.vue'
import SwiftSelectAssignee from './SwiftSelectAssignee.vue'
import SwiftSelectPriority from './SwiftSelectPriority.vue'
import BrowserExtensionDrawingCanvas from './BrowserExtensionDrawingCanvas.vue'
import UploadApi from '../api/UploadApi.js'

export default {
  components: {
    SwiftButton,
    SwiftSelectStatus,
    SwiftSelectProject,
    SwiftSelectAssignee,
    SwiftSelectPriority,
    BrowserExtensionDrawingCanvas
  },
  data: function () {
    return {
      submitIsLoading: false,
      screenshotUri: '',
      screenshotInfo: {
        url: String,
        title: String,
        tabHeight: Number,
        tabWidth: Number,
        browser: String,
        os: String,
        language: String,
        cookieEnabled: Boolean,
        devicePixelRatio: Number,
        naturalHeight: Number,
        naturalWidth: Number
      },
      screenshotIsLoaded: false,
      loadCanvasFile: false,
      titleErrors: []
    }
  },
  async created () {
    await this.$store.dispatch('getOrganizationUsersProjectsUser')
    await this.$store.dispatch('getStatusesOfProject', this.$store.state.projects.currentProjectId)
  },
  mounted () {
    let img = document.getElementById('image-from-extension')

    // wait for image to load than import all info into vue
    img.addEventListener('load', (event) => {
      if (event.target.src && event.target.src !== '') {
        // save img uri to vue
        this.screenshotUri = event.target.src
        // get and parse screenshot data than save to vue
        let screenshotInfoJson = event.target.getAttribute('data-screenshot-info')
        this.screenshotInfo = JSON.parse(screenshotInfoJson)
        this.screenshotInfo.naturalHeight = img.naturalHeight
        this.screenshotInfo.naturalWidth = img.naturalWidth

        this.screenshotIsLoaded = true
      } else {
        const errorNotification = {
          errorText: 'Could not process screenshot from browser extension'
        }
        this.$store.dispatch('createNotification', errorNotification)
      }
    })
  },
  computed: {
    title: {
      get () {
        return this.$store.state.createTicket.title
      },
      set (value) {
        this.$store.commit('updateCreateTicketTitle', value)
      }
    },
    getCurrentProject () {
      return this.$store.getters.getProjectById(this.$store.state.projects.currentProjectId)
    },
    description: {
      get () {
        return this.$store.state.createTicket.description
      },
      set (value) {
        this.$store.commit('updateCreateTicketDescription', value)
      }
    }
  },
  methods: {
    async setProjectIdAndReset (id) {
      this.$store.commit('setCreateTicketProjectId', id)
      this.$store.commit('setCurrentProjectId', id)

      // reset all things that change with new project
      this.setCreateTicketStatus(null)
      this.setCreateTicketAssignee(null)

      // relaod data
      await this.$store.dispatch('getStatusesOfProject', this.$store.state.projects.currentProjectId)
    },
    async uploadFiles (files) {
      var _this = this
      // request s3 signed URL and the S3 url to the file after it will be uploaded from server
      async function getAllS3uploadInfo (files) {
        var s3UploadInfos = {}
        let i = 0
        for (const file of files) {
          i++
          try {
            // check file size
            if (file.size > 5000000) {
              const notification = {
                errorText: 'Exceeds maximum file size of 5 MB'
              }
              _this.$store.dispatch('createNotification', notification)
              throw new Error('Exceeds maximum file size of 5 MB')
            }

            // check file type
            if (file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/svg+xml') {
              // do nothing and continue
            } else {
              const notification = {
                errorText: `Could not upload file. The file type is not allowed. Please only upload jpeg, png, svg or gif files.`
              }
              _this.$store.dispatch('createNotification', notification)
              throw new Error('Filetype is not allowed')
            }

            // get uploadinfo
            s3UploadInfos[i] = (await UploadApi.getS3UploadInfo(file)).data
          } catch (error) {
            throw new Error(error) // this error will stop the outer try catch block
          }
        }
        return s3UploadInfos
      }
      async function uploadAllFiles (files, s3UploadInfos) {
        let i = 0
        var uploadedFiles = []
        for (const file of files) {
          i++
          try {
            await UploadApi.uploadFile(file, s3UploadInfos[i].s3SignedUrl)

            s3UploadInfos[i].urlAfterUpload = encodeURI(s3UploadInfos[i].urlAfterUpload)
          } catch (error) {
            throw new Error(error) // this error will stop the outer try catch block
          }
          const uploadedFile = {
            name: file.name,
            size: file.size,
            type: file.type,
            url: s3UploadInfos[i].urlAfterUpload
          }
          uploadedFiles.push(uploadedFile)
        }
        return uploadedFiles
      }
      async function uploadFiles (files) {
        try {
          var s3UploadInfos = await getAllS3uploadInfo(files)
          const uploadedFiles = await uploadAllFiles(files, s3UploadInfos)
          return uploadedFiles
        } catch (error) {
          throw new Error(error)
        }
      }

      try {
        const uploadedFiles = await uploadFiles(files)
        return uploadedFiles
      } catch (error) {
        throw new Error(error)
      }
    },
    convertImageUriToFile () {
      function dataURItoBlob (dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1])

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length)
        var ia = new Uint8Array(ab)
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ab], { type: mimeString })
      }

      try {
        let blob = dataURItoBlob(this.screenshotUri)
        return blob
      } catch (error) {
        throw new Error('could not generate blob file from uri' + error)
      }
    },
    handleSubmit () {
      // triggers the annoationcanvas component to update svg file via this prop change
      this.loadCanvasFile = !this.loadCanvasFile
    },
    async createTicket (canvasFile) {
      if (!this.checkTitle()) {
        return
      }

      this.submitIsLoading = true

      try {
        // upload image attachment
        canvasFile.name = this.screenshotInfo.title
        let uploadedFile = (await this.uploadFiles([canvasFile]))[0]

        // add final img with annoation url to ticket
        this.$store.commit('pushCreateTicketAttachments', [uploadedFile])

        // create ticket
        const response = await this.$store.dispatch('createTicket')
        const newTicketId = response.data.id

        // route to newly created ticket

        this.$router.push({
          name: 'ticket-modal',
          params: {
            organizationName: this.$store.state.organization.organization.name,
            projectId: this.$store.state.projects.currentProjectId.toString(),
            ticketId: newTicketId.toString()
          } })

        this.submitIsLoading = false
      } catch (error) {
        const errorNotification = {
          errorText: 'An error occured trying to submit your ticket.'
        }
        this.$store.dispatch('createNotification', errorNotification)
        this.submitIsLoading = false
      }
    },
    resizeTextarea () {
      this.$refs.textarea.style.height = ''
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px'
    },
    setCreateTicketPriorityValue (priorityValue) {
      this.$store.commit('setCreateTicketPriorityValue', priorityValue)
    },
    setCreateTicketAssignee (assignee) {
      this.$store.commit('setCreateTicketAssignee', assignee)
    },
    setCreateTicketStatus (status) {
      this.$store.commit('setCreateTicketStatus', status)
    },
    checkTitle () {
      this.titleErrors = []

      if (!this.title) {
        this.titleErrors.push('Title required')
        return false
      }

      if (!this.titleErrors.length) {
        return true
      }
    }
  }
}
</script>

<style scoped>
.browser-extension {
  display: flex;
  min-height: 100%;
  user-select: none;
}

.screenshot-loading {
  position: relative;
  display: flex;

  flex-direction: column;
  background-color: #484e56;
  color: #fff;
}

.settings {
  display: flex;
  flex-shrink: 0;
  width: 260px;
  background-color: #f9fafb;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 20px;

}

.setting {
  width: 100%;
  padding-right: 22px;
  padding-left: 22px;
}

.setting-input {
  display: flex;
  align-items: center;
  width: 100%;
}

.label {
  padding-top: 10px;
  display: flex;
  width: 100%;
}

.title {
  width: 100%;
}

.description {
  width: 100%;
  height: 80px;
}

.submit-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #e0e4eb;
}

#image-from-extension {
  touch-action: none;
  max-height: 100vh;
  max-width: 100%;
  user-select: none;
}

</style>
