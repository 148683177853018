<template>
  <div class="project-settings">
    <div class="loading" v-if="!$store.state.projects.projectsAreLoaded">
      <icon-spinner width="20px" height="20px" fill="#fff" />
    </div>
    <div class="global-panel" v-if="$store.state.projects.projectsAreLoaded">
      <div class="title-row">
        <icon-gear class="title-icon" height="21px" width="21px" fill="#347be5"/>
        <h1 class="global-title">
          Project Settings
        </h1>
      </div>
      <form class="name-form"
            id="name-form"
            novalidate
            @submit.prevent="changeProjectName()">
        <div class="name-slug">
          <div class="name">
            <div>
              <label for="name" class="global-label">
                Project Name
              </label>
            </div>
            <input
              class="global-input name-input"
              type="text"
              name="name"
              spellcheck="false"
              autocapitalize="off"
              v-model="editableProjectName"
            >
          </div>
        </div>

        <swift-button
          class="name-button"
          color="blue"
          type="submit"
          :is-loading="changeNameIsLoading"
        >
          Change name
        </swift-button>
      </form>

      <div class="delete">
        <div>
          <label for="name" class="global-label delete-label">
            Danger Zone
          </label>
        </div>
        <swift-button
          class="name-button"
          color="black"
          @click.native.stop="confirmDelete()"
        >
          Delete project
        </swift-button>
        <portal v-if="showDeleteConfirmation === true">
          <div class="global-overlay-shadow" @click.stop.self="showDeleteConfirmation = false">
            <div class="global-modal global-confirmation-modal">
              <div class="global-confirmation-modal-body global-border-bottom">
                Are you sure you want to do this?<br>
                This will delete the project and all of its tickets.
              </div>
              <div class="global-confirmation-modal-footer">
                <swift-button color="blue" :is-loading="deleteProjectIsLoading"  @click.native="deleteProject()">Delete project</swift-button>
                <swift-button color="link" @click.native="showDeleteConfirmation = false">Cancel</swift-button>
              </div>
            </div>
          </div>
        </portal>
      </div>

    </div>
  </div>
</template>

<script>
import SwiftButton from './SwiftButton.vue'
import { Portal } from '@linusborg/vue-simple-portal'
import IconGear from './icons/IconGear.vue'
import IconSpinner from './icons/IconSpinner.vue'

export default {
  components: {
    SwiftButton,
    IconGear,
    IconSpinner,
    Portal
  },
  data () {
    return {
      editableProjectName: '',
      changeNameIsLoading: false,
      showDeleteConfirmation: false,
      deleteProjectIsLoading: false
    }
  },
  computed: {
    project () {
      return this.$store.getters.getProjectById(this.$route.params.projectId)
    }
  },
  methods: {
    confirmDelete () {
      this.showDeleteConfirmation = true
    },
    async deleteProject () {
      this.deleteProjectIsLoading = true
      try {
        await this.$store.dispatch('deleteProjectById', this.$store.state.projects.currentProjectId)
        this.deleteProjectIsLoading = false
      } catch (error) {
        this.deleteProjectIsLoading = false
      }
      this.deleteProjectIsLoading = false
    },
    async changeProjectName () {
      const updatedProject = {
        id: this.project.id,
        name: this.editableProjectName
      }
      this.changeNameIsLoading = true
      try {
        await this.$store.dispatch('updateProject', updatedProject)
        this.changeNameIsLoading = false

        // replace route to remove outdated slug
        this.$router.replace({
          name: 'project-settings',
          params: {
            organizationName: this.$route.params.organizationName,
            projectId: this.projectId.toString()
          }
        })
      } catch (error) {
        this.changeNameIsLoading = false
      }
      this.changeNameIsLoading = false
    }
  }
}
</script>

<style scoped>
.title-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title-icon {
  margin-right: 4px;
}

.name-form {
  margin-bottom: 40px;
}
.name-input {
  margin-bottom: 5px;
}

.members {
  padding-bottom: 40px;
}

.member {
  display: flex;
  align-items: center;
}

.member-name {
  margin-left: 5px;
}
</style>
