<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 1792 1792"
    xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="fill"
      d="M1088 1248v192q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h192q40 0 68 28t28 68zm0-512v192q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h192q40 0 68 28t28 68zm0-512v192q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h192q40 0 68 28t28 68z"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: '#4b586c'
    }
  }
}
</script>

<style scoped>
</style>
