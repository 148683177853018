<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 1792 1792"
    xmlns="http://www.w3.org/2000/svg">
    <path :fill="fill" d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: '#4b586c'
    }
  }
}
</script>

<style scoped>
</style>
