<template>
  <div class="select-reporter">
    <swift-dropdown button-style="invisible-button">
      <template slot="dropdown-button">
        <div class="inner-dropdown">
          <div class="label" :class="{'label-active': selectedReporter}">
            Reported by
          </div>
          <div
            v-if="selectedReporter"
            class="selected-value">
            <div class="item">
              <div class="item-name">
                {{ selectedReporter.name }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="dropdown-items">
        <swift-dropdown-item
          @selected="clearSelect()">
          <icon-close fill="#4b586c" width="14px" height="14px" />
          <div class="clear-select">Clear filter</div>
        </swift-dropdown-item>
        <swift-dropdown-item
          :value="$store.state.user.user"
          @selected="selectReporter($store.state.user.user)"
        >
          <div class="avatar-container">
            <swift-user-avatar
              :user="$store.state.user.user"
              :size="16"
            />
          </div>
          <div class="yourself">
            You
          </div>
        </swift-dropdown-item>
        <div class="menu-divider"></div>
        <swift-dropdown-item
          v-for="user in activeUsers"
          :key="user.id"
          :value="user"
          @selected="selectReporter(user)">
          <div class="avatar-container">
            <swift-user-avatar
              :user="user"
              :size="16" />
          </div>
          {{ user.name }}
        </swift-dropdown-item>
      </template>
    </swift-dropdown>
  </div>
</template>

<script>
import SwiftDropdown from './SwiftDropdown.vue'
import IconClose from './icons/IconClose.vue'
import SwiftDropdownItem from './SwiftDropdownItem.vue'
import SwiftUserAvatar from './SwiftUserAvatar.vue'

export default {
  components: {
    SwiftDropdown,
    IconClose,
    SwiftDropdownItem,
    SwiftUserAvatar
  },
  props: ['initialReporter', 'setInitialReporter'],
  data () {
    return {
      selectedReporter: this.initialReporter
    }
  },
  mounted () {
    if (this.setInitialReporter) {
      this.selectReporter(this.initialReporter)
    }
  },
  computed: {
    getUserById () {
      return this.$store.getters.getUserById
    },
    activeUsers () {
      return this.$store.getters.activeUsers
    }
  },
  methods: {
    selectReporter (user) {
      this.selectedReporter = user
      this.$emit('selectedReporter', user)
    },
    clearSelect () {
      this.selectedReporter = null
      this.$emit('selectedReporter', null)
    }
  }
}
</script>

<style scoped>
.select-reporter {
  width: 100%;
}

.selected-value {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.item {
  display: flex;
  align-items: center;
}

.clear-select {
  margin-left: 5px;
}
.menu-divider {
  width: 100%;
  border-top: 1px solid #e0e4eb;
  color: #4b586c;
  padding-top: 8px;
  margin-top: 5px;
}

.avatar-container {
  margin-right: 5px;
}

.inner-dropdown {
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  font-weight: 600;
  color: #9ba8bb;
  font-size: 13px;
  margin-right: 5px;
}

.label-active, .label:hover, .label:focus {
  color: #1764d9;
  transition-duration: 0.3s;
}

</style>
