var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagination"},[_c('div',{staticClass:"pagination-bar"},[_c('router-link',{staticClass:"pagination-link pagination-previous",class:{'pagination-inactive': !_vm.$store.state.tickets.pagination.hasPreviousPage},attrs:{"to":{
        query: {
          ..._vm.$route.query,
          page: _vm.$store.state.tickets.pagination.previousPage
        }},"append":""}},[_c('icon-angle-left',{attrs:{"width":"16px","height":"16px","fill":_vm.$store.state.tickets.pagination.hasPreviousPage? '#1764d9' : '#c8cfda'}}),_vm._v("Prev ")],1),_vm._l((_vm.pages),function(page){return _c('router-link',{key:page.index,staticClass:"pagination-link",class:{'pagination-active': _vm.$store.state.tickets.pagination.currentPage === parseInt(page), 'pagination-empty': page === '...'},attrs:{"to":{
        query: {
          ..._vm.$route.query,
          page: page
        }},"append":""}},[_vm._v(" "+_vm._s(page)+" ")])}),_c('router-link',{staticClass:"pagination-link pagination-next",class:{'pagination-inactive': !_vm.$store.state.tickets.pagination.hasNextPage},attrs:{"to":{
        query: {
          ..._vm.$route.query,
          page: _vm.$store.state.tickets.pagination.nextPage
        }},"append":""}},[_vm._v(" Next"),_c('icon-angle-right',{attrs:{"width":"16px","height":"16px","fill":_vm.$store.state.tickets.pagination.hasNextPage? '#1764d9' : '#c8cfda'}})],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }