<template>
  <div class="swift-notification">
    <div class="notifications">
      <transition-group name="notification">
        <div class="notification" :class="notification.type"  v-for="notification in $store.state.notifications.notifications" :key="notification.id">
          <div class="notification-content">
            <div class="error-text" v-if="notification.text">
              {{ notification.text}}
            </div>
            <!-- Only shows if there is no error text -->
            <div class="status" v-if="!notification.text">
              <div class="status-code" v-if="notification.statusCode">
                {{ notification.statusCode}}
              </div>
              <div class="status-text" v-if="notification.statusText">
                {{ notification.statusText}}
              </div>
            </div>
            <!-- Error without any info -->
            <div class="unkown-error" v-if="!notification.error && !notification.statusCode && notification.type === 'error'">
              Unkown Error
            </div>
          </div>
          <div class="notification-close">
            <swift-close-round height="8px" width="8px" :fill="fillColor(notification.type)" @click.native="closeNotification(notification.id)" />
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import SwiftCloseRound from './SwiftCloseRound.vue'

export default {
  components: {
    SwiftCloseRound
  },
  data () {
    return {

    }
  },
  methods: {
    closeNotification (id) {
      this.$store.commit('deleteNotificationById', id)
    },
    fillColor (type) {
      if (type === 'error') {
        return '#a80000'
      } else if (type === 'success') {
        return '#007a37'
      } else {
        return '#4b586c'
      }
    }

  }
}
</script>

<style scoped>

.swift-notification {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
}
.notifications {
  position:relative;
  margin-right: 30px;
  margin-top: 10px;

}

.notification {
  display: flex;
  max-width: 500px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 12px hsla(216, 18%, 19%, 0.1);
  word-break: break-all;
}

.error {
  background-color: #fff5f5;
  color: #a80000;
  border: solid 1px #ffbdbd;
}
.success {
  background-color: #f6fefa;
  color: #007a37;
  border: solid 1px #b6edce;
}

.notification-content {

  display: flex;
  flex-grow: 1;
  align-items: center;
  word-wrap: break-word;

}

.notification-close {
  margin-left: 20px;
}

.status {
  display: flex;
}

.status-code {
  margin-right: 5px;
}

.notification-enter-active, .notification-leave-active {
  transition: all 0.5s ease;
}

.notification-enter, .notification-leave-to {
  opacity: 0;
  transform: translateX(500px)
}

</style>
