<template>
  <div class="browser-icon">
    <svg
      v-if="browser == 'Android Browser'"
      :width="width"
      :height="height"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg">
      <path :fill="fill" d="M685 483q16 0 27.5-11.5t11.5-27.5-11.5-27.5-27.5-11.5-27 11.5-11 27.5 11 27.5 27 11.5zm422 0q16 0 27-11.5t11-27.5-11-27.5-27-11.5-27.5 11.5-11.5 27.5 11.5 27.5 27.5 11.5zm-812 184q42 0 72 30t30 72v430q0 43-29.5 73t-72.5 30-73-30-30-73v-430q0-42 30-72t73-30zm1060 19v666q0 46-32 78t-77 32h-75v227q0 43-30 73t-73 30-73-30-30-73v-227h-138v227q0 43-30 73t-73 30q-42 0-72-30t-30-73l-1-227h-74q-46 0-78-32t-32-78v-666h918zm-232-405q107 55 171 153.5t64 215.5h-925q0-117 64-215.5t172-153.5l-71-131q-7-13 5-20 13-6 20 6l72 132q95-42 201-42t201 42l72-132q7-12 20-6 12 7 5 20zm477 488v430q0 43-30 73t-73 30q-42 0-72-30t-30-73v-430q0-43 30-72.5t72-29.5q43 0 73 29.5t30 72.5z"/>
    </svg>
    <svg
      v-if="browser == 'Chrome'"
      :width="width"
      :height="height"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg">
      <path :fill="fill" d="M893 0q240-2 451 120 232 134 352 372l-742-39q-160-9-294 74.5t-185 229.5l-276-424q128-159 311-245.5t383-87.5zm-747 405l337 663q72 143 211 217t293 45l-230 451q-212-33-385-157.5t-272.5-316-99.5-411.5q0-267 146-491zm1586 169q58 150 59.5 310.5t-48.5 306-153 272-246 209.5q-230 133-498 119l405-623q88-131 82.5-290.5t-106.5-277.5zm-836 20q125 0 213.5 88.5t88.5 213.5-88.5 213.5-213.5 88.5-213.5-88.5-88.5-213.5 88.5-213.5 213.5-88.5z"/>
    </svg>
    <svg
      v-if="browser == 'Edge'"
      :width="width"
      :height="height"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg">
      <path :fill="fill" d="M69 795h1q16-126 58.5-241.5t115-217 167.5-176 223.5-117.5 276.5-43q231 0 414 105.5t294 303.5q104 187 104 442v188h-1125q1 111 53.5 192.5t136.5 122.5 189.5 57 213 3 208-46.5 173.5-84.5v377q-92 55-229.5 92t-312.5 38-316-53q-189-73-311.5-249t-124.5-372q-3-242 111-412t325-268q-48 60-78 125.5t-46 159.5h635q8-77-8-140t-47-101.5-70.5-66.5-80.5-41-75-20.5-56-8.5l-22-1q-135 5-259.5 44.5t-223.5 104.5-176 140.5-138 163.5z"/>
    </svg>
    <svg
      v-if="browser == 'IE'"
      :width="width"
      :height="height"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg">
      <path :fill="fill" d="M1792 937q0 56-7 104h-1151q0 146 109.5 244.5t257.5 98.5q99 0 185.5-46.5t136.5-130.5h423q-56 159-170.5 281t-267.5 188.5-321 66.5q-187 0-356-83-228 116-394 116-237 0-237-263 0-115 45-275 17-60 109-229 199-360 475-606-184 79-427 354 63-274 283.5-449.5t501.5-175.5q30 0 45 1 255-117 433-117 64 0 116 13t94.5 40.5 66.5 76.5 24 115q0 116-75 286 101 182 101 390zm-70-640q0-83-53-132t-137-49q-108 0-254 70 121 47 222.5 131.5t170.5 195.5q51-135 51-216zm-1594 1237q0 86 48.5 132.5t134.5 46.5q115 0 266-83-122-72-213.5-183t-137.5-245q-98 205-98 332zm504-713h728q-5-142-113-237t-251-95q-144 0-251.5 95t-112.5 237z"/>
    </svg>
    <svg
      v-if="browser == 'Safari' || browser == 'Mobile Safari'"
      :width="width"
      :height="height"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg">
      <path :fill="fill" d="M949 893q0 26-16.5 45t-41.5 19q-26 0-45-16.5t-19-41.5q0-26 17-45t42-19 44 16.5 19 41.5zm15 58l350-581q-9 8-67.5 62.5t-125.5 116.5-136.5 127-117 110.5-50.5 51.5l-349 580q7-7 67-62t126-116.5 136-127 117-111 50-50.5zm647-55q0 201-104 371-3-2-17-11t-26.5-16.5-16.5-7.5q-13 0-13 13 0 10 59 44-74 112-184.5 190.5t-241.5 110.5l-16-67q-1-10-15-10-5 0-8 5.5t-2 9.5l16 68q-72 15-146 15-199 0-372-105 1-2 13-20.5t21.5-33.5 9.5-19q0-13-13-13-6 0-17 14.5t-22.5 34.5-13.5 23q-113-75-192-187.5t-110-244.5l69-15q10-3 10-15 0-5-5.5-8t-10.5-2l-68 15q-14-72-14-139 0-206 109-379 2 1 18.5 12t30 19 17.5 8q13 0 13-12 0-6-12.5-15.5t-32.5-21.5l-20-12q77-112 189-189t244-107l15 67q2 10 15 10 5 0 8-5.5t2-10.5l-15-66q71-13 134-13 204 0 379 109-39 56-39 65 0 13 12 13 11 0 48-64 111 75 187.5 186t107.5 241l-56 12q-10 2-10 16 0 5 5.5 8t9.5 2l57-13q14 72 14 140zm85 0q0-163-63.5-311t-170.5-255-255-170.5-311-63.5-311 63.5-255 170.5-170.5 255-63.5 311 63.5 311 170.5 255 255 170.5 311 63.5 311-63.5 255-170.5 170.5-255 63.5-311zm96 0q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z"/>
    </svg>
    <svg
      v-if="browser == 'Mozilla'|| browser == 'Firefox'"
      :width="width"
      :height="height"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg">
      <path :fill="fill" d="M903 1792q-283 0-504.5-150.5t-329.5-398.5q-58-131-67-301t26-332.5 111-312 179-242.5l-11 281q11-14 68-15.5t70 15.5q42-81 160.5-138t234.5-59q-54 45-119.5 148.5t-58.5 163.5q25 8 62.5 13.5t63 7.5 68 4 50.5 3q15 5 9.5 45.5t-30.5 75.5q-5 7-16.5 18.5t-56.5 35.5-101 34l15 189-139-67q-18 43-7.5 81.5t36 66.5 65.5 41.5 81 6.5q51-9 98-34.5t83.5-45 73.5-17.5q61 4 89.5 33t19.5 65q-1 2-2.5 5.5t-8.5 12.5-18 15.5-31.5 10.5-46.5 1q-60 95-144.5 135.5t-209.5 29.5q74 61 162.5 82.5t168.5 6 154.5-52 128-87.5 80.5-104q43-91 39-192.5t-37.5-188.5-78.5-125q87 38 137 79.5t77 112.5q15-170-57.5-343t-209.5-284q265 77 412 279.5t151 517.5q2 127-40.5 255t-123.5 238-189 196-247.5 135.5-288.5 49.5z"/>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    browser: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: '#4b586c'
    }
  }
}
</script>

<style scoped>
.browser-icon {
  display: flex;
  align-self: center;
}
</style>
