<template>
  <div class="annotation-canvas">
    <textarea class="text-input"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              v-if="textInputIsActive"
              v-model="textInputContent"
              @blur="stopText()"
              @keydown="editText()"
              ref="textinput">
    </textarea>
    <div class="temp">
      <!-- Canvas: {{canvas}}<br> -->
      Canvas Height: {{ canvasHeight}}<br>
      Canvas Width: {{ canvasWidth}}<br>

      <br>
      clientY: {{ clientY }}<br>
      clientX: {{ clientX }}<br>
      <br>
      canvasY: {{ canvasY }}<br>
      canvasX: {{ canvasX }}<br>
      <br>
      <div v-if="canvas">
        canvas.height  {{canvas.height }}<br>
        canvas.width  {{canvas.width }}<br>
        canvas.clientHeight  {{canvas.clientHeight }}<br>
        canvas.clientWidht  {{canvas.clientWidth }}<br>
      </div>
    </div>
    <div class="toolbar">
      <button class="toolbar-button toolbar-border-left"  :class="{ 'toolbar-button-active': activeTool === 'text'}"
              @click="activeTool = 'text'">
        <icon-a height="16" width="16" fill="white" />
        <div class="toolbar-button-text">
          Text
        </div>
      </button>
      <div class="toolbar-seperator"></div>
      <button class="toolbar-button" :class="{ 'toolbar-button-active': activeTool === 'ellipse'}"
              @click="activeTool = 'ellipse'">
        <icon-circle height="16" width="16" fill="white" />
        <div class="toolbar-button-text">
          Ellipse
        </div>
      </button>
      <div class="toolbar-seperator"></div>
      <button class="toolbar-button" :class="{ 'toolbar-button-active': activeTool === 'rect'}"
              @click="activeTool = 'rect'" >
        <icon-rect width="16" height="16" fill="white" />
        <div class="toolbar-button-text">
          Rect
        </div>
      </button>
      <div class="toolbar-seperator"></div>
      <button class="toolbar-button" :class="{ 'toolbar-button-active': activeTool === 'line'}"
              @click="activeTool = 'line'">
        <icon-edit height="16" width="16" fill="white" />
        <div class="toolbar-button-text">
          Pen
        </div>
      </button>
      <div class="toolbar-seperator"></div>
      <button class="toolbar-button" :class="{ 'toolbar-button-active': activeTool === 'arrow'}"
              @click="activeTool = 'arrow'">
        <icon-arrow height="16" width="16" fill="white" />
        <div class="toolbar-button-text">
          Arrow
        </div>
      </button>
      <div class="toolbar-seperator"></div>
      <button class="toolbar-button" @click="undoAction()">
        <icon-undo height="16" width="15" :fill="undoIsActive ? '#fff' : '#7988a0'" />
        <div class="toolbar-button-text" :style="{color: undoIsActive ? '#fff' : '#7988a0'}">
          Undo
        </div>
      </button>
      <div class="toolbar-seperator"></div>
      <button class="toolbar-button" @click="redoAction()">
        <icon-redo height="16" width="15" :fill="redoIsActive ? '#fff' : '#7988a0'" />
        <div class="toolbar-button-text" :style="{color: redoIsActive ? '#fff' : '#7988a0'}">
          Redo
        </div>
      </button>
      <div class="toolbar-seperator"></div>
      <button class="toolbar-button toolbar-border-right" @click="deleteAnnotations()">
        <icon-delete height="16" width="16" :fill="actionTimeline.length ? '#fff' : '#7988a0'" />
        <div class="toolbar-button-text" :style="{color: actionTimeline.length ? '#fff' : '#7988a0'}">
          Reset
        </div>
      </button>
    </div>
    <div class="flex-top">
    </div>
    <div class="flex-middle">
      <div class="flex-middle-left">
      </div>
      <div class="flex-middle-canvas">
        <canvas id="drawing-canvas" ref="drawingcanvas"
                @pointerdown="startShape($event)"
                @pointermove="drawShape($event)"
                @pointerup="stopShape($event)"
                style="width:100%;">
        </canvas >
      </div>
      <div class="flex-middle-right">
      </div>
    </div>
    <div class="flex-bottom">
    </div>
  </div>
</template>

<script>
import IconDelete from './icons/IconDelete.vue'
import IconCircle from './icons/IconCircle.vue'
import IconA from './icons/IconA.vue'
import IconRect from './icons/IconRect.vue'
import IconArrow from './icons/IconArrow.vue'
import IconEdit from './icons/IconEdit.vue'
import IconRedo from './icons/IconRedo.vue'
import IconUndo from './icons/IconUndo.vue'

export default {
  components: {
    IconDelete,
    IconCircle,
    IconA,
    IconRect,
    IconArrow,
    IconEdit,
    IconRedo,
    IconUndo
  },
  props: {
    screenshotUri: null,
    screenshotInfo: {
      url: String,
      title: String,
      tabHeight: Number,
      tabWidth: Number,
      browser: String,
      os: String,
      language: String,
      cookieEnabled: Boolean,
      devicePixelRatio: Number,
      naturalHeight: Number,
      naturalWidth: Number
    },
    screenshotIsLoaded: Boolean,
    loadCanvasFile: Boolean
  },
  data: function () {
    return {
      activeTool: null,
      color: 'red',
      canvas: null,
      canvasHeight: null,
      canvasWidth: null,
      canvasLeft: null,
      canvasTop: null,
      ctx: null,
      clientX: null,
      clientY: null,
      rects: [],
      deletedRects: [],
      ellipses: [],
      deletedEllipses: [],
      arrows: [],
      deletedArrows: [],
      lines: [],
      deletedLines: [],
      texts: [],
      deletedTexts: [],
      isDrawing: false,
      textInputIsActive: false,
      textInputContent: 'type here',
      actionTimeline: [],
      timelineUndoPosition: -1,
      img: null
    }
  },
  computed: {
    canvasX () {
      return Math.round(((this.clientX - this.canvasLeft) / this.canvasWidth * this.screenshotInfo.naturalWidth) / this.canvasScale)
    },
    canvasY () {
      return Math.round(((this.clientY - this.canvasTop) / this.canvasHeight * this.screenshotInfo.naturalHeight) / this.canvasScale)
    },
    canvasScale () {
      return Math.round(this.screenshotInfo.naturalHeight / this.screenshotInfo.tabHeight * 100) / 100
    },
    undoIsActive () {
      if (this.timelineUndoPosition >= 0) {
        return true
      } else {
        return false
      }
    },
    redoIsActive () {
      if (this.actionTimeline.length === 0 || this.timelineUndoPosition + 1 >= this.actionTimeline.length) {
        return false
      } else {
        return true
      }
    }
  },
  mounted () {
    this.canvas = document.getElementById('drawing-canvas')
    this.ctx = this.canvas.getContext('2d')

    // set canvas dimension to image
    this.canvas.width = this.screenshotInfo.naturalWidth
    this.canvas.height = this.screenshotInfo.naturalHeight

    // stops canvas to scroll larger than how the tab was displayed (this can be smalle than the img natural width because of devicePixelRatio for exmaple on Retina displays)
    this.canvas.style.maxWidth = this.screenshotInfo.tabWidth + 'px'
    this.canvas.style.maxHeight = this.screenshotInfo.tabHeight + 'px'
    // create img and wait for onload to draw image on canvas
    this.img = new Image()
    this.img.src = this.screenshotUri
    this.img.onload = () => {
      this.drawImage()
    }
  },
  watch: {
    loadCanvasFile: function () {
      let canvasFile = this.convertCanvasToFile()
      this.$emit('updated-canvas-file', canvasFile)
    }
  },
  methods: {
    redrawCanvas () {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

      this.drawImage()

      // change scale to adjust all drawings to possible difference between the screenshots natural size and the tab size caused by different device pixel ratio or zooom levels. We adjust drawings instead of the image because the image gets blurry when adjusted
      this.ctx.save()
      this.ctx.scale(this.canvasScale, this.canvasScale)

      this.drawRects()
      this.drawEllipses()
      this.drawArrows()
      this.drawLines()
      this.drawText()

      // restore scale
      this.ctx.restore()
    },
    drawImage () {
      this.ctx.drawImage(this.img, 0, 0)
    },
    drawRects () {
      this.rects.forEach(rect => {
        this.ctx.beginPath()
        this.ctx.lineWidth = rect.lineWidth
        this.ctx.strokeStyle = rect.strokeStyle
        this.ctx.shadowColor = 'rgba(0,0,0,0.2)'
        this.ctx.shadowBlur = 5
        this.ctx.rect(rect.x, rect.y, rect.width, rect.height)
        this.ctx.stroke()
      })
    },
    drawEllipses () {
      this.ellipses.forEach(ellipse => {
        this.ctx.beginPath()
        this.ctx.lineWidth = ellipse.lineWidth
        this.ctx.strokeStyle = ellipse.strokeStyle
        this.ctx.shadowColor = 'rgba(0,0,0,0.2)'
        this.ctx.shadowBlur = 5
        this.ctx.ellipse(ellipse.x, ellipse.y, ellipse.radiusX, ellipse.radiusY, ellipse.rotation, ellipse.startAngle, ellipse.endAngle)
        this.ctx.stroke()
      })
    },
    drawArrows () {
      this.arrows.forEach(arrow => {
        this.ctx.beginPath()
        this.ctx.lineWidth = arrow.lineWidth
        this.ctx.strokeStyle = arrow.strokeStyle
        this.ctx.lineCap = 'round'
        this.ctx.linejoin = 'round'
        this.ctx.shadowColor = 'rgba(0,0,0,0.2)'
        this.ctx.shadowBlur = 5

        // draw line
        this.ctx.moveTo(arrow.lineStartX, arrow.lineStartY)
        this.ctx.lineTo(arrow.lineEndX, arrow.lineEndY)
        this.ctx.stroke()

        // caclulate angle for arrowhead
        let distanceX = arrow.lineStartX - arrow.lineEndX
        let distanceY = arrow.lineStartY - arrow.lineEndY
        let endingAngle = Math.atan2(distanceY, distanceX)

        // draw arrowhead
        this.ctx.beginPath()
        this.ctx.save()
        this.ctx.fillStyle = this.color
        // move arrowhead to end of line and rotate
        this.ctx.translate(arrow.lineEndX, arrow.lineEndY)
        this.ctx.rotate(endingAngle)
        this.ctx.moveTo(0, 0)
        this.ctx.lineTo(0, -12)
        this.ctx.lineTo(-24, 0)
        this.ctx.lineTo(0, 12)
        this.ctx.closePath()
        this.ctx.fill()
        this.ctx.restore()
      })
    },
    drawLines () {
      this.lines.forEach(line => {
        this.ctx.beginPath()
        this.ctx.lineWidth = line.lineWidth
        this.ctx.strokeStyle = line.strokeStyle
        this.ctx.lineCap = 'round'
        this.ctx.linejoin = 'round'
        this.ctx.shadowColor = 'rgba(0,0,0,0.2)'
        this.ctx.shadowBlur = 5

        var p1 = line.points[0]
        var p2 = line.points[1]

        this.ctx.moveTo(p1.x, p1.y)

        for (var i = 1, len = line.points.length; i < len; i++) {
          // we pick the point between pi+1 & pi+2 as the
          // end point and p1 as our control point
          var middlePoint = {
            x: p1.x + (p2.x - p1.x) / 2,
            y: p1.y + (p2.y - p1.y) / 2
          }
          this.ctx.quadraticCurveTo(p1.x, p1.y, middlePoint.x, middlePoint.y)

          // assign next points
          p1 = line.points[i]
          p2 = line.points[i + 1]
        }
        // Draw last line as a straight line while
        // we wait for the next point to be able to calculate
        // the bezier control point
        this.ctx.lineTo(p1.x, p1.y)
        this.ctx.stroke()
      })
    },
    drawText () {
      let lineHeight = 40

      this.texts.forEach(text => {
        // split text in mutliple lines
        let lines = text.content.split('\n')

        // draw text lines on canvas
        var i = -1
        lines.forEach(line => {
          i++
          this.ctx.beginPath()
          this.ctx.font = '700 28px sans-serif'
          this.ctx.fillStyle = this.color
          this.ctx.strokeStyle = 'white'
          this.ctx.shadowColor = 'rgba(0,0,0,0.2)'
          this.ctx.shadowBlur = 5
          this.ctx.lineWidth = 6
          this.ctx.miterLimit = 2 // preventes stroke bleeding out on M and Ws
          this.ctx.strokeText(line, text.x, text.y + (i * lineHeight))
          this.ctx.shadowColor = 'transparent'
          this.ctx.shadowBlur = 0
          this.ctx.fillText(line, text.x, text.y + (i * lineHeight))
        })
      })

      // draw cursor on active text
      if (this.textInputIsActive) {
        let text = this.texts[this.texts.length - 1]
        let lines = text.content.split('\n')
        let numberOfLines = lines.length
        let lastLineWidth = this.ctx.measureText(lines[lines.length - 1]).width
        let marginToText = 2
        let cursorX = text.x + lastLineWidth + marginToText
        let cursorY = text.y + 5 + ((numberOfLines - 1) * lineHeight)
        let cursorHeight = 30
        this.ctx.beginPath()
        this.ctx.strokeStyle = 'rgba(255,0,0,1)'
        this.ctx.lineWidth = 2
        this.ctx.moveTo(cursorX, cursorY)
        this.ctx.lineTo(cursorX, cursorY - cursorHeight)
        this.ctx.stroke()
      }
    },
    startShape (event) {
      // capture subsequent pointer moves even outside the canvas
      event.target.setPointerCapture(event.pointerId)

      // save canvas dimensions
      this.canvasHeight = Math.round(this.$refs.drawingcanvas.getBoundingClientRect().height)
      this.canvasWidth = Math.round(this.$refs.drawingcanvas.getBoundingClientRect().width)
      this.canvasTop = Math.round(this.$refs.drawingcanvas.getBoundingClientRect().top)
      this.canvasLeft = Math.round(this.$refs.drawingcanvas.getBoundingClientRect().left)

      // save pointer position this will also trigger the computed value calculations of mouseInViewBox
      this.clientX = Math.round(event.clientX)
      this.clientY = Math.round(event.clientY)

      // create shape
      if (this.activeTool === 'rect') {
        this.startRect()
      }
      if (this.activeTool === 'ellipse') {
        this.startEllipse()
      }
      if (this.activeTool === 'arrow') {
        this.startArrow()
      }
      if (this.activeTool === 'line') {
        this.startLine()
      }
      if (this.activeTool === 'text') {
        if (this.textInputIsActive) {
          this.textInputIsActive = false
          this.redrawCanvas()
        } else {
          this.startText()
        }
      }
    },
    drawShape (event) {
      // push current point position to trigger calculations
      this.clientX = Math.round(event.clientX)
      this.clientY = Math.round(event.clientY)

      if (this.activeTool === 'rect') {
        this.editRect()
      }
      if (this.activeTool === 'ellipse') {
        this.editEllipse()
      }
      if (this.activeTool === 'arrow') {
        this.editArrow()
      }
      if (this.activeTool === 'line') {
        this.editLine()
      }
    },
    stopShape (event) {
      event.target.onpointermove = null
      event.target.releasePointerCapture(event.pointerId)

      if (this.isDrawing === true) {
        this.isDrawing = false
      }
    },
    startRect () {
      const id = this.createUniqueId()
      let rect = {
        id: id,
        mousedownX: this.canvasX,
        mousedownY: this.canvasY,
        x: this.canvasX,
        y: this.canvasY,
        width: 2,
        height: 2,
        lineWidth: 6,
        strokeStyle: this.color
      }
      this.rects.push(rect)

      this.saveTimelineAction({
        type: 'create',
        shape: 'rect',
        id: id
      })

      this.isDrawing = true
      this.redrawCanvas()
    },
    editRect () {
      if (this.isDrawing) {
        let rect = this.rects[this.rects.length - 1]

        // calculate width from first click
        rect.width = Math.abs(rect.mousedownX - this.canvasX)
        rect.height = Math.abs(rect.mousedownY - this.canvasY)

        // caluclate new drawing starting point if mouse is left or upwards from initital starting point
        if (rect.mousedownX >= this.canvasX) {
          rect.x = this.canvasX
        }
        if (rect.mousedownY >= this.canvasY) {
          rect.y = this.canvasY
        }

        // trigger drawing
        this.redrawCanvas()
      }
    },
    startEllipse () {
      const id = this.createUniqueId()

      let ellipse = {
        id: id,
        mousedownX: this.canvasX,
        mousedownY: this.canvasY,
        x: this.canvasX,
        y: this.canvasY,
        radiusX: 2,
        radiusY: 2,
        rotation: 0,
        startAngle: 0,
        endAngle: 2 * Math.PI,
        lineWidth: 6,
        strokeStyle: this.color
      }
      this.ellipses.push(ellipse)

      this.saveTimelineAction({
        type: 'create',
        shape: 'ellipse',
        id: id
      })

      this.isDrawing = true
      this.redrawCanvas()
    },
    editEllipse () {
      if (this.isDrawing) {
        let ellipse = this.ellipses[this.ellipses.length - 1]

        // calculate radius from mousedown to current position
        ellipse.radiusX = Math.round((Math.abs(ellipse.mousedownX - this.canvasX) / 2))
        ellipse.radiusY = Math.round((Math.abs(ellipse.mousedownY - this.canvasY) / 2))

        // calculate new center point by addding or substracting radius
        if (ellipse.mousedownX < this.canvasX) {
          ellipse.x = ellipse.mousedownX + ellipse.radiusX
        } else {
          ellipse.x = ellipse.mousedownX - ellipse.radiusX
        }

        if (ellipse.mousedownY < this.canvasY) {
          ellipse.y = ellipse.mousedownY + ellipse.radiusY
        } else {
          ellipse.y = ellipse.mousedownY - ellipse.radiusY
        }

        // trigger drawing
        this.redrawCanvas()
      }
    },
    startArrow () {
      const id = this.createUniqueId()
      let arrow = {
        id: id,
        lineStartX: this.canvasX,
        lineStartY: this.canvasY,
        lineEndX: this.canvasX,
        lineEndY: this.canvasY + 1, // this will make the arrowhead point downwards onclick
        lineWidth: 6,
        strokeStyle: this.color
      }
      this.arrows.push(arrow)

      this.saveTimelineAction({
        type: 'create',
        shape: 'arrow',
        id: id
      })

      this.isDrawing = true
      this.redrawCanvas()
    },
    editArrow () {
      if (this.isDrawing) {
        let arrow = this.arrows[this.arrows.length - 1]
        arrow.lineEndX = this.canvasX
        arrow.lineEndY = this.canvasY

        // trigger drawing
        this.redrawCanvas()
      }
    },
    startLine () {
      const id = this.createUniqueId()
      let line = {
        id: id,
        points: [],
        lineWidth: 6,
        strokeStyle: this.color
      }

      let point = {
        x: this.canvasX,
        y: this.canvasY
      }
      line.points.push(point)

      this.lines.push(line)

      this.saveTimelineAction({
        type: 'create',
        shape: 'line',
        id: id
      })

      this.isDrawing = true
      this.redrawCanvas()
    },
    editLine () {
      if (this.isDrawing) {
        let line = this.lines[this.lines.length - 1]

        // add new line point
        let point = {
          x: this.canvasX,
          y: this.canvasY
        }
        line.points.push(point)

        // trigger drawing
        this.redrawCanvas()
      }
    },
    startText () {
      const id = this.createUniqueId()
      this.textInputIsActive = true
      this.textInputContent = 'start typing'
      let text = {
        id: id,
        x: this.canvasX,
        y: this.canvasY,
        content: this.textInputContent
      }
      this.texts.push(text)

      this.saveTimelineAction({
        type: 'create',
        shape: 'text',
        id: id
      })

      // trigger drawing
      this.redrawCanvas()
      // show text area and focus on it text are will than trigger edit texct on input event

      setTimeout(() => {
        this.$refs.textinput.focus()
        this.$refs.textinput.select()
      })
    },
    editText () {
      // timeout to wait for updated textfield
      setTimeout(() => {
        let text = this.texts[this.texts.length - 1]
        text.content = this.textInputContent

        // trigger redraw
        this.redrawCanvas()
      })
    },
    stopText () {
      this.textInputIsActive = false

      // delete text if nothing was typed
      let text = this.texts[this.texts.length - 1]
      if (text.content === 'start typing') {
        this.texts.splice(-1, 1)
      }
      this.redrawCanvas()
    },
    deleteAnnotations () {
      this.rects = []
      this.ellipses = []
      this.arrows = []
      this.lines = []
      this.texts = []
      this.actionTimeline = []
      this.timelineUndoPosition = -1
      this.redrawCanvas()
    },
    saveTimelineAction (timelineAction) {
      if (this.timelineUndoPosition < this.actionTimeline.length - 1) {
        this.actionTimeline.splice(this.timelineUndoPosition + 1, 0, timelineAction)
        this.timelineUndoPosition += 1
      } else {
        this.actionTimeline.push(timelineAction)
        this.timelineUndoPosition = this.actionTimeline.length - 1
      }
    },
    undoAction () {
      // get action of current undo Position
      const action = this.actionTimeline[this.timelineUndoPosition]

      // return if no action recorded
      if (typeof action === 'undefined') {
        return
      }

      if (action.type === 'create') {
        if (action.shape === 'rect') {
          // find shape
          let findById = (element) => element.id === action.id
          let index = this.rects.findIndex(findById)
          if (index === -1) {
            return
          }
          let rect = this.rects[index]

          // delete shape
          this.deletedRects.push(rect)
          this.rects.splice(index, 1)
        }

        if (action.shape === 'ellipse') {
          // find shape
          let findById = (element) => element.id === action.id
          let index = this.ellipses.findIndex(findById)
          if (index === -1) {
            return
          }
          let ellipse = this.ellipses[index]

          // delete shape
          this.deletedEllipses.push(ellipse)
          this.ellipses.splice(index, 1)
        }

        if (action.shape === 'arrow') {
          // find shape
          let findById = (element) => element.id === action.id
          let index = this.arrows.findIndex(findById)
          if (index === -1) {
            return
          }
          let arrow = this.arrows[index]

          // delete shape
          this.deletedArrows.push(arrow)
          this.arrows.splice(index, 1)
        }

        if (action.shape === 'line') {
          // find shape
          let findById = (element) => element.id === action.id
          let index = this.lines.findIndex(findById)
          if (index === -1) {
            return
          }
          let line = this.lines[index]

          // delete shape
          this.deletedLines.push(line)
          this.lines.splice(index, 1)
        }

        if (action.shape === 'text') {
          // find shape
          let findById = (element) => element.id === action.id
          let index = this.texts.findIndex(findById)
          if (index === -1) {
            return
          }
          let text = this.texts[index]

          // delete shape
          this.deletedTexts.push(text)
          this.texts.splice(index, 1)
        }

        // move undo position to prior action
        this.timelineUndoPosition -= 1
      }
      this.redrawCanvas()
    },
    redoAction () {
      // return if nothing to redo
      if (this.actionTimeline.length - 1 <= this.timelineUndoPosition) {
        return
      }

      // get action prior to current position
      const action = this.actionTimeline[this.timelineUndoPosition + 1]

      if (action.type === 'create') {
        if (action.shape === 'rect') {
          // find shape
          let findById = (element) => element.id === action.id
          let index = this.deletedRects.findIndex(findById)
          if (index === -1) {
            return
          }
          let rect = this.deletedRects[index]

          // recreate rect
          this.rects.push(rect)
          this.deletedRects.splice(index, 1)
        }

        if (action.shape === 'ellipse') {
          // find shape
          let findById = (element) => element.id === action.id
          let index = this.deletedEllipses.findIndex(findById)
          if (index === -1) {
            return
          }
          let ellipse = this.deletedEllipses[index]

          // recreate shape
          this.ellipses.push(ellipse)
          this.deletedEllipses.splice(index, 1)
        }

        if (action.shape === 'arrow') {
          // find shape
          let findById = (element) => element.id === action.id
          let index = this.deletedArrows.findIndex(findById)
          if (index === -1) {
            return
          }
          let arrow = this.deletedArrows[index]

          // recreate rect
          this.arrows.push(arrow)
          this.deletedArrows.splice(index, 1)
        }

        if (action.shape === 'line') {
          // find shape
          let findById = (element) => element.id === action.id
          let index = this.deletedLines.findIndex(findById)
          if (index === -1) {
            return
          }
          let line = this.deletedLines[index]

          // recreate rect
          this.lines.push(line)
          this.deletedLines.splice(index, 1)
        }

        if (action.shape === 'text') {
          // find shape
          let findById = (element) => element.id === action.id
          let index = this.deletedTexts.findIndex(findById)
          if (index === -1) {
            return
          }
          let text = this.deletedTexts[index]

          // recreate rect
          this.texts.push(text)
          this.deletedTexts.splice(index, 1)
        }

        // update timeline position
        this.timelineUndoPosition += 1
      }
      this.redrawCanvas()
    },
    convertCanvasToFile () {
      // first convert cnavs to Uri
      let canvasUri = this.canvas.toDataURL('image/jpeg', 0.8)

      // convert it to file blob
      function dataURItoBlob (dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1])

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length)
        var ia = new Uint8Array(ab)
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ab], { type: mimeString })
      }
      try {
        let blob = dataURItoBlob(canvasUri)
        return blob
      } catch (error) {
        throw new Error('could not generate blob file from uri' + error)
      }
    },
    createUniqueId () {
      var dt = new Date().getTime()
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
      return uuid
    }
  }
}
</script>

<style scoped>

.annotation-canvas {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: #484e56;
}
.flex-top {
  display: flex;
  flex-grow: 1;
}
.flex-middle {
  display: flex;
}
.flex-middle-left {
  display: flex;
  flex-basis: 10px;
  flex-grow: 1;
}
.flex-middle-canvas {
    display: flex;
}
.flex-middle-right {
  display: flex;
   flex-grow: 1;
   flex-basis: 10px;
}
.flex-bottom {
  display: flex;
  flex-grow: 1;
}

#drawing-canvas {
  display: flex;
  background-color: #fff;
  user-select: none;
  padding: 0;
  box-shadow: 0px 2px 12px hsla(216, 10%, 10%, 0.3);
  border-radius: 3px;
  touch-action: none;

}
.text-input {
    display: flex;
    background: transparent;
    font-family: sans-serif;
    color: transparent;
    margin-left: 0px;
    padding: 0;
    border: 0;
    border-radius: 2px;
    position: absolute;
    z-index: -100;
    resize: none;
    overflow: hidden;
}

.toolbar {
    display: flex;
    background-color: #282f39;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
    z-index: 100;
    box-shadow: 0px 2px 12px hsla(216, 18%, 19%, .2);
}
.toolbar-drag {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-top: 4px;
    width: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    cursor: grab;
}

.toolbar-button {
  width: 64px;
  display: flex;
  width: 64px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  line-height:  1;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  outline: none;
  color: #FFFFFF;
  border: none;
  user-select: none;
  background-color: transparent;
}

.toolbar-border-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.toolbar-border-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.toolbar-button:hover {
  background-color: #36404e;
  transition-duration: 0.15s;
}

.toolbar-button:active {
  background-color: #1764d9;
  transition-duration: 0.3s;
}

.toolbar-button:focus {
   /* ensures shadows overlays surroundign element */
  z-index: 1;
  transition-duration: 0.3s;

}

.toolbar-button-text {
  padding-top: 3px;
  font-size: 11px;
  font-weight: 400;
  color: #fff;
}

.toolbar-button-active, .toolbar-button-active:hover {
  background-color: #1764d9;
  transition-duration: 0.3s;
}

.toolbar-seperator {
  width: 1px;
  display: flex;
  background-color: #36404e;
}
.toolbar-color-icon {
    padding: 0;
    border-radius: 50%;
    border: 1.5px solid #fff;
    width: 16px;
    height: 16px;
}

.temp {
  color: white;
  position: absolute;
  top: 50px;
  max-width: 200px;
  background-color: #282f39;
  display: none;
}
</style>
