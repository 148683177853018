<template>
  <div class="pagination">
    <div class="pagination-bar">
      <router-link
        :to="{
          query: {
            ...$route.query,
            page: $store.state.tickets.pagination.previousPage
          }}"
        class="pagination-link pagination-previous"
        :class="{'pagination-inactive': !$store.state.tickets.pagination.hasPreviousPage}"
        append>
        <icon-angle-left width="16px" height="16px" :fill="$store.state.tickets.pagination.hasPreviousPage? '#1764d9' : '#c8cfda'"/>Prev
      </router-link>
      <router-link
        v-for="page in pages"
        :key="page.index"
        :to="{
          query: {
            ...$route.query,
            page: page
          }}"
        class="pagination-link"
        :class="{'pagination-active': $store.state.tickets.pagination.currentPage === parseInt(page), 'pagination-empty': page === '...'}"
        append>
        {{ page }}
      </router-link>
      <router-link
        :to="{
          query: {
            ...$route.query,
            page: $store.state.tickets.pagination.nextPage
          }}"
        class="pagination-link pagination-next"
        :class="{'pagination-inactive': !$store.state.tickets.pagination.hasNextPage}"
        append>
        Next<icon-angle-right width="16px" height="16px" :fill="$store.state.tickets.pagination.hasNextPage? '#1764d9' : '#c8cfda'"/>
      </router-link>
    </div>
  </div>
</template>

<script>
import IconAngleRight from './icons/IconAngleRight.vue'
import IconAngleLeft from './icons/IconAngleLeft.vue'

export default {
  components: {
    IconAngleRight,
    IconAngleLeft
  },
  data: function () {
    return {

    }
  },
  computed: {
    pages () {
      var maxPagesShown = 5
      if (this.$store.state.userIsMobile) {
        maxPagesShown = 1
      }
      let pages = []

      // if less pages than maxPageShown show all pages [(1),2,3]
      if ((maxPagesShown + 1) >= this.$store.state.tickets.pagination.lastPage) {
        for (let i = 1; i <= this.$store.state.tickets.pagination.lastPage; i++) {
          pages.push(i)
        }
        return pages
      }
      // current page on left side [1,(2),3,4,5,...,27]
      if (this.$store.state.tickets.pagination.currentPage <= maxPagesShown) {
        for (let i = 1; i <= maxPagesShown + 1; i++) {
          pages.push(i)
        }

        pages.push('...')
        pages.push(this.$store.state.tickets.pagination.lastPage)
        return pages
      }

      // current page on right side [1,...23,(24),25,26,27]
      if (this.$store.state.tickets.pagination.currentPage > (this.$store.state.tickets.pagination.lastPage - maxPagesShown)) {
        pages.push(1)
        pages.push('...')
        for (let i = (this.$store.state.tickets.pagination.lastPage - maxPagesShown); i <= this.$store.state.tickets.pagination.lastPage; i++) {
          pages.push(i)
        }
        return pages
      }

      //  else case: current page in the middle [1,...,10,11,(12), 13, 14,...,27]
      pages.push(1)
      pages.push('...')
      for (let i = this.$store.state.tickets.pagination.currentPage - Math.floor(maxPagesShown / 2); i < this.$store.state.tickets.pagination.currentPage + Math.round(maxPagesShown / 2); i++) {
        pages.push(i)
      }
      pages.push('...')
      pages.push(this.$store.state.tickets.pagination.lastPage)

      return pages
    }
  }

}

</script>

<style scoped>
.pagination {
  display: flex;
  background-color: #fff;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 6px 8px hsla(216, 18%, 19%, 0.03), 0 1px 2px hsla(216, 18%, 19%, 0.1);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.pagination-bar {
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
}

.pagination-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4b586c;
  width: 27px;
  height: 27px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 4px;
}
.pagination-link:hover {
  background-color: #f5f9ff;
  transition-duration: 0.1s;
  cursor: pointer;
}
.pagination-active, .pagination-active:hover {
  background-color: #1764d9;
  font-weight: 600;
  color: #fff;
}

.pagination-previous, .pagination-next {
  font-weight: 600;
  color: #1764d9;
  width: auto;
  padding-right: 5px;
  padding-left: 5px;
  height: auto;
}

.pagination-inactive, .pagination-inactive:hover {
  color: #c8cfda;
  background-color: #fff;
  cursor: default;
}

.pagination-empty, .pagination-empty:hover {
  background-color: #fff;
  width: auto;
  display: flex;
  align-items: center;
  color: #9ba8bb;
  margin: 0;
  padding: 0;
  cursor: default;
}
</style>
