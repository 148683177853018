<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 1000 1000"
       xmlns="http://www.w3.org/2000/svg">

    <polygon :fill="fill" points="900,0 950,0 1000,50 1000,100 300,800 450,950 0,1000 50,550 200,700"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: '#4b586c'
    }
  }
}
</script>

<style scoped>

</style>
