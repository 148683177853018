<template>
  <button class="button-close">
    <svg
      :height="height"
      viewBox="0 0 357 357"
      xmlns="http://www.w3.org/2000/svg">
      <polygon :fill="fill"  points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3 214.2,178.5"/>
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '11px'
    },
    fill: {
      type: String,
      default: '#4b586c'
    }
  }
}
</script>

<style scoped>
.button-close {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    line-height:  1;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    outline: none;
    padding: 10px;
    color: #FFFFFF;
    border: none;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
}

.button-close:hover {
    border: 1px solid #e0e4eb;
    transition: 0.3s;
}

</style>
