import Vue from 'vue'
import Vuex from 'vuex'
import createTicket from './modules/create-ticket.js'
import user from './modules/user.js'
import tickets from './modules/tickets.js'
import status from './modules/status.js'
import priorities from './modules/priorities.js'
import organization from './modules/organization.js'
import projects from './modules/projects.js'
import authentication from './modules/authentication.js'
import invites from './modules/invites.js'
import widgets from './modules/widgets.js'
import notifications from './modules/notifications.js'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// use to reset state in develoment
// if (process.env.NODE_ENV === 'development') {
//   localStorage.removeItem('vuex')
//    localStorage.removeItem('vuexpersistedstate')
// }

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    createTicket,
    user,
    tickets,
    status,
    organization,
    authentication,
    invites,
    widgets,
    projects,
    priorities,
    notifications
  },
  plugins: [createPersistedState({
    key: 'vuexpersistedstate',
    paths: ['authentication']
  })],
  state: {
    imgUri: '',
    colors: {
      Blue: '#1764d9',
      Green: '#0dab54',
      Yellow: '#fbbf09',
      Orange: '#ff6200',
      Red: '#db2929',
      Purple: '#6c19e1',
      Pink: '#fc27a7',
      Teal: '#00c7bd',
      Brown: '#9f6138',
      Black: '#60718a'
    },
    showModal: false,
    scrollPositionBeforeModal: null,
    showTicketModal: false,
    currentTicketModalId: null,
    showNavbar: true,
    userIsMobile: false
  },
  getters: {
  },
  mutations: {
    showTicketModalById: (state, ticketId) => {
      state.showTicketModal = true
      state.currentTicketModalId = ticketId
      if (state.showTicketModal) {
        document.body.className = 'no-scrollbar'
      } else {
        document.body.className = document.body.className.replace('no-scrollbar', '')
      }
    },
    hideTicketModal: (state) => {
      state.showTicketModal = false
      state.currentTicketModalId = null
      if (state.showTicketModal) {
        document.body.className = 'no-scrollbar'
      } else {
        document.body.className = document.body.className.replace('no-scrollbar', '')
      }
    },
    showNavbar: (state) => {
      state.showNavbar = true
    },
    hideNavbar: (state) => {
      state.showNavbar = false
    },
    showModal: (state) => {
      state.showModal = true
    },
    hideModal: (state) => {
      state.showModal = false
    },
    saveScrollPositionBeforeModal: (state, payload) => {
      state.scrollPositionBeforeModal = payload
    },
    setUserToMobile: (state) => {
      state.userIsMobile = true
    },
    setUserToDesktop: (state) => {
      state.userIsMobile = false
    }
  },
  actions: {

  }
})
