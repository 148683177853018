import axios from 'axios'
import store from '@/store/store'

export default {
  getStatusesOfProject (projectId) {
    return axios.get(`/api/${projectId}/statuses`, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  createStatus (status) {
    return axios.post('/api/statuses', status, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  updateStatus (status) {
    return axios.put(`/api/statuses/${status.id}`, status, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  deleteStatusById (statusId) {
    return axios.delete(`/api/statuses/${statusId}`, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  }
}
