import OrganizationApi from '../../api/OrganizationApi.js'

const state = {
  users: [],
  usersAreLoaded: false,
  organizationIsLoaded: false,
  organization: null
}

const getters = {
  getUserById: (state) => (userId) => {
    return state.users.find(element => element.id === userId)
  },
  activeUsers: state => {
  // filters deleted and guest users
    return state.users.filter(function (element) {
      return element.role !== 'guest' && element.deletedAt === null
    })
  }
}

const mutations = {
  saveOrganization: (state, organization) => {
    state.organization = organization
    state.organizationIsLoaded = true
  },
  saveUsers: (state, users) => {
    state.users = users
    state.usersAreLoaded = true
  }
}

const actions = {
  async getOrganization ({ commit }) {
    // gets orga for current users and users of that orga
    const response = (await OrganizationApi.getOrganization()).data
    commit('saveOrganization', response.organization)
    commit('saveUsers', response.users)
  },
  async getOrganizationUsersProjectsUser ({ commit, state, rootState }) {
    const response = (await OrganizationApi.getOrganizationUsersProjectsUser()).data
    commit('saveOrganization', response.organization)
    commit('saveUsers', response.users)
    commit('saveUser', response.user)
    commit('saveProjects', response.projects)
  }

}

export default {
  state,
  getters,
  mutations,
  actions
}
