import axios from 'axios'
import store from '@/store/store'

export default {
  createInvite (invite) {
    return axios.post('/api/invites', invite, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  getInvites () {
    return axios.get('/api/invites', {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  deleteInviteById (inviteId) {
    return axios.delete(`/api/invites/${inviteId}`, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  }
}
