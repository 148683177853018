import InvitesApi from '../../api/InvitesApi.js'

const state = {
  invites: null,
  invitesAreLoaded: false
}

const mutations = {
  saveInvites: (state, invites) => {
    state.invites = invites
    state.invitesAreLoaded = true
  }
}

const actions = {
  async getInvites ({ commit }) {
    const invites = (await InvitesApi.getInvites()).data
    commit('saveInvites', invites)
  }
}

export default {
  state,
  mutations,
  actions
}
