import axios from 'axios'
import store from '@/store/store'

export default {
  getTicketsStatusesPrioritiesOfProject (params) {
    return axios.get(`/api/${params.projectId}/tickets-statuses-priorities`, {
      params: {
        page: params.query.page,
        status: params.query.status,
        assignee: params.query.assignee,
        reporter: params.query.reporter,
        sortBy: params.query.sortBy,
        search: params.query.search
      },
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  getTicketById (ticketId) {
    return axios.get(`/api/tickets/${ticketId}`, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  createTicket (ticket) {
    return axios.post('/api/tickets', ticket, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  updateTicket (ticket) {
    return axios.put(`/api/tickets/${ticket.id}`, ticket, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  deleteTicketById (ticketId) {
    return axios.delete(`/api/tickets/${ticketId}`, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  }
}
