const state = {
  user: null
}

const mutations = {
  saveUser: (state, user) => {
    state.user = user
  }
}

const actions = {

}

export default {
  state,
  mutations,
  actions
}
