<template>
  <div class="select-status">
    <swift-dropdown menu-max-width="200px">
      <template slot="dropdown-button">
        <div class="selected-value" v-if="!selectedStatus">Select status</div>
        <div
          v-else
          class="selected-value">
          <div class="selected-item">
            <div
              class="item-color"
              :style="{ 'background-color': selectedStatus.color }"/>
            <div class="selected-item-name">
              {{ selectedStatus.name }}
            </div>
          </div>
        </div>
        <icon-sort-down
          width="14px"
          height="14px"
          fill="#4b586c" />
      </template>
      <swift-dropdown-item
        @selected="clearSelect()"
        slot="dropdown-items">
        <div class="clear-select">No status</div>
      </swift-dropdown-item>
      <swift-dropdown-item
        v-for="status in sortedStatuses"
        :key="status.id"
        :value="status"
        @selected="selectStatus(status)"
        slot="dropdown-items">
        <div class="item">
          <div
            class="item-color"
            :style="{ 'background-color': status.color }"/>
          <div class="item-name">
            {{ status.name }}
          </div>
        </div>
      </swift-dropdown-item>
    </swift-dropdown>
  </div>
</template>

<script>
import SwiftDropdown from './SwiftDropdown.vue'
import IconSortDown from './icons/IconSortDown.vue'
import SwiftDropdownItem from './SwiftDropdownItem.vue'

export default {
  components: {
    SwiftDropdown,
    IconSortDown,
    SwiftDropdownItem
  },
  props: ['initialStatus', 'setInitialStatus'],
  data () {
    return {
      selectedStatus: this.initialStatus
    }
  },
  mounted () {
    if (this.setInitialStatus) {
      this.selectStatus(this.initialStatus)
    }
  },
  computed: {
    sortedStatuses () {
      return this.$store.getters.sortedStatuses
    }
  },
  methods: {
    selectStatus (status) {
      this.selectedStatus = status
      this.$emit('selectedStatus', status)
    },
    clearSelect () {
      this.selectedStatus = null
      this.$emit('selectedStatus', null)
    }
  }
}
</script>

<style scoped>

.select-status {
  width: 100%;
}

.selected-value {
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
}

.selected-item {
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
}

.item {
  display: flex;
  width: 100%;
  align-items: center;

}

.item-color {
  display: flex;
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-right: 4px;
}

.selected-item-name {
  display: flex;
  width: calc(100% - 21px);
}

.item-name {
  display: flex;
  width: calc(100% - 21px);
  overflow: hidden;
}

</style>
