<template>
  <div class="select-priority">
    <div class="select-priority-label global-label">
      Choose Priority
    </div>
    <div class="select-priority-select">
      <ticket-create-select-priority-button
        v-for="priority in $store.state.priorities.priorities"
        :key="priority.value"
        :priority="priority"
        :selected-priority="selectedPriority"
        @selectedprio="setSelectedPriorityValue($event)" />
    </div>
  </div>
</template>

<script>
import TicketCreateSelectPriorityButton from './TicketCreateSelectPriorityButton.vue'

export default {
  components: {
    TicketCreateSelectPriorityButton
  },
  data: function () {
    return {
      selectedPriority: null
    }
  },

  methods: {
    setSelectedPriorityValue (priority) {
      this.selectedPriority = priority.value
      this.$store.commit('setCreateTicketPriorityValue', priority.value)
    }
  }
}
</script>

<style scoped>
.select-priority {
  margin-top: 20px;
  max-width: 520px;
}

.select-priority-select {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.select-priority-option {
  display: flex;
  max-width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  color: #FFFFFF;
  font-weight: 600;
}

.select-priority-option-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1;
}

.select-priority-option-checkmark {
  display: flex;
}

</style>
