<template>
  <div class="user-avatar">
    <!-- User uploaded own image -->
    <img
      v-if="user.avatarImg"
      class="user-avatar-image"
      :src="user.avatarImg"
      :style="{ height: imgHeight, width: imgWidth}">
    <!-- Default round div based on initials -->
    <div
      v-else
      class="user-avatar-image initials"
      :style="{ height: imgHeight, width: imgWidth, fontSize: fontSize, backgroundColor: backgroundColor}">
      {{ initials }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      required: true
    }
  },
  computed: {
    imgHeight () {
      if (this.size) {
        return this.size + 'px'
      } else {
        return false
      }
    },
    imgWidth () {
      if (this.size) {
        return this.size + 'px'
      } else {
        return false
      }
    },
    fontSize () {
      if (this.size) {
        return Math.round(this.size / 2.2) + 'px'
      } else {
        return false
      }
    },
    initials () {
      var upperCaseName = this.user.name.toUpperCase().replace(/[^A-Za-z/-]/g, '')
      var initials = null

      if (upperCaseName.length > 1) {
        initials = upperCaseName[0] + upperCaseName[1]
      } else {
        initials = upperCaseName[0]
      }

      return initials
    },
    backgroundColor () {
      // random color choose based on user id ensures that colors stays the same for same user but differs for different users
      var userId = this.user.id
      var colors = [
        '#a96bff', '#60cd91', '#ff9757', '#bd794c', '#5698fb', '#fb7474', '#4f05b8', '#00853c', '#008f88', '#7c431d', '#0043a8', '#d14d00', '#bd0505'
      ]
      var lastDigitOfId = userId % 10
      var randomColorIndex = Math.round(lastDigitOfId / 10 * colors.length)
      var randomColor = colors[randomColorIndex]
      return randomColor
    }
  }
}
</script>

<style scoped>
.user-avatar {
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.user-avatar-image {
  /* defautl sizes if size is not specified */
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.initials {
  color: #ffffff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
