import axios from 'axios'
import store from '@/store/store'

export default {
  createAttachments (attachments) {
    return axios.post('/api/attachments', attachments, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  }
}
