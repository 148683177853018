import ProjectsApi from '../../api/ProjectsApi.js'
import router from '../../router.js'

const state = {
  currentProjectId: null,
  projects: [],
  projectsAreLoaded: false
}

const getters = {
  getProjectById: (state) => (projectId) => {
    return state.projects.find(element => element.id === projectId)
  }
}

const mutations = {
  saveProjects: (state, projects) => {
    state.projects = projects
    state.projectsAreLoaded = true

    if (!state.currentProjectId) {
      if (router.history.current.params.projectId) {
        state.currentProjectId = router.history.current.params.projectId
      } else {
        state.currentProjectId = state.projects[0].id
      }
    }
  },
  setCurrentProjectId: (state, currentProjectId) => {
    state.currentProjectId = currentProjectId
  },
  routeToTicketListOfProject: (state, routeData) => {
    router.push({ name: 'tickets',
      params: {
        projectId: routeData.projectId.toString(),
        organizationName: routeData.organizationName
      }
    })
  }
}

const actions = {
  async getProjects ({ commit, dispatch, state }) {
    const response = (await ProjectsApi.getProjects()).data
    commit('saveProjects', response.projects)
  },
  async createProject ({ dispatch }, projectData) {
    await ProjectsApi.createProject({
      name: projectData.name
    })
    dispatch('getProjects')
  },
  async updateProject ({ commit, dispatch, state, rootState }, updatedProject) {
    await ProjectsApi.updateProject(updatedProject)
    await dispatch('getProjects')
  },
  async deleteProjectById ({ commit, dispatch, state, rootState }, projectId) {
    await ProjectsApi.deleteProjectById(projectId)
    // reroute before projects are loaded because otherwise user is one empty project
    router.push({ name: 'organization' })
    await dispatch('getProjects')
  },
  selectProject ({ commit, getters, dispatch, rootState }, projectId) {
    commit('setCurrentProjectId', projectId)

    const routeData = {
      projectId: projectId,
      organizationName: rootState.organization.organization.name
    }
    commit('routeToTicketListOfProject', routeData)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
