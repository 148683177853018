<template>
  <div
    class="global-modal ticket-modal"
    v-if="$store.state.tickets.ticketIsLoaded"
    @mousedown="ignoreBackdropClick = true"
    @mouseup="ignoreBackdropClick = false">
    <div class="title-row global-border-bottom">
      <div class="avatar-container">
        <swift-user-avatar
          :user="getUserById(ticket.reporterId)"
          :size="36" />
      </div>
      <div class="title-head" >
        <div class="global-form-error" v-if="titleErrors.length">
          <div v-for="error in titleErrors" :key="error.index">
            {{error}}
          </div>
        </div>
        <div class="title-container">
          <div class="title" :class="{'editable-outline': titleOutlineIsActive}" v-if="!editTitleMode" @mouseover="showTitleOutline(ticket.reporterId)" @mouseout="hideTitleOutline()" @click="activateEditTitleMode(ticket.reporterId)">{{ ticket.title }}</div>

          <div class="title-edit-container" v-if="editTitleMode">
            <input
              class="global-input title-edit"
              type="text"
              v-model="editableTitle"
              autocomplete="off"
              ref="title"
              @keyup.enter="$event.target.blur()"
              @blur="updateTitleOfTicket(ticket.id)"
              :class=" { 'global-input-invalid': titleErrors.length}"
            >
          </div>
        </div>
        <div class="subtitle">
          #{{ ticket.number }} &#8226; by {{ getUserById(ticket.reporterId).name }} &#8226; created {{ formatDistanceToNow(ticket.createdAt) }} &#8226; updated {{ formatDistanceToNow(ticket.updatedAt) }}
        </div>
      </div>
      <div class="close-container">
        <swift-close-round height="12px" width="12px" @click.native="closeTicketModal()" />
      </div>
    </div>
    <div class="mainpanel">
      <div class="content">

        <div class="description" v-if="!editDescriptionMode" :class="{'editable-outline': descriptionOutlineIsActive}" @mouseover="showDescriptionOutline(ticket.reporterId)" @mouseout="hideDescriptionOutline()" @click="activateEditDescriptionMode(ticket.reporterId)">{{ ticket.description }}
        </div>
        <div class="widget-report" v-if="widgetInfo">
          <div class="widget-report-child" v-if="pageUrl"> &#8226; <a class="global-link widget-report-child" :href="pageUrl" target="blank"> {{ pageUrl }} </a></div><div class="widget-report-child" v-if="clientBrowser"> &#8226; <icon-browser class="browser-icon" :browser="clientBrowser" height="14px" width="14px" fill="#60718a"/>{{clientBrowser}} </div><div class="widget-reporter-child" v-if="clientBrowserVersion">{{clientBrowserVersion}} </div><div class="widget-report-child" v-if="clientOs"> &#8226; <icon-os class="os-icon" :os="clientOs" height="14px" width="14px" fill="#60718a"/>{{clientOs}} </div><div class="widget-reporter-child" v-if="clientOsVersion">{{clientOsVersion}} </div><div class="widget-report-child" v-if="widgetId"> &#8226; reported via <div class="global-link" @click="routeToWidget(widgetId)">widget </div></div><div class="widget-report-child" v-if="clientEmail"> &#8226; reported by {{clientEmail}} </div>
        </div>
        <div class="description-edit-container" v-if="editDescriptionMode">
          <textarea
            class="global-input-resizeable description-edit"
            type="text"
            v-model="editableDescription"
            autocomplete="off"
            ref="description"
            @input="resizeDescription()"
            @blur="updateDescriptionOfTicket(ticket.id)"
          />
        </div>
        <div class="attachments-container" v-if="ticket.attachments && ticket.attachments.length > 0">
          <ticket-attachments
            :attachments="ticket.attachments"
            :selectable="true" />
        </div>
        <div class="content-actions">
          <div>
            <swift-upload @filesUploaded="addAttachmentsToTicket($event)" @uploadStatusChanged="updateUploadStatus($event)"/>
          </div>
          <swift-button
            id="delete-ticket"
            @click.native="showDeleteConfirmation = true"
            color="link">
            Delete ticket
          </swift-button>
          <portal v-if="showDeleteConfirmation === true">
            <div class="global-overlay-shadow" @click.stop.self="showDeleteConfirmation = false">
              <div class="global-modal global-confirmation-modal">
                <div class="global-confirmation-modal-body global-border-bottom">
                  <div class="global-confirmation-modal-title">
                    Delete this ticket permanently?
                  </div>
                  <div class="confirmation-ticket-title">
                    {{ ticket.title }}
                  </div>
                  #{{ ticket.number }} created {{ formatDistanceToNow(ticket.createdAt) }} by {{ getUserById(ticket.reporterId).name }}
                </div>
                <div class="global-confirmation-modal-footer">
                  <swift-button id="confirm-delete-ticket" color="blue" @click.native="deleteTicketById(ticket.id)">Delete ticket</swift-button>
                  <swift-button color="link" @click.native="showDeleteConfirmation = false">Cancel</swift-button>
                </div>
              </div>
            </div>
          </portal>
        </div>
        <ticket-comments />
      </div>
      <div class="settings">
        <div class="setting" v-if="$store.state.status.statuses.length">
          <div class="setting-left">
            <div class="global-label">
              Status
            </div>
          </div>
          <div class="setting-right">
            <swift-select-status
              :initial-status="getStatusById(ticket.statusId)"
              @selectedStatus="updateStatusOfTicket($event, ticket.id)" />
          </div>
        </div>
        <div class="setting">
          <div class="setting-left">
            <div class="global-label">
              Assigned
            </div>
          </div>
          <div class="setting-right">
            <swift-select-assignee
              :initial-assignee="getUserById(ticket.assigneeId)"
              @selectedAssignee="updateAssigneeOfTicket($event, ticket.id)"  />
          </div>
        </div>
        <div class="setting">
          <div class="setting-left">
            <div class="global-label">
              Priority
            </div>
          </div>
          <div class="setting-right">
            <swift-select-priority
              :initial-priority="getPriorityByValue(ticket.priorityValue)"
              @selectedPriority="updatePriorityValueOfTicket($event.value, ticket.id)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Portal } from '@linusborg/vue-simple-portal'
import TicketsApi from '../api/TicketsApi.js'
import AttachmentsApi from '../api/AttachmentsApi.js'
import SwiftCloseRound from './SwiftCloseRound.vue'
import SwiftButton from './SwiftButton.vue'
import SwiftSelectPriority from './SwiftSelectPriority.vue'
import SwiftSelectAssignee from './SwiftSelectAssignee.vue'
import SwiftUserAvatar from './SwiftUserAvatar.vue'
import SwiftSelectStatus from './SwiftSelectStatus.vue'
import TicketAttachments from './TicketAttachments.vue'
import TicketComments from './TicketComments.vue'
import SwiftUpload from './SwiftUpload.vue'
import IconBrowser from './icons/IconBrowser.vue'
import IconOs from './icons/IconOs.vue'
import { formatDistanceToNow, parseISO } from 'date-fns'

export default {
  components: {
    SwiftButton,
    SwiftCloseRound,
    SwiftSelectPriority,
    SwiftSelectAssignee,
    SwiftUserAvatar,
    SwiftSelectStatus,
    TicketAttachments,
    TicketComments,
    SwiftUpload,
    IconBrowser,
    IconOs,
    Portal
  },
  props: ['closeModal'],
  data: function () {
    return {
      ignoreBackdropClick: false,
      showDeleteConfirmation: false,
      titleOutlineIsActive: false,
      editTitleMode: false,
      editableTitle: '',
      titleErrors: [],
      descriptionOutlineIsActive: false,
      editDescriptionMode: false,
      editableDescription: '',
      uploadStatus: null,
      uploadErrors: [],
      clients: [],
      htmls: []
    }
  },
  async created () {
    this.$store.dispatch('getTicketById', this.$route.params.ticketId)
  },
  computed: {
    ticket () {
      return this.$store.getters.getTicket
    },
    getUserById () {
      return this.$store.getters.getUserById
    },
    getPriorityByValue () {
      return this.$store.getters.getPriorityByValue
    },
    getStatusById () {
      return this.$store.getters.getStatusById
    },
    widgetInfo () {
      // emptyy check
      if (this.$store.state.tickets.ticket.client && this.$store.state.tickets.ticket.client !== null && this.$store.state.tickets.ticketIsLoaded) {
        return true
      } else {
        return false
      }
    },
    pageUrl () {
      if (this.$store.state.tickets.ticket.client.pageUrl) {
        return this.$store.state.tickets.ticket.client.pageUrl
      } else {
        return false
      }
    },
    clientEmail () {
      if (this.$store.state.tickets.ticket.client.email) {
        return this.$store.state.tickets.ticket.client.email
      } else {
        return false
      }
    },
    clientOs () {
      if (this.$store.state.tickets.ticket.client.os) {
        return this.$store.state.tickets.ticket.client.os
      } else {
        return false
      }
    },
    clientOsVersion () {
      if (this.$store.state.tickets.ticket.client.osVersion) {
        return this.$store.state.tickets.ticket.client.osVersion
      } else {
        return false
      }
    },
    clientBrowser () {
      if (this.$store.state.tickets.ticket.client.browser) {
        return this.$store.state.tickets.ticket.client.browser
      } else {
        return false
      }
    },
    clientBrowserVersion () {
      if (this.$store.state.tickets.ticket.client.browserVersion) {
        return this.$store.state.tickets.ticket.client.browserVersion
      } else {
        return false
      }
    },
    clientBrowserLanguage () {
      if (this.$store.state.tickets.ticket.client.browserLanguage) {
        return this.$store.state.tickets.ticket.client.browserLanguage
      } else {
        return false
      }
    },
    widgetId () {
      if (this.$store.state.tickets.ticket.client.widgetId) {
        return this.$store.state.tickets.ticket.client.widgetId
      } else {
        return false
      }
    }
  },
  watch: {
    closeModal () {
      this.closeTicketModal()
    }
  },
  methods: {
    async updateStatusOfTicket (newStatus, ticketId) {
      this.$store.dispatch('updateStatusOfTicket', { newStatus, ticketId })
    },
    updateAssigneeOfTicket (newAssignee, ticketId) {
      this.$store.dispatch('updateAssigneeOfTicket', { newAssignee, ticketId })
    },
    updatePriorityValueOfTicket (newPriorityValue, ticketId) {
      this.$store.dispatch('updatePriorityValueOfTicket', { newPriorityValue, ticketId })
    },
    async updateTitleOfTicket (ticketId) {
      if (!this.checkTitle()) {
        return
      }
      // do nothing if title was not changed
      if (this.editableTitle === this.ticket.title) {
        this.editTitleMode = false
        return
      }

      const ticket = {
        id: ticketId,
        title: this.editableTitle
      }
      await TicketsApi.updateTicket(ticket)

      await this.$store.dispatch('updateTicketById', ticketId)
      this.editTitleMode = false
    },
    async updateDescriptionOfTicket (ticketId) {
      // do nothing if description was not changed
      if (this.editableDescription === this.ticket.description) {
        this.editDescriptionMode = false
        return
      }
      const ticket = {
        id: ticketId,
        description: this.editableDescription
      }
      await TicketsApi.updateTicket(ticket)
      await this.$store.dispatch('updateTicketById', ticketId)
      // only leave the edit mode at the end because otherwise the not saved contetn will dissappear and reappear
      this.editDescriptionMode = false
    },
    async addAttachmentsToTicket (attachments) {
      // add ticketId to attachments
      attachments.forEach(element => {
        element.ticketId = this.ticket.id
      })
      await AttachmentsApi.createAttachments(attachments)
      await this.$store.dispatch('updateTicketById', this.ticket.id)
    },
    deleteTicketById (id) {
      this.$store.dispatch('deleteTicketById', id)
    },
    closeTicketModal () {
      this.$store.commit('hideModal')
      this.$store.commit('hideTicketModal')
      this.$router.push({ name: 'tickets',
        query: {
          ...this.$route.query
        }
      })
    },
    showTitleOutline (reporterId) {
      if (reporterId === this.$store.state.user.user.id) {
        this.titleOutlineIsActive = true
      }
    },
    hideTitleOutline () {
      this.titleOutlineIsActive = false
    },
    activateEditTitleMode (reporterId) {
      if (reporterId === this.$store.state.user.user.id) {
        this.titleOutlineIsActive = false
        this.editableTitle = this.ticket.title
        this.editTitleMode = true
        this.$nextTick(() => this.$refs.title.focus())
      }
    },
    checkTitle () {
      this.titleErrors = []

      if (!this.editableTitle) {
        this.titleErrors.push('Title required')
        return false
      }

      if (!this.titleErrors.length) {
        return true
      }
    },
    showDescriptionOutline (reporterId) {
      if (reporterId === this.$store.state.user.user.id) {
        this.descriptionOutlineIsActive = true
      }
    },
    hideDescriptionOutline () {
      this.descriptionOutlineIsActive = false
    },
    activateEditDescriptionMode (reporterId) {
      if (reporterId === this.$store.state.user.user.id) {
        this.descriptionOutlineIsActive = false
        this.editableDescription = this.ticket.description
        this.editDescriptionMode = true
        this.$nextTick(() => {
          this.$refs.description.focus()
          this.resizeDescription()
        })
      }
    },
    resizeDescription () {
      this.$refs.description.style.height = ''
      this.$refs.description.style.height = this.$refs.description.scrollHeight + 'px'
    },
    formatDistanceToNow (date) {
      return formatDistanceToNow(parseISO(date)) + ' ago'
    },
    updateUploadStatus (status) {
      this.uploadStatus = status

      if (status === 'success') {
        this.uploadErrors = []
      }
    },
    routeToWidget (widgetId) {
      this.$router.push({
        name: 'widget',
        params: { widgetId: widgetId } })
    }
  }
}
</script>

<style scoped>
.mainpanel {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}
@media (max-width: 800px) {
  .mainpanel {
    flex-direction: column;
  }
}

.ticket-modal {
    position: relative;
    margin-left: 180px; /* beware: if you use auto here the modla will jump on opening, so we use a fixed margin for the max-layout of the table and when the tbale goes to small it will go back to auto */
    margin-top: 1%;
    margin-bottom: 1%;
    width: 100%;
    border-radius: 4px;
    max-width: 1200px;
}

@media (max-width: 1400px) {
    .ticket-modal {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 1025px) {
    .ticket-modal {
        width: 100%;
        min-height: 100%;
        border-radius: 0px;
        max-width: 100%;
        margin: 0px;
    }
}

.title-row {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 25px;
    padding-left: 25px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.avatar-container {
    display: flex;
    /* paddin is since the title is abit lower because of line-height */
    padding-top: 3px;
    height: 100%;
    align-items: center;
}

.title-head {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title-container {
    display: flex;
    align-items: center;
}

.title {
  width: 100%;
  line-height: 1.1;
  padding-top: 1px;
  padding-bottom: 3px;
  padding-left: 5px;
  margin-left: 2px;
  padding-right: 7px;
  font-size: 18px;
  font-weight: 600;
}
.title-edit-container {
  width: 100%;

}
.title-edit {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
   margin-left: 2px;
  padding-right: 6px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}

.subtitle {
  line-height: 1;
  font-size: 14px;
  padding-left: 7px;
  font-weight: 400;
  color: #9ba8bb;
}
.close-container {
  display: flex;
  flex-basis: 100px;
  justify-content: flex-end;
}
.content {
    width: 100%;
}

.content-actions {
  display: flex;
  justify-content: flex-end;
  margin-right: 35px;

}

.settings {
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;
  padding-top: 20px;
  padding-bottom: 20px;
  border-left: 1px solid #e6eaef;
  border-bottom-right-radius: 5px;
}
@media (max-width: 800px) {
  .settings {
    /* puts settings right below headbar on mobile */
    order: -1;
    border: 1px solid #e6eaef;
    padding-top: 10px;
    padding-bottom: 0px;
  }
}

.setting {
  display: flex;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;

}
.setting-left {
  display: flex;
  align-items: center;
  width: 70px;
}
.setting-right {
  display: flex;
  align-items: center;
  width: 130px;
}

.settings-input {
    display: flex;
    align-items: center;
}

.widget-report {
  display: flex;
  flex-wrap: wrap;
  white-space: pre-wrap;
  margin-left: 23px;
  margin-right: 23px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
  color:#9ba8bb;
  word-break: break-all;
}

.widget-report-child {
  display: flex;
  white-space: pre-wrap;
}

.browser-icon, .os-icon {
  padding-right: 3px;
}

.description {
    font-size: 15px;
    margin-top: 15px;
    padding-top: 5px;
    margin-bottom: 30px;
    margin-left: 23px;
    margin-right: 23px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
    white-space: pre-wrap;
}

.editable-outline {
  box-shadow: 0 0 0 1px #d9dfe7;
  border-radius: 4px;
  cursor: pointer;
}

.description-edit-container {
  margin-top: 17px;
  margin-bottom: 28px;
  margin-left: 22px;
  margin-right: 23px;
}
.description-edit {
  display: block;
  width: 100%;
  font-size: 15px;
  white-space: pre-wrap;
}

.attachments-container {
  margin-left: 35px;
  margin-right: 35px;
  margin-bottom: 20px;
}

.confirmation-ticket-title {
  font-weight: 600;
}

</style>
