<template>
  <div class="login-positioner">
    <div class="logo">
      <icon-logo-full width="180px" height="40px" icon-fill="#1764d9" text-fill="#434f60" />
    </div>
    <div class="login">
      <div class="success-message" v-if="forgotPasswordSuccess">
        {{ resetMessage }}
      </div>
      <form v-if="!forgotPasswordSuccess" class="login-form"
            id="login-form"
            novalidate
            @submit.prevent="forgotPassword()" >
        <div class="login-title">Forgot your password?</div>
        <div class="description">Please enter your Swiftbug account email to request a password reset email</div>
        <label for="email" class="global-label email-label">Email</label>
        <div class="global-form-error" v-if="emailErrors.length">
          <div v-for="error in emailErrors" :key="error.index">
            {{error}}
          </div>
        </div>
        <input
          class="global-input"
          type="email"
          name="email"
          novalidate
          autofocus
          spellcheck="false"
          autocapitalize="off"
          v-model="email"
          :class=" { 'global-input-invalid': emailErrors.length}"
        >

        <swift-button
          class="login-button"
          color="blue"
          shadow="shadow"
          size="large"
          type="submit"
          :is-loading="forgotPasswordIsLoading"
        >
          Send reset link
        </swift-button>
      </form>
      <div class="register-remark global-border-top">
        <router-link
          to="/login"
          class="global-link">Back to login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SwiftButton from './SwiftButton.vue'
import IconLogoFull from './icons/IconLogoFull.vue'

export default {
  components: {
    SwiftButton,
    IconLogoFull
  },
  data () {
    return {
      forgotPasswordSuccess: false,
      resetMessage: '',
      email: '',
      emailErrors: [],
      forgotPasswordIsLoading: false
    }
  },
  methods: {
    async forgotPassword () {
      if (!this.checkEmail()) {
        return
      }

      this.forgotPasswordIsLoading = true
      try {
        const response = await this.$store.dispatch('forgotPassword', { email: this.email })
        if (response.resetMessage) {
          this.resetMessage = response.resetMessage
          this.forgotPasswordSuccess = true
        }
      } catch (error) {
        this.forgotPasswordIsLoading = false
      }
      this.forgotPasswordIsLoading = false
    },
    checkEmail () {
      this.emailErrors = []

      if (!this.email) {
        this.emailErrors.push('Email required')
        return false
      }

      var validEmail = (email) => {
        var regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        return regex.test(email)
      }

      if (!validEmail(this.email)) {
        this.emailErrors.push('Please enter a valid email adress')
        return false
      }

      if (!this.emailErrors.length) {
        return true
      }
    }
  }

}
</script>

<style scoped>
.login-positioner {
  margin-top: -5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  margin-bottom: 38Px;
}

.login {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e0e4eb;
  width: 360px;
  display: flex;
  flex-direction: column;
  padding: 36px;
  box-shadow: 0px 2px 12px hsla(216, 18%, 19%, 0.1);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-title {
  display: flex;
  justify-content: center;
  color: #36404e;
  font-size: 26px;
  font-weight: 600;
}

.description {
  display: flex;
  padding-top: 10px;
}

.email-label {
  margin-top: 15px;
}

.login-button {
  width: 100%;
  margin-top: 15px;
}

.register-remark {
  margin-top: 20px;
  padding-top: 10px;
}

.success-message {
  background-color: #f6fefa;
  color: #007a37;
  border: solid 1px #b6edce;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
}
</style>
