<template>
  <div class="dropdown">
    <button
      type="button"
      :class="buttonStyle"
      @click.stop="toggleDropdown">
      <slot name="dropdown-button">Toggle Dropdown</slot>
    </button>
    <div
      class="dropdown-menu"
      :style="{'max-width': menuMaxWidth}"
      v-if="dropdownIsOpen"
      v-click-outside="checkIfMousedownWasOutside"
      @click="closeDropdown"
      @mousedown="ignoreBackdropClick = true"
      @mouseup="ignoreBackdropClick = false">
      <slot name="dropdown-items">Action</slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    buttonStyle: {
      type: String,
      default: 'default-button'
    },
    menuMaxWidth: {
      type: String
    }
  },
  data: function () {
    return {
      dropdownIsOpen: false,
      ignoreBackdropClick: false
    }
  },
  methods: {
    toggleDropdown () {
      this.dropdownIsOpen = !this.dropdownIsOpen
    },
    closeDropdown () {
      this.dropdownIsOpen = false
    },
    checkIfMousedownWasOutside () {
      // only close when mousedown AND mouseup initiated outside
      if (this.ignoreBackdropClick) {
        this.ignoreBackdropClick = false
      } else {
        this.closeDropdown()
      }
    }
  }
}
</script>

<style scoped>
.dropdown {
  position: relative;
  width: 100%;
}

.default-button {
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  justify-content: space-between;
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #FFFFFF;
  border: solid 1px #d9dfe7;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  user-select: none;
}
.default-button:hover {
  border: 1px solid #9ba8bb;
  transition-duration: 0.3s;
}

.default-button:focus {
  border: 1px solid #1764d9;
  box-shadow: 0 0 0 2px #c7ddff;
  /* ensures shadows overlays surroundign element */
  z-index: 1;
  transition-duration: 0.3s;
}

.invisible-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  user-select: none;
}

.dropdown-menu {
  position: absolute;
  margin-top: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #FFFFFF;
  min-width: 100%;
  border-radius: 4px;
  border: solid 1px #d9dfe7;
  box-shadow: 0px 2px 12px hsla(216, 18%, 19%, 0.1);
  z-index: 1;
  overflow: hidden;
  max-width: 200px;
}

</style>
