<template>
  <div class="select-priority">
    <swift-dropdown>
      <template slot="dropdown-button">
        <div class="selected-value" v-if="!selectedPriority">Select priority</div>
        <div
          v-else
          class="selected-value">
          <swift-priority-tag
            :priority="selectedPriority"/>
        </div>
        <icon-sort-down
          width="14px"
          height="14px"
          fill="#4b586c" />
      </template>
      <swift-dropdown-item
        @selected="clearSelect()"
        slot="dropdown-items">
        <div class="clear-select">No priority</div>
      </swift-dropdown-item>
      <swift-dropdown-item
        v-for="priority in $store.state.priorities.priorities"
        :key="priority.value"
        :value="priority"
        @selected="selectPriority"
        slot="dropdown-items"
        class="items">
        <swift-priority-tag
          :priority="priority"/>
      </swift-dropdown-item>
    </swift-dropdown>
  </div>
</template>

<script>
import SwiftDropdown from './SwiftDropdown.vue'
import IconSortDown from './icons/IconSortDown.vue'
import SwiftDropdownItem from './SwiftDropdownItem.vue'
import SwiftPriorityTag from './SwiftPriorityTag.vue'

export default {
  components: {
    SwiftDropdown,
    IconSortDown,
    SwiftDropdownItem,
    SwiftPriorityTag
  },
  props: ['initialPriority'],
  data () {
    return {
      selectedPriority: this.initialPriority
    }
  },
  methods: {
    selectPriority (priority) {
      this.selectedPriority = priority
      this.$emit('selectedPriority', priority)
    },
    clearSelect () {
      this.selectedPriority = null
      this.$emit('selectedPriority', null)
    }
  }
}
</script>

<style scoped>
.select-priority {
  width: 100%;
}

.selected-value {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.items {
  display: flex;
}

</style>
