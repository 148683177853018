import axios from 'axios'
import store from '../store/store'

export default {
  register (credentials) {
    return axios.post('/api/auth/register', credentials)
  },
  registerInvite (credentials) {
    return axios.post('/api/auth/register-invite', credentials)
  },
  login (credentials) {
    return axios.post('/api/auth/login', credentials)
  },
  forgotPassword (email) {
    return axios.post('/api/auth/forgot-password', email)
  },
  resetPassword (credentials) {
    return axios.post('/api/auth/reset-password', credentials)
  },
  logout () {
    return axios.post('/api/auth/logout', null, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  impersonate (email) {
    console.log('api impersonate', email)
    return axios.post('/api/auth/impersonate', email, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  }

}
