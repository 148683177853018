<template>
  <button
    type="button"
    class="swift-button"
    :class="[color, shadow, active, link, size, shape]">
    <slot>Submit</slot>
    <icon-spinner v-if="isLoading" class="spinner" fill="#FFFFFF" width="14px" height="14px" />
  </button>
</template>

<script>
import IconSpinner from './icons/IconSpinner.vue'

export default {
  components: {
    IconSpinner
  },
  props: {
    color: {
      type: String
    },
    shadow: {
      type: String
    },
    active: {
      type: String
    },
    link: {
      type: String
    },
    size: {
      type: String
    },
    shape: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.swift-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    line-height:  1;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    outline: none;
    padding: 8px 14px;
    color: #FFFFFF;
    border: none;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    user-select: none;
}

.swift-button:focus {
  box-shadow: 0 0 0 2px #c7ddff;
  /* ensures shadows overlays surroundign element */
  z-index: 1;
  transition-duration: 0.3s;
}
.shadow {
  background-color: #1764d9;
  box-shadow: 0px 3px 5px hsla(216, 18%, 19%, 0.12);
  font-weight: 600;
}
.shadow:active {
  box-shadow: 0px 3px 5px hsla(216, 18%, 19%, 0);
  transition-duration: 0.3s;
}
.blue {
  background: #1764d9;
  font-weight: 600;
}
.blue:hover {
    background-color: #004ec2;
    transition-duration: 0.3s;
}
.blue:active {
    background-color: #347be5;
    transition-duration: 0.3s;
}
.blue:focus {
  box-shadow: 0 0 0 2px #c7ddff;
  /* ensures shadows overlays surroundign element */
  z-index: 1;
  transition-duration: 0.3s;
}

.green {
  background: #0dab54;
  font-weight: 600;
}
.green:hover {
    background-color: #009945;
    transition-duration: 0.3s;
}
.green:active {
    background-color: #3cbe76;
    transition-duration: 0.3s;
}

.light {
  background-color: transparent;
  font-weight: 400;
  padding:7px 13px; /* reduced padding to adjust for border */
  color: #347be5;
  border: 1px solid #347be5;
}

.light-inactive {
  background-color: transparent;
  font-weight: 400;
  padding:7px 13px; /* reduced padding to adjust for border */
  color: #c8cfda;
  border: 1px solid #c8cfda;
}

.light-inactive:focus {
  box-shadow: none;

}

.light-black {
  color: #60718a;
  border: 1px solid #60718a;
}

.light:hover {
  color: #0043a8;
  border: 1px solid #0043a8;
}

.light-black:hover {
  color: #282f39;
  border: 1px solid #282f39;

}

.red {
  background: #db2929;
  font-weight: 600;
}
.red:hover {
    background-color: #c91818;
    transition-duration: 0.3s;
}
.red:active {
    background-color: #f44848;
    transition-duration: 0.3s;
}

.white {
  background: #ffffff;
  border: 1px solid #e0e4eb;
  padding:7px 13px; /* reduced padding to adjust for border */
  color: #4b586c;
  font-weight: 600;
}
.white:hover {
  color: #1764d9;
  background-color: #f9fafb;
  transition-duration: 0.3s;
}
.white:active {
  color: #1764d9;
  background-color: #ffffff;
  transition-duration: 0.3s;
}
.white:focus {
  box-shadow: 0 0 0 2px #c7ddff;
  border: 1px solid #1764d9;
  /* ensures shadows overlays surroundign element */
  z-index: 1;
  transition-duration: 0.3s;
}

.whiteongray {
  background: #ffffff;
  border: 1px solid #c8cfda;
  color: #4b586c;
  font-weight: 600;
}
.whiteongray:hover {
  color: #1764d9;
  background-color: #f9fafb;
  transition-duration: 0.3s;
}
.whiteongray:active {
  color: #1764d9;
  background-color: #ffffff;
  transition-duration: 0.3s;
}

.black {
  background: #60718a;
  font-weight: 600;
}
.black:hover {
    background-color: #546278;
    transition-duration: 0.3s;
}
.black:active {
    background-color: #7988a0;
    transition-duration: 0.3s;
}

.inactive {
  border: 1px solid #d9dfe7;
  background-color: #e0e4eb;
  color: #c8cfda;
}
.inactive:hover {
  background-color: #e0e4eb;
  transition-duration: 0.3s;
}
.link {
  background-color: transparent;
  color: #1764d9;
  font-weight: 400;
}
.link:hover {
   color: #004ec2;
}

.simplelink {
  background-color: transparent;
  color: #1764d9;
  font-weight: 400;
  padding: 0;
}
.simplelink:hover {
   color: #004ec2;
}

.small {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
}

.medium {
  padding: 7px 11px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
}

.large {
  padding: 10px 14px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
}

.round {
  border-radius: 50px;
  padding: 6px 6px;
}

.spinner {
  margin-left: 5px;
}

</style>
