<template>
  <div class="ticket-create">
    <div class="headrow bg-grey global-border-bottom">
      <div class="header">
        Create New Ticket
      </div>
      <div class="close">
        <swift-close-round height="12px" width="12px" @click.native="closeCreateTicket()" v-on:keyup.esc="closeCreateTicket()" />
      </div>
    </div>
    <div class="mainpanel global-border-bottom">
      <div class="title-label global-label">
        Title *
      </div>
      <div class="global-form-error" v-if="titleErrors.length">
        <div v-for="error in titleErrors" :key="error.index">
          {{error}}
        </div>
      </div>
      <div class="title">
        <input id="ticket-input-title" class="global-input title-input" v-model="title" placeholder="Add a title" :class=" { 'global-input-invalid': titleErrors.length}" ref="title">
      </div>
      <div class="description-label global-label">
        Description
      </div>
      <div class="description">
        <textarea class="global-input-resizeable description-textarea" v-model="description" placeholder="Add a description"
                  ref="textarea"
                  @input="resizeTextarea()"/>
      </div>
      <div class="attachments-container"
           v-if="$store.state.createTicket.attachments && $store.state.createTicket.attachments.length > 0" >
        <ticket-attachments
          :attachments="$store.state.createTicket.attachments"
          :selectable="false"/>
      </div>
      <div class="global-form-error upload-error" v-if="uploadErrors.length">
        <div v-for="error in uploadErrors" :key="error.index">
          {{error}}
        </div>
      </div>
      <swift-upload @uploadStatusChanged="updateUploadStatus($event)"
                    @filesUploaded="pushCreateTicketAttachments($event)" />
      <div class="selectrow">
        <div class="assignee-container">
          <div class="global-label">
            Assign to
          </div>
          <swift-select-assignee
            :initial-assignee="$store.state.user.user"
            :set-initial-assignee="true"
            @selectedAssignee="setCreateTicketAssignee($event)"  />
        </div>
        <div class="status-container" v-if="$store.state.status.statuses.length">
          <div class="global-label">
            Status
          </div>
          <swift-select-status
            :initial-status="initialStatus"
            :set-initial-status="true"
            @selectedStatus="setCreateTicketStatus($event)" />
        </div>
      </div>
      <ticket-create-select-priority />
    </div>
    <div class="bottomrow">
      <swift-button id="save-ticket" color="blue" @click.native="createTicket()" :is-loading="createTicketIsLoading">Create ticket</swift-button>
      <swift-button link="link" @click.native="closeCreateTicket()">Cancel</swift-button>
    </div>
  </div>
</template>

<script>
import SwiftCloseRound from './SwiftCloseRound.vue'
import SwiftButton from './SwiftButton.vue'
import SwiftSelectAssignee from './SwiftSelectAssignee.vue'
import SwiftSelectStatus from './SwiftSelectStatus.vue'
import TicketCreateSelectPriority from './TicketCreateSelectPriority.vue'
import SwiftUpload from './SwiftUpload.vue'
import TicketAttachments from './TicketAttachments.vue'

export default {
  components: {
    SwiftCloseRound,
    SwiftButton,
    SwiftSelectAssignee,
    SwiftSelectStatus,
    TicketCreateSelectPriority,
    SwiftUpload,
    TicketAttachments
  },
  props: ['closeModal'],
  data: function () {
    return {
      uploadStatus: null,
      uploadErrors: [],
      titleErrors: [],
      createTicketIsLoading: false
    }
  },
  watch: {
    closeModal () {
      this.closeCreateTicket()
    }
  },
  mounted () {
    this.$refs.title.focus()
  },
  computed: {
    getUserById () {
      return this.$store.getters.getUserById
    },
    sortedStatuses () {
      return this.$store.getters.sortedStatuses
    },
    getStatusById () {
      return this.$store.getters.getStatusById
    },
    initialStatus () {
      if (this.$route.query.status) {
        return this.getStatusById(parseInt(this.$route.query.status))
      } else {
        return false
      }
    },
    title: {
      get () {
        return this.$store.state.createTicket.title
      },
      set (value) {
        this.$store.commit('updateCreateTicketTitle', value)
      }
    },
    description: {
      get () {
        return this.$store.state.createTicket.description
      },
      set (value) {
        this.$store.commit('updateCreateTicketDescription', value)
      }
    }
  },
  methods: {
    setCreateTicketStatus (status) {
      this.$store.commit('setCreateTicketStatus', status)
    },
    setCreateTicketAssignee (assignee) {
      this.$store.commit('setCreateTicketAssignee', assignee)
    },
    pushCreateTicketAttachments (attachments) {
      this.$store.commit('pushCreateTicketAttachments', attachments)
    },
    closeCreateTicket () {
      this.$store.commit('closeCreateTicket')
      this.$store.commit('hideModal')
      this.$router.push({ name: 'tickets',
        query: {
          ...this.$route.query
        }
      })
    },
    async createTicket () {
      if (!this.checkTitle()) {
        return
      }
      if (!this.checkUpload()) {
        return
      }

      this.createTicketIsLoading = true

      const response = await this.$store.dispatch('createTicket')
      const newTicketId = response.data.id

      this.createTicketIsLoading = false

      this.$store.commit('closeCreateTicket')
      this.$store.commit('hideModal')

      this.$router.push({ name: 'ticket-modal',
        query: {
          ...this.$route.query
        },
        params: {
          ticketId: newTicketId.toString()
        }
      })
    },
    checkTitle () {
      this.titleErrors = []

      if (!this.title) {
        this.titleErrors.push('Title required')
        return false
      }

      if (!this.titleErrors.length) {
        return true
      }
    },
    updateUploadStatus (status) {
      this.uploadStatus = status

      if (status === 'success') {
        this.uploadErrors = []
      }
    },
    checkUpload () {
      this.uploadErrors = []

      if (this.uploadStatus === 'saving') {
        this.uploadErrors.push('File is still uploading')
        return false
      }

      if (!this.uploadErrors.length) {
        return true
      }
    },
    resizeTextarea () {
      this.$refs.textarea.style.height = ''
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px'
    }
  }
}
</script>

<style scoped>

.ticket-create {
    position: relative;
    margin-left: 280px; /* beware: if you use auto here the modal will jump on opening, so we use a fixed margin for the max-layout of the table and when the tbale goes to small it will go back to auto */
    border-radius: 4px;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 750px;
    max-width: 90%;
    background-color: #ffffff;
}

@media (max-width: 1400px) {
    .ticket-create {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 1025px) {
    .ticket-create {
        width: 100%;
        min-height: 100%;
        border-radius: 0px;
        max-width: 100%;
        margin: 0px;
    }
}

.headrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 50px;
  background-color: #f9fafb;
}

.header {
  font-size: 17px;
  font-weight: 600;
  margin-left: 30px;
}
@media (max-width: 620px) {
    .header {
        margin-left: 15px;
    }
}

.title-input {
  width:100%;
}

.close {
  margin-right: 15px;
}
@media (max-width: 620px) {
    .close {
        margin-left: 5px;
    }
}

.mainpanel {
  padding-top: 25px;
  padding-bottom: 15px;
  padding-left: 45px;
  padding-right: 45px;
}
@media (max-width: 620px) {
    .mainpanel {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.description-label {
  margin-top: 20px;
}

.description {
  display: flex;
}

.description-textarea {
  width: 100%;
  height: 100px;
}

.attachments-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.upload-error {
  margin-top: 10px;
}

.selectrow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}

.assignee-container {
  width: 50%;
  padding-right: 10px;
}

.status-container {
  width: 50%;
  padding-left: 10px;
}

.bottomrow {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 45px;
  padding-left: 45px;
}

</style>
