<template>
  <div class="search">
    <div class="search-bar">
      <input
        type="text"
        placeholder="Search.."
        v-model="searchQuery"
        @input="bufferedSearch()"
        @keyup.enter="bufferedSearch()"
        class="global-input search-input-field"
        :class="{'active-search': searchQuery}">
      <button v-if="searchQuery || $route.query.search"
              class="search-button" @click="cancelSearch()">
        <icon-close
          width="14px"
          height="14px"
          fill="#1764d9"
        />
      </button>
      <button v-else
              class="search-button">
        <icon-search
          width="14px"
          height="14px"
          fill="#60718a"
        />
      </button>
    </div>
    <div class="results-for" v-if="$route.query.search && !$store.state.userIsMobile" >
      {{ $store.state.tickets.totalTickets }} Results for {{ $route.query.search }}
    </div>
  </div>
</template>

<script>
import IconSearch from './icons/IconSearch.vue'
import IconClose from './icons/IconClose.vue'

export default {
  components: {
    IconSearch,
    IconClose
  },
  data: function () {
    return {
      searchQuery: '',
      searchTimestamp: null
    }
  },
  created () {
    if (this.$route.query.search) {
      this.searchQuery = this.$route.query.search
    }
  },
  methods: {
    search () {
      if (this.$route.query.search === this.searchQuery) return

      this.searchTimestamp = Date.now()
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          search: this.searchQuery
        } })
    },
    cancelSearch () {
      this.searchQuery = ''

      if (!this.$route.query.search) return
      let query = Object.assign({}, this.$route.query)
      delete query.search
      query.page = 1
      this.$router.push({ query })
    },
    bufferedSearch () {
      let searchBufferInMs = 500

      // run search every 0.5s when user is typing
      if ((Date.now() - this.searchTimestamp) > searchBufferInMs) {
        this.search()
      } else {
        // this ensures we will run a search when user stops typing
        setTimeout(() => {
          if ((Date.now() - this.searchTimestamp) > searchBufferInMs) {
            this.search()
          }
        }, searchBufferInMs)
      }
    }
  }
}
</script>

<style scoped>

.search {
  display: flex;
  align-items: center;
}

.search-bar {
    position: relative;
    width: 280px;
}
@media (max-width: 1025px) {
    .search-bar {
        max-width: 280px;
        margin-right: 10px;
    }
}
.search-input-field {
    width: 100%;
    padding-left: 15px;
    padding-right: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 50px;
    border: 1px solid #c8cfda;
}

.active-search {
    border: 1px solid #1764d9;
    transition-duration: 0.3s;
}

.search-button {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 10px;
    padding-top: 8px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.results-for {
  padding-left: 10px;
  font-size: 13px;
}
</style>
