<template>
  <div class="global-panel team">
    <div class="title-row">
      <icon-team class="title-icon" height="20px" width="25px" fill="#347be5"/>
      <h1>
        Team Members
      </h1>
    </div>
    <div class="description">
      Add or delete team members. Assign roles to control permissions for each team member.
    </div>
    <div class="invite-box">
      <h2 class="subtitle">
        Invite New Members
      </h2>
      <div>
        Send email invitations to add new users to your team.
      </div>
      <div class="invite">
        <div class="invite-email">
          <label class="global-label">Email</label>
          <div class="global-form-error" v-if="emailErrors.length">
            <div v-for="error in emailErrors" :key="error.index">
              {{error}}
            </div>
          </div>
          <input
            class="global-input email-input"
            type="email"
            name="email"
            placeholder="name@company.com"
            novalidate
            spellcheck="false"
            autocapitalize="off"
            v-model="email"
          >
        </div>

        <div>
          <swift-button
            color="blue"
            type="submit"
            @click.native="inviteUser()"
            :is-loading="inviteButtonIsLoading"
          >
            Invite member
          </swift-button>
        </div>
      </div>
    </div>
    <div v-if="$store.state.invites.invitesAreLoaded">
      <h2 class="subtitle">
        Pending Invitations ({{ $store.state.invites.invites.length}})
      </h2>
      <div v-if="$store.state.invites.invites.length === 0">
        No pending invitations.
      </div>
      <div class="global-table" v-if="$store.state.invites.invites.length > 0">
        <div class="global-table-head-row">
          <div class="global-table-column">
            Email
          </div>
          <div class="global-table-column">
            Invited by
          </div>
          <div class="global-table-column">
            Send date
          </div>
          <div class="global-table-column">
            Actions
          </div>
        </div>
        <div class="global-table-row" v-for="invite in $store.state.invites.invites" :key="invite.id">
          <div class="global-table-column invite-email-column">
            {{ invite.email}}
          </div>
          <div class="global-table-column">
            <div class="inviter-name-text">{{ getUserById(invite.inviterId).name}}</div>
          </div>
          <div class="global-table-column">
            {{ formatDistanceToNow(invite.createdAt) }}
          </div>
          <div class="global-table-column">
            <swift-button
              size="small"
              color="light"
              type="submit"
              @click.native="deleteInviteById(invite.id)"
            >
              Revoke invitation
            </swift-button>
          </div>
        </div>
      </div>
    </div>

    <h2 class="team-member-subtitle">
      Team Members ({{activeUsers.length}})
    </h2>

    <div class="global-table">
      <div class="global-table-head-row">
        <div class="global-table-column">
          Member
        </div>
        <div class="global-table-column">
          Email
        </div>
        <div class="global-table-column">
          Role
        </div>

        <!-- ONLY OWNERS -->
        <div class="global-table-column" v-if="$store.state.user.user.role === 'owner'">
          Actions
        </div>

      </div>
      <div class="global-table-row" v-for="user in activeUsers" :key="user.id">
        <div class="global-table-column">
          <swift-user-avatar :user="user" :size="24"/>
          <div class="member-name-text">{{ user.name}}</div>
        </div>
        <div class="global-table-column">
          {{ user.email}}
        </div>
        <div class="global-table-column">
          {{ capitalizeFirst(user.role) }}
        </div>
        <!-- ONLY OWNERS -->
        <div class="global-table-column"
             v-if="$store.state.user.user.role === 'owner' ||
               $store.state.user.user.role === 'super-admin' &&
               user.id != $store.state.user.user.id">
          <swift-button
            size="small"
            color="light"
            type="submit"
            @click.native="deleteUserById(user.id)"
          >
            Delete
          </swift-button>
        </div>
        <!-- INACTIV EBUTTON TO AVOID LAYOUT SHIFTS -->
        <div class="global-table-column"
             v-if="$store.state.user.user.role === 'owner' ||
               $store.state.user.user.role === 'super-admin' &&
               user.id === $store.state.user.user.id">
          <swift-button
            size="small"
            color="light-inactive"
            type="submit"
          >
            Delete
          </swift-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import InvitesApi from '../api/InvitesApi.js'
import UsersApi from '../api/UsersApi.js'
import { formatDistanceToNow, parseISO } from 'date-fns'
import SwiftButton from './SwiftButton.vue'
import SwiftUserAvatar from './SwiftUserAvatar.vue'
import IconTeam from './icons/IconTeam.vue'

export default {
  components: {
    SwiftButton,
    SwiftUserAvatar,
    IconTeam
  },
  data () {
    return {
      email: '',
      inviteButtonIsLoading: false,
      emailErrors: []
    }
  },
  created () {
    this.$store.dispatch('getInvites')
  },
  computed: {
    getUserById () {
      return this.$store.getters.getUserById
    },
    activeUsers () {
      return this.$store.getters.activeUsers
    }

  },
  methods: {
    async inviteUser () {
      if (!this.checkEmail()) {
        return
      }

      this.inviteButtonIsLoading = true

      try {
        await InvitesApi.createInvite({
          email: this.email
        })
      } catch (error) {
        this.inviteButtonIsLoading = false
      }
      // cleare input fields
      this.email = ''
      this.inviteButtonIsLoading = false
      // reaload invites
      this.$store.dispatch('getInvites')
    },
    async deleteInviteById (id) {
      await InvitesApi.deleteInviteById(id)
      this.$store.dispatch('getInvites')
    },
    async deleteUserById (id) {
      await UsersApi.deleteUserById(id)
      this.$store.dispatch('getOrganizationUsersProjectsUser')
    },
    checkEmail () {
      this.emailErrors = []

      if (!this.email) {
        this.emailErrors.push('Email required')
        return false
      }

      var validEmail = (email) => {
        var regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        return regex.test(email)
      }

      if (!validEmail(this.email)) {
        this.emailErrors.push('Please enter a valid email adress')
        return false
      }

      if (!this.emailErrors.length) {
        return true
      }
    },
    formatDistanceToNow (date) {
      return formatDistanceToNow(parseISO(date)) + ' ago'
    },
    capitalizeFirst (string) {
      return string[0].toUpperCase() + string.substring(1)
    }
  }
}
</script>

<style scoped>
.title-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title-icon {
  margin-right: 5px;
}

.subtitle {
  margin-bottom: 20px;
}

.team-member-subtitle {
  margin-top: 40px;
  margin-bottom: 20px;

}

.description {
  max-width: 800px;
  margin-bottom: 40px;
}

.invite-box {
  background-color: #f9fafb;
  max-width: 600px;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #e0e4eb;
  margin-bottom: 50px;
}

.invite {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 800px;
  align-items: flex-end;
}

.invite-email {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.email-input {
  width: 300px;
}

.invite-email-column {
  font-weight: 600;
}
.inviter-name-text {
  margin-left: 4px;
}

.member-name-text {
  margin-left: 4px;
  font-weight: 600;
}

</style>
