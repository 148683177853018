<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 1792 1792"
       xmlns="http://www.w3.org/2000/svg">
    <path :fill="fill" d="M960,416l416,416l-832,832H128v-416L960,416z M1643,512c0,35.3-12.3,65.3-37,90l-166,166l-416-416l166-165 c24-25.3,54-38,90-38c35.3,0,65.7,12.7,91,38l235,234C1630.7,447,1643,477.3,1643,512z"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: '#4b586c'
    }
  }
}
</script>

<style scoped>
</style>
