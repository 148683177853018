import axios from 'axios'
import store from '@/store/store'

export default {
  getOrganization () {
    return axios.get('/api/organization', {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  getOrganizationUsersProjectsUser () {
    return axios.get('/api/organization-users-projects-user', {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  }

}
