<template>
  <!-- HOW THE MODAL WORKSs
   1. the modal is placed right after the app div via vue-portal
   2. if it's dekstop size (taller than 1025 px) there will be a overlay shadow and the modal is placed in front of it
   3. if it's mobile size there will be no overlay. Instead the whole rest of the app won't be displayed anymore and the modal will take over fullscreen. This is necessary to avoid scrolling issues on mobile.

   HOW TO USE THE MODAL

   Example:
    <swift-modal v-if="$store.state.createTicket.showCreateTicket && $store.state.showModal">
            <template v-slot="{closeModal}">
              <create-ticket :close-modal="closeModal" />
            </template>
      </swift-modal>

  1. place you component inside the swift-modal
  2. trigger it via v-if $store.state.showModal
  3. use v-slot to handle the closeModal value that sends the close information when somebody clicked on the overlay shadow
  4. save the current scrollPosition before the Modal opens, this is necessary because on mobile it will otherwise jump to the top after closing. The resete of the scroll positon after close is already included in the modal:

  Example:
  let getScrollY = function () {
          if (window.pageYOffset !== undefined) {
            return pageYOffset
          } else {
            var scrollY = document.documentElement.scrollTop || document.body.scrollTop || 0
            return scrollY
          }
        }
        let scrollPositionY = getScrollY()

        this.$store.commit('saveScrollPositionBeforeModal', scrollPositionY)

  5. to close the modal use this.$store.commit('hideModal') also you need to handle the closeModal prop inside your component via a prop ['closeModal'] and a watcher on it

    Example:
    watch: {
    closeModal () {
      this.closeCreateTicket()
    }
  },
   -->
  <portal selector="#portal-target">
    <div :class="{ 'global-overlay-shadow': !this.$store.state.userIsMobile }"
         @mousedown.self="mousedown($event)"
         @mouseup.self="mouseup()">

      <slot :close-modal="closeModal">Modal content goes here</slot>

    </div>
  </portal>
</template>

<script>
import { Portal } from '@linusborg/vue-simple-portal'

export default {
  components: {
    Portal
  },
  data () {
    return {
      closeModal: false,
      mousedownWasOnShadow: false
    }
  },
  computed: {
    userIsMobile () {
      return this.$store.state.userIsMobile
    }
  },
  mounted () {
    window.scroll(0, 0)

    if (this.userIsMobile) {
      document.body.className = 'white-background'
      document.body.className = document.body.className.replace('no-scrollbar', '')
    } else {
      document.body.className = 'no-scrollbar'
    }
  },
  beforeDestroy () {
    document.body.className = document.body.className.replace('no-scrollbar', '')
    document.body.className = document.body.className.replace('white-background', '')
    // reset scroll to initital position
    this.$nextTick(function () {
      window.scroll(0, this.$store.state.scrollPositionBeforeModal)
    })
  },
  watch: {
    userIsMobile: function () {
      if (this.userIsMobile) {
        document.body.className = 'white-background'
        document.body.className = document.body.className.replace('no-scrollbar', '')
      } else {
        document.body.className = 'no-scrollbar'
      }
    }
  },
  methods: {
    mousedown (event) {
      // ensure click was not on scrollbar
      if (event.offsetX > event.target.clientWidth) {
        return
      }

      this.mousedownWasOnShadow = true
    },
    mouseup () {
      if (this.mousedownWasOnShadow) {
        this.closeModal = true
      } else {
        this.mousedownWasOnShadow = false
      }
    }
  }
}
</script>

<style scoped>

</style>
