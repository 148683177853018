const state = {
  notifications: []
}

const mutations = {
  saveNotification: (state, notification) => {
    state.notifications.push(notification)
  },
  deleteNotificationById: (state, notificationId) => {
    let index = state.notifications.map(element => {
      return element.id
    }).indexOf(notificationId)

    state.notifications.splice(index, 1)
  }
}

const actions = {

  async createNotification ({ commit, state }, notification) {
    // stop notifcation spam e.g. for reapepring disconnect errors
    if (state.notifications.length > 5) {
      return
    }

    // create uui
    function createUniqueId () {
      var dt = new Date().getTime()
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
      return uuid
    }
    const notificationId = createUniqueId()

    const notificationCreatedAt = new Date()

    // create notifcation
    commit('saveNotification', {
      id: notificationId,
      type: notification.type,
      statusCode: notification.statusCode,
      statusText: notification.statusText,
      text: notification.text,
      createdAt: notificationCreatedAt
    })

    // wait and then delete
    function sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
    async function deleteNotificationAfterTimeout () {
      await sleep(7000)
      commit('deleteNotificationById', notificationId)
    }
    deleteNotificationAfterTimeout()
  }
}

export default {
  state,
  mutations,
  actions
}
