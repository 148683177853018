import Vue from 'vue'
import App from './App.vue'
import store from './store/store.js'
import router from './router'
import './assets/global.css'
import apiErrorHandler from './api/ApiErrorHandler.js'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import axios from 'axios'

apiErrorHandler()
axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.config.productionTip = false

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.event = function (event) {
      // In order to not trigger the outside click when the element e.g. the dropdown) is opened make sure to add a .stop event modifier to the drodpown toggle in order to prevent event propagation. E.g. @click.stop="toggleDropdown()"
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, calls method provided as a value between the quotations where the directive is called
        vnode.context[binding.expression](event)
      }
    }
    document.addEventListener('click', el.event)
  },
  unbind: function (el) {
    document.removeEventListener('click', el.event)
  }
})

var vm = new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
// only use sentry in production

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://55fa71b976d94cba9a3d1042eca83d81@sentry.io/1781605',
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })]
  })
}

// embed staging widget in staging dashboard
if (process.env.VUE_APP_MODE === 'staging') {
  (function (k, l, w, d) {
    w.swiftbug = w.swiftbug || []
    w.swiftbug.push({ widgetKey: k })
    var s = d.createElement('script')
    s.id = k; s.async = 1; s.src = l
    var parentNode = d.head || d.body; parentNode.appendChild(s)
  })('a9619ec7-a01a-4896-8ec9-6168d70d87e9', 'https://widget-staging.swiftbug.com/core/js/app.js', window, document)
}

// embedding the local dev widget on the developemtn dashbaord does not working becausecss will not be seperated for same name componennts (this is only a local dev problem)
if (process.env.VUE_APP_MODE === 'development') {
  (function (k, l, w, d) {
    w.swiftbug = w.swiftbug || []
    w.swiftbug.push({ widgetKey: k })
    var s = d.createElement('script')
    s.id = k; s.async = 1; s.src = l
    var parentNode = d.head || d.body; parentNode.appendChild(s)
  })('5285a1c3-c345-4a2a-b486-829b432c45aa', 'http://localhost:8081/js/app.js', window, document)
}
