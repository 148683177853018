<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg">
    <path :fill="fill" d="M102,0C73.95,0,51,22.95,51,51v408c0,28.05,22.95,51,51,51h306c28.05,0,51-22.95,51-51V153L306,0H102z M280.5,178.5V38.25 L420.75,178.5H280.5z"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: '#4b586c'
    }
  }
}
</script>

<style scoped>
</style>
