<template>
  <div class="comment">
    <div class="comment-author">
      <swift-user-avatar
        :user="getUserById(comment.authorId)"
        :size="26" />
    </div>
    <div class="comment-content">
      <div class="comment-label">
        <span class="comment-label-author">{{ getUserById(comment.authorId).name }}</span> commented {{ formatDistanceToNow(comment.createdAt) }}
      </div>
      <div class="comment-text" v-if="!editCommentMode" :class="{'editable-outline': editCommentOutlineIsActive}" @mouseover="showEditCommentOutline(comment.authorId)" @mouseout="hideEditCommentOutline()" @click="activateEditCommentMode(comment)">{{ comment.text }}
      </div>

      <textarea
        v-if="editCommentMode"
        class="global-input-resizeable comment-edit"
        type="text"
        v-model="editableComment"
        autocomplete="off"
        ref="editcomment"
        @input="resizeEditComment()"
        @blur="updateComment()"
      />

    </div>
  </div>
</template>

<script>
import CommentsApi from '../api/CommentsApi.js'
import SwiftUserAvatar from './SwiftUserAvatar.vue'
import { formatDistanceToNow, parseISO } from 'date-fns'

export default {
  components: {
    SwiftUserAvatar
  },
  props: ['comment', 'ticketId'],
  data: function () {
    return {
      editCommentOutlineIsActive: false,
      editCommentMode: false,
      editableComment: ''
    }
  },
  computed: {
    getUserById () {
      return this.$store.getters.getUserById
    }
  },
  methods: {

    showEditCommentOutline (authorId) {
      if (authorId === this.$store.state.user.user.id) {
        this.editCommentOutlineIsActive = true
      }
    },
    hideEditCommentOutline () {
      this.editCommentOutlineIsActive = false
    },
    activateEditCommentMode (comment) {
      if (comment.authorId === this.$store.state.user.user.id) {
        this.editCommentOutlineIsActive = false
        this.editableComment = comment.text
        this.editCommentMode = true
        this.$nextTick(() => {
          this.$refs.editcomment.focus()
          this.resizeEditComment()
        })
      }
    },
    resizeEditComment () {
      this.$refs.editcomment.style.height = ''
      this.$refs.editcomment.style.height = this.$refs.editcomment.scrollHeight + 'px'
    },
    async updateComment () {
      // do nothing if nothing changed
      if (this.editableComment === this.comment.text) {
        this.editCommentMode = false
        return
      }
      // empty check
      if (this.editableComment === '') {
        this.editCommentMode = false
        return
      }

      const comment = {
        id: this.comment.id,
        text: this.editableComment
      }

      await CommentsApi.updateComment(comment)
      await this.$store.dispatch('updateTicketById', this.ticketId)
      this.editCommentMode = false
    },
    formatDistanceToNow (date) {
      return formatDistanceToNow(parseISO(date)) + ' ago'
    }
  }
}
</script>

<style scoped>

.comment {
  display: flex;
  margin-bottom: 15px;
}
.comment-content {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.comment-text {
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 15px;
  padding-top: 4px;
  padding-bottom: 6px;
  padding-left: 5px;
}

.comment-author {
  margin-right: 0px;
}

.comment-label {
  color: #9ba8bb;
  padding-left: 5px;
  padding-bottom: 2px;
  line-height: 1;
}

.comment-label-author {
  color: #4b586c;
  font-weight: 600;
}
.editable-outline {
  box-shadow: 0 0 0 1px #d9dfe7;
  border-radius: 4px;
  cursor: pointer;
}

.comment-edit-container {
  display: block;
  width: 100%;
}

.comment-edit {
  width: 100%;
  padding-left: 4px;
  padding-top: 6px;
  padding-right: 5px;
  padding-bottom: 4px;
  font-size: 15px;
  white-space: pre-wrap;
}

</style>
