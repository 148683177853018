<template>
  <div
    class="ticket"
    tabindex="0">
    <div class="ticket-left">
      <div class="ticket-title">
        {{ ticket.title }}
      </div>
      <div class="ticket-subtitle">
        #{{ ticket.number }} &#8226; by {{ getUserById(ticket.reporterId).name }} &#8226; created {{ formatDistanceToNow(ticket.createdAt) }} &#8226; updated {{ formatDistanceToNow(ticket.updatedAt) }}
      </div>
    </div>
    <div class="ticket-assignee">
      <div class="ticket-assignee-available" v-if="ticket.assigneeId">
        <swift-user-avatar v-if="$store.state.userIsMobile" :user="getUserById(ticket.assigneeId)" :size="22" />
        <div class="ticket-assigne-name" v-else>
          {{ getUserById(ticket.assigneeId).name }}
        </div>
      </div>
    </div>
    <div class="ticket-prio">
      <swift-priority-tag
        v-if="ticket.priorityValue"
        :priority="getPriorityByValue(ticket.priorityValue)"/>
    </div>
    <div class="ticket-thumb">
      <div
        class="ticket-thumb-image"
        v-if="ticket.attachments.length"
        :style="{ backgroundImage: toCloudImageBackgroundUrl(ticket.attachments[0].url) }" />
    </div>
    <div class="ticket-comment">
      <template v-if="ticket.commentsCount ">
        <icon-comment
          width="14px"
          height="14px"
          fill="#4b586c" />
        <div class="ticket-comment-text">{{ ticket.commentsCount }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import { formatDistanceToNow, parseISO } from 'date-fns'

import SwiftPriorityTag from './SwiftPriorityTag.vue'
import SwiftUserAvatar from './SwiftUserAvatar.vue'
import IconComment from './icons/IconComment.vue'

export default {
  components: {
    SwiftPriorityTag,
    IconComment,
    SwiftUserAvatar
  },
  props: ['ticket'],
  computed: {
    getUserById () {
      return this.$store.getters.getUserById
    },
    getPriorityByValue () {
      return this.$store.getters.getPriorityByValue
    }
  },
  methods: {
    formatDistanceToNow (date) {
      return formatDistanceToNow(parseISO(date)) + ' ago'
    },
    toCloudImageBackgroundUrl (origUrl) {
      const aliasUrl = origUrl.replace('https://swiftbug-useruploads.s3.amazonaws.com', '_swiftbug_')
      const cloudImageTokenUrl = 'https://cnvcbcfiua.cloudimg.io/'
      const sizeParameters = 'width=90&height=44&sharp=1&func=bound'
      const url = `url(${cloudImageTokenUrl}${aliasUrl}?${sizeParameters})`
      return url
    }
  }
}
</script>

<style scoped>
.ticket {
    display: flex;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #FFFFFF;
    margin-bottom: 4px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 4px;
    box-shadow: 0 6px 8px hsla(216, 18%, 19%,0.03), 0 1px 2px hsla(216, 18%, 19%, 0.2);
}
@media (max-width: 600px) {
    .ticket {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.ticket:hover,
.ticket:focus{
    background-color: #f5f9ff;
    transition-duration: 0.1s;
    cursor: pointer;
}

.ticket-left {
    flex-basis: 100px;
    flex-grow: 4;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    line-height: 1.3;
    padding-top: 5px;
    padding-bottom: 5px;
}
.ticket-title {
    align-self: flex-start;
    line-height: 1.1;
    font-weight: 600;
    font-size: 16px;
    color: #4b586c;
    padding-bottom: 2px;
}
.ticket-subtitle {
    align-self: flex-start;
    font-size: 13px;
    color: #8290a6;
    white-space: nowrap;
}
@media (max-width: 00px) {
    .ticket-subtitle {
        display: none;
    }
}
.ticket-assignee {
    flex-basis: 100px;
    flex-grow: 0.7;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
@media (max-width: 900px) {
    .ticket-assignee {
        flex-basis: 50px;
    }
}

.ticket-assignee-available {
    flex-grow: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ticket-assignee-name {
  word-break: break-all;
}

.ticket-assignee-avatar {
    width: 25px;
    border-radius: 50%;
    margin-right: 5px;
}
.ticket-prio {
    flex-basis: 70px;
    flex-grow: 0.2;
    flex-shrink: 0;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
@media (max-width: 600px) {
    .ticket-prio {
        display: none;
    }
}

.ticket-thumb {
    margin-left: 10px;
    flex-basis: 100px;
    flex-grow: 0.1;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
}
@media (max-width: 700px) {
    .ticket-thumb {
        display: none;
    }
}
.ticket-thumb-image {
    display: flex;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 92px;
    height: 46px;
    border-radius: 4px;
    border: 1px solid #e0e4eb;
}

.ticket-comment {
    flex-basis: 50px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
@media (max-width: 800px) {
    .ticket-comment {
        display: none;
    }
}
.ticket-comment-text {
    padding-left: 3px;
}

.icon-comment {
    height: 14px;
    padding-right: 4px;
}

</style>
