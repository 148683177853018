import axios from 'axios'
import store from '@/store/store'

export default {
  createWidget (widget) {
    return axios.post('/api/widgets', widget, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  updateWidget (widget) {
    return axios.put(`/api/widgets/${widget.id}`, widget, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  getWidgetById (widgetId) {
    return axios.get(`/api/widgets/${widgetId}`, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  getWidgetsOfProject (projectId) {
    return axios.get(`/api/${projectId}/widgets`, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  }
}
