<template>
  <button
    class="select-priority-option"
    :style="{backgroundColor: buttonColor}"
    @mouseenter="buttonIsHovered = true"
    @mouseleave="buttonIsHovered = false"
    @click="toggleButton()">
    <div class="select-priority-option-checkmark">
      <icon-checkmark
        width="14px"
        height="14px"
        fill="#FFF"
        v-if="buttonIsSelected" />
    </div>
    <div class="select-priority-option-text">
      {{ priority.name }}
    </div>
  </button>
</template>

<script>
import IconCheckmark from './icons/IconCheckmark.vue'

export default {
  components: {
    IconCheckmark
  },
  props: ['priority', 'selectedPriority'],
  data: function () {
    return {
      buttonIsSelected: false,
      buttonIsHovered: false
    }
  },
  watch: {
    selectedPriority: function () {
      if (this.selectedPriority !== this.priority.value) {
        this.buttonIsSelected = false
      }
    }
  },
  computed: {
    buttonColor () {
      if (this.buttonIsHovered) {
        return this.priority.color_hover
        // checks if selected priority exists and if it's the same to this id this is not a duplication!
      } else if (this.selectedPriority && this.selectedPriority !== this.priority.value) {
        return this.priority.color_fade
      } else if (this.buttonIsSelected) {
        return this.priority.color
      } else {
        return this.priority.color
      }
    }
  },
  methods: {
    toggleButton () {
      this.buttonIsSelected = !this.buttonIsSelected
      if (this.buttonIsSelected) {
        this.$emit('selectedprio', this.priority)
      } else {
        this.$emit('selectedprio', false)
      }
    }
  }
}
</script>

<style scoped>
.select-priority {
  margin-top: 20px;
  width: 100%;
}

.select-priority-select {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.select-priority-option {
  border: none;
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  color: #FFFFFF;
  background-color: grey;
  font-weight: 600;
  transition-duration: 0.3s;
}

.select-priority-option:focus {
  box-shadow: 0 0 0 2px #e0e4eb;
  /* ensures shadows overlays surroundign element */
  z-index: 1;
  transition-duration: 0.3s;
}

.select-priority-option-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 14px;
  padding-left: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1;
}

.select-priority-option-checkmark {
  display: flex;
}
</style>
