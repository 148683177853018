import StatusesApi from '../../api/StatusesApi.js'

const state = {
  statuses: [],
  statusesAreLoaded: false

}

const getters = {
  sortedStatuses: state => {
    // assign statuses to new var so state content stays the same (unsorted) as database
    const sortedStatuses = [...state.statuses].sort(function (a, b) {
      if (a.order > b.order) {
        return 1
      }
      if (a.order < b.order) {
        return -1
      }
      return 0
    })
    return sortedStatuses
  },
  getStatusById: (state) => (statusId) => {
    return state.statuses.find(element => element.id === statusId)
  }
}

const mutations = {
  saveStatuses: (state, statuses) => {
    state.statuses = statuses
    state.statusesAreLoaded = true
  },
  resetStatusesToNull: (state) => {
    state.statuses = []
  },
  updateOrderOfStatus: (state, { status, newOrderValue }) => {
    status.order = newOrderValue
  },
  setStatusesAreLoaded: (state, boolean) => {
    state.statusesAreLoaded = boolean
  }
}

const actions = {
  async createStatus ({ commit, dispatch }, status) {
    await StatusesApi.createStatus(status)
    dispatch('getStatusesOfProject', status.projectId)
  },
  async getStatusesOfProject ({ commit, dispatch, state }, projectId) {
    commit('setStatusesAreLoaded', false)
    const statuses = (await StatusesApi.getStatusesOfProject(projectId)).data
    commit('saveStatuses', statuses)
  },
  async updateStatus ({ commit, dispatch, state, rootState }, updatedStatus) {
    await StatusesApi.updateStatus(updatedStatus)
    dispatch('getStatusesOfProject', rootState.projects.currentProjectId)
  },
  async deleteStatusById ({ commit, dispatch, state, rootState }, statusId) {
    await StatusesApi.deleteStatusById(statusId)
    dispatch('getStatusesOfProject', rootState.projects.currentProjectId)
  },
  async moveOrderOfStatusesToRight ({ dispatch, state, rootState }, { orderOfDroppedOnStatus, orderOfDraggedStatus, droppedStatus }) {
    await state.statuses.forEach(function (element, index) {
      if (element.order >= orderOfDroppedOnStatus && element.order <= orderOfDraggedStatus) {
        const editedStatus = JSON.parse(JSON.stringify(element))
        editedStatus.order += 1
        StatusesApi.updateStatus(editedStatus)
      }
    })
    const editedDroppedStatus = JSON.parse(JSON.stringify(droppedStatus))
    editedDroppedStatus.order = orderOfDroppedOnStatus
    await StatusesApi.updateStatus(editedDroppedStatus)
    dispatch('getStatusesOfProject', rootState.projects.currentProjectId)
  },
  async moveOrderOfStatusesToLeft ({ dispatch, state, rootState }, { orderOfDroppedOnStatus, orderOfDraggedStatus, droppedStatus }) {
    await state.statuses.forEach(function (element, index) {
      if (element.order <= orderOfDroppedOnStatus && element.order >= orderOfDraggedStatus) {
        const editedStatus = JSON.parse(JSON.stringify(element))
        editedStatus.order -= 1

        StatusesApi.updateStatus(editedStatus)
      }
    })
    const editedDroppedStatus = JSON.parse(JSON.stringify(droppedStatus))
    editedDroppedStatus.order = orderOfDroppedOnStatus
    await StatusesApi.updateStatus(editedDroppedStatus)
    dispatch('getStatusesOfProject', rootState.projects.currentProjectId)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
