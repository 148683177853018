<template>
  <div class="select-assignee">
    <swift-dropdown>
      <template slot="dropdown-button">
        <div class="selected-value" v-if="!selectedAssignee">
          Select person
        </div>
        <div
          v-else
          class="selected-value">
          <div class="item">
            <div class="avatar-container">
              <swift-user-avatar
                :user="selectedAssignee"
                :size="16" />
            </div>
            <div class="item-name">
              {{ selectedAssignee.name }}
            </div>
          </div>

        </div>

        <icon-sort-down
          width="14px"
          height="14px"
          fill="#4b586c" />
      </template>
      <swift-dropdown-item
        @selected="clearSelect()"
        slot="dropdown-items">
        <div class="clear-select">No assignee</div>
      </swift-dropdown-item>
      <swift-dropdown-item
        v-for="user in activeUsers"
        :key="user.id"
        :value="user"
        @selected="selectAssignee(user)"
        slot="dropdown-items">
        <div class="avatar-container">
          <swift-user-avatar
            :user="user"
            :size="16" />
        </div>
        {{ user.name }}
      </swift-dropdown-item>
    </swift-dropdown>
  </div>
</template>

<script>
import SwiftDropdown from './SwiftDropdown.vue'
import IconSortDown from './icons/IconSortDown.vue'
import SwiftDropdownItem from './SwiftDropdownItem.vue'
import SwiftUserAvatar from './SwiftUserAvatar.vue'

export default {
  components: {
    SwiftDropdown,
    IconSortDown,
    SwiftDropdownItem,
    SwiftUserAvatar
  },
  props: ['initialAssignee', 'setInitialAssignee'],
  data () {
    return {
      selectedAssignee: this.initialAssignee
    }
  },
  mounted () {
    if (this.setInitialAssignee) {
      this.selectAssignee(this.initialAssignee)
    }
  },
  computed: {
    getUserById () {
      return this.$store.getters.getUserById
    },
    activeUsers () {
      return this.$store.getters.activeUsers
    }
  },
  methods: {
    selectAssignee (user) {
      this.selectedAssignee = user
      this.$emit('selectedAssignee', user)
    },
    clearSelect () {
      this.selectedAssignee = null
      this.$emit('selectedAssignee', null)
    }
  }
}
</script>

<style scoped>
.select-assignee {
  width: 100%;
}

.selected-value {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.item {
  display: flex;
  align-items: center;
}

.avatar-container {
  margin-right: 5px;
}

</style>
