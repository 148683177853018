import axios from 'axios'
import store from '@/store/store'

export default {
  getProjects () {
    return axios.get(`/api/projects`, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  createProject (project) {
    return axios.post(`/api/projects`, project, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  updateProject (project) {
    return axios.put(`/api/projects/${project.id}`, project, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  deleteProjectById (projectId) {
    return axios.delete(`/api/projects/${projectId}`, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  }
}
