import axios from 'axios'
import store from '@/store/store'

export default {
  getS3UploadInfo (file) {
    return axios.get(`/api/upload/sign-s3?filetype=${file.type}`, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  uploadFile (file, signedS3Url) {
    return axios.put(signedS3Url, file, {
      headers: {
        'Content-Type': file.type
      }
    })
  }
}
