<template>
  <div class="select-project">
    <swift-dropdown>
      <template slot="dropdown-button">
        <div class="selected-value" v-if="!selectedProject">Select project</div>
        <div
          v-else
          class="selected-value">
          <div class="select-project-item">
            <div class="select-project-item-name">
              {{ selectedProject.name }}
            </div>
          </div>
        </div>
        <icon-sort-down
          width="14px"
          height="14px"
          fill="#4b586c" />
      </template>
      <swift-dropdown-item
        v-for="project in $store.state.projects.projects"
        :key="project.id"
        :value="project"
        @selected="selectProject"
        slot="dropdown-items">
        <div class="select-project-item">
          <div class="select-project-item-name">
            {{ project.name }}
          </div>
        </div>
      </swift-dropdown-item>
    </swift-dropdown>
  </div>
</template>

<script>
import SwiftDropdown from './SwiftDropdown.vue'
import IconSortDown from './icons/IconSortDown.vue'
import SwiftDropdownItem from './SwiftDropdownItem.vue'

export default {
  components: {
    SwiftDropdown,
    IconSortDown,
    SwiftDropdownItem
  },
  props: {
    initialProject: {
      type: Object,
      required: true
    },
    setInitialProject: {
      type: Boolean
    }
  },
  data () {
    return {
      selectedProject: this.initialProject
    }
  },
  mounted () {
    if (this.setInitialProject) {
      this.selectProject(this.initialProject)
    }
  },
  methods: {
    selectProject (project) {
      this.selectedProject = project
      this.$emit('selectedProject', project)
    }
  }
}
</script>

<style scoped>
.select-project {
  width: 100%;
}
.select-project-item {
  display: flex;
  align-items: center;
}

.selected-value {
  display: flex;
  align-items: center;
  overflow: hidden;
}

</style>
