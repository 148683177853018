<template>
  <div v-if="$store.state.projects.projectsAreLoaded">
    <navigation-sidebar/>
    <div class="mainpanel">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavigationSidebar from './NavigationSidebar.vue'

export default {
  components: {
    NavigationSidebar
  },
  created () {
    // component will not be shown before loaded because of v-if
    this.$store.dispatch('getOrganizationUsersProjectsUser')
  }
}
</script>

<style scoped>
.mainpanel {
  max-width: 1300px;
  box-sizing: border-box;
  margin-left: 210px;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 50px;
}
@media (max-width: 1650px) {
  .mainpanel {
    margin-right: 50px;
  }
}
@media (max-width: 1025px) {
  .mainpanel {
    margin-left: auto;
    margin-right: auto;
  }
}

</style>
