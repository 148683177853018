import { render, staticRenderFns } from "./TicketListSortingFilterAssignee.vue?vue&type=template&id=3cd6e2c1&scoped=true"
import script from "./TicketListSortingFilterAssignee.vue?vue&type=script&lang=js"
export * from "./TicketListSortingFilterAssignee.vue?vue&type=script&lang=js"
import style0 from "./TicketListSortingFilterAssignee.vue?vue&type=style&index=0&id=3cd6e2c1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cd6e2c1",
  null
  
)

export default component.exports