<template>
  <div class="login-positioner">
    <div class="logo">
      <icon-logo-full width="100px" height="69px" fill="#1764d9" />
    </div>
    <div class="login">
      <form class="login-form"
            id="login-form"
            novalidate
            @submit.prevent="resetPassword()" >
        <div class="login-title">Reset Password</div>
        <div class="description">Please enter your new password</div>
        <label for="password" class="global-label password-label">New Password</label>
        <div class="global-form-error" v-if="passwordErrors.length">
          <div v-for="error in passwordErrors" :key="error.index">
            {{error}}
          </div>
        </div>
        <input
          class="global-input"
          type="password"
          name="password"
          id="password"
          novalidate
          spellcheck="false"
          autocapitalize="off"
          placeholder="Minimum 8 characters"
          v-model="password"
          :class=" { 'global-input-invalid': passwordErrors.length}"
        >
        <div class="global-form-error" v-if="tokenErrors.length">
          <div v-for="error in tokenErrors" :key="error.index">
            {{error}}
          </div>
        </div>
        <swift-button
          class="login-button"
          color="blue"
          shadow="shadow"
          size="large"
          type="submit"
          :is-loading="resetPasswordIsLoading"
        >
          Change password
        </swift-button>
      </form>
      <div class="register-remark global-border-top">
        <router-link
          to="/login"
          class="global-link">Back to login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SwiftButton from './SwiftButton.vue'
import IconLogoFull from './icons/IconLogoFull.vue'

export default {
  components: {
    SwiftButton,
    IconLogoFull
  },
  data () {
    return {
      password: '',
      passwordErrors: [],
      tokenErrors: [],
      resetPasswordIsLoading: false,
      token: '',
      tokenIsMissing: false
    }
  },
  created () {
    if (this.$route.params.passwordResetToken) {
      this.token = this.$route.params.passwordResetToken
    }
  },
  methods: {
    async resetPassword () {
      if (!this.checkPassword()) {
        return
      }
      if (!this.checkToken()) {
        return
      }

      this.resetPasswordIsLoading = true
      try {
        await this.$store.dispatch('resetPassword', {
          password: this.password,
          passwordResetToken: this.token
        })
        // will forward to login if succesful
      } catch (error) {
        this.resetPasswordIsLoading = false
      }
      this.resetPasswordIsLoading = false
    },
    checkPassword () {
      this.passwordErrors = []

      if (!this.password) {
        this.passwordErrors.push('Password required')
        return false
      }

      if (this.password.length < 8) {
        this.passwordErrors.push('Password should be at least 8 characters long')
      }

      if (!this.passwordErrors.length) {
        return true
      }
    },
    checkToken () {
      this.tokenErrors = []

      if (!this.token) {
        this.tokenErrors.push('Your password reset link is invalid. Make sure you copied the full link from your reset email.')
        return false
      }
      if (!this.token.length === 24) {
        this.tokenErrors.push('Your password reset link is invalid. Make sure you copied the full link from your reset email.')
      }
      if (!this.tokenErrors.length) {
        return true
      }
    }
  }

}
</script>

<style scoped>
.login-positioner {
  margin-top: -5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  margin-bottom: 38Px;
}

.login {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e0e4eb;
  width: 360px;
  display: flex;
  flex-direction: column;
  padding: 36px;
  box-shadow: 0px 2px 12px hsla(216, 18%, 19%, 0.1);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-title {
  display: flex;
  justify-content: center;
  color: #36404e;
  font-size: 26px;
  font-weight: 600;
}

.description {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.email-label {
  margin-top: 15px;
}

.login-button {
  width: 100%;
  margin-top: 15px;
}

.register-remark {
  margin-top: 20px;
  padding-top: 10px;
}

.success-message {
  background-color: #f6fefa;
  color: #007a37;
  border: solid 1px #b6edce;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
}
</style>
