
const state = {
  priorities: [
    {
      color: '#0dab54',
      color_fade: '#b6edce',
      color_hover: '#009945',
      name: 'low',
      value: 10
    },
    {
      color: '#fbbf09',
      color_fade: '#ffe79e',
      color_hover: '#eba400',
      name: 'medium',
      value: 20
    },
    {
      color: '#ff6200',
      color_fade: '#ffc39e',
      color_hover: '#e65800',
      name: 'high',
      value: 30
    },
    {
      color: '#db2929',
      color_fade: '#ffbdbd',
      color_hover: '#c91818',
      name: 'critical',
      value: 40
    }
  ]

}

const getters = {
  getPriorityByValue: (state) => (priorityValue) => {
    return state.priorities.find(element => element.value === priorityValue)
  }
}

const mutations = {

}

const actions = {

}

export default {
  state,
  mutations,
  actions,
  getters
}
