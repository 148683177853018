<template>
  <div class="file-uploader">
    <form
      enctype="multipart/form-data"
      novalidate>
      <div class="dropbox">
        <input
          type="file"
          multiple
          :name="uploadFieldName"
          :disabled="uploadStatus === 'saving'"
          @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
          accept="image/*"
          class="input-file">
        <!--INITIAL-->
        <div
          class="dropbox-text"
          v-if="uploadStatus === 'initial'">
          Upload image
        </div>
        <!--SAVING-->
        <div
          class="dropbox-text"
          v-if="uploadStatus === 'saving'">
          Uploading {{ fileCount }} files...
        </div>
        <!--SUCCESS-->
        <div
          class="dropbox-text"
          v-if="uploadStatus === 'success'">
          Uploaded successfully! Click to upload more.
        </div>
        <!--FAILED-->
        <div
          class="dropbox-text"
          v-if="uploadStatus === 'failed'">
          Uploading failed: {{ uploadError }}
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import UploadApi from '../api/UploadApi.js'

export default {
  data () {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'files',
      uploadStatus: 'initial'
    }
  },
  methods: {
    reset () {
      // reset form to initial state
      this.uploadStatus = 'initial'
      this.uploadedFiles = []
      this.uploadError = null
    },
    async save (formData) {
      // upload data to the server
      this.uploadStatus = 'saving'

      // request s3 signed URL and the S3 url to the file after it will be uploaded from server
      var _this = this
      async function getAllS3uploadInfo (files) {
        var s3UploadInfos = {}
        let i = 0
        for (const file of files) {
          i++
          s3UploadInfos[i] = (await UploadApi.getS3UploadInfo(file)).data
        }
        return s3UploadInfos
      }
      async function uploadAllFiles (files, s3UploadInfos) {
        let i = 0
        for (const file of files) {
          i++
          s3UploadInfos[i].urlAfterUpload = encodeURI(s3UploadInfos[i].urlAfterUpload)
          await UploadApi.uploadFile(file, s3UploadInfos[i].s3SignedUrl)
          const attachment = {
            name: file.name,
            size: file.size,
            type: file.type,
            url: s3UploadInfos[i].urlAfterUpload
          }
          _this.uploadedFiles.push(attachment)
        }
        // only sets success status after all files are uploaded
        _this.uploadStatus = 'success'
      }
      async function uploadFiles (files) {
        try {
          var s3UploadInfos = await getAllS3uploadInfo(files)
          await uploadAllFiles(files, s3UploadInfos)
          _this.uploadStatus = 'success'
        } catch (error) {
          _this.uploadError = error.toString()
          _this.uploadStatus = 'failed'
        }
      }
      await uploadFiles(formData.getAll('files'))

      this.$emit('filesUploaded', this.uploadedFiles)

      // reset uploaded files so if users uploads more file we don't reupload these
      this.reset()
    },
    filesChange (fieldName, fileList) {
      // handle file changes
      const formData = new FormData()
      if (!fileList.length) return

      // append the files to FormData, files can be accessed via the html fieldname files
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          formData.append(fieldName, fileList[x], fileList[x].name)
        })
      // save it
      this.save(formData)
    }
  },
  mounted () {
    this.reset()
  },
  watch: {
    uploadStatus () {
      this.$emit('uploadStatusChanged', this.uploadStatus)
    }
  }
}
</script>

<style scoped>
.file-uploader {
    width: 100%;
}

.dropbox {
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 2px dashed #d9dfe7;
    border-radius: 4px;
    outline-offset: -8px;
    background-color: #f9fafb;
    color: #60718a;
    padding: 0px 10px;
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 40px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background-color:#f5f9ff;
    color: #1764d9;
    outline: 2px dashed #1764d9;
    transition-duration: 0.3s;
  }

  .dropbox-text{
    display: flex;
    padding-right: 5px;
    padding-left: 5px;
  }

</style>
