<template>
  <div class="tickets-list-welcome-message">

    <svg
      class="welcome-image"
      viewBox="0 0 512 512">
      <g>
        <polygon
          fill="#60718a"
          points="61.342,226.39 61.342,454.002 30.906,454.002 30.906,222.741   "/>
        <polygon
          fill="#60718a"
          points="481.09,222.751 481.09,454.002 450.654,454.002 450.654,226.39"/>
      </g>
      <path
        fill="#b6edce"
        d="M504.399,57.998v161.589c-69.095,9.834-138.635,15.542-208.237,17.134
          c-96.378,2.19-192.887-3.518-288.555-17.134V57.998c23.522,3.346,47.094,6.215,70.697,8.608h0.01
          C220.082,80.972,363.148,78.103,504.399,57.998z"/>
      <g>
        <path
          fill="#60718a"
          d="M30.906,222.741l30.436,3.65v34.978c-10.149-1.125-20.298-2.342-30.436-3.65
            C30.906,257.719,30.906,222.741,30.906,222.741z"/>
        <path
          fill="#60718a"
          d="M481.09,222.751v34.968c-10.139,1.308-20.287,2.525-30.436,3.65V226.39L481.09,222.751z"/>
      </g>
      <path
        fill="#60cd91"
        d="M296.162,236.722c-96.378,2.19-192.887-3.518-288.555-17.134V57.998
          c23.522,3.346,47.094,6.215,70.697,8.608c0,0,63.113,159.612,217.727,170.106C296.071,236.712,296.122,236.722,296.162,236.722z"/>
      <path
        fill="#4b586c"
        d="M181.863,195.095c4.199,0,7.604-3.405,7.604-7.604v-57.699c5.463,0.3,10.922,0.578,16.358,0.803
          c0.106,0.004,0.213,0.006,0.319,0.006c4.054,0,7.422-3.201,7.592-7.29c0.173-4.195-3.087-7.738-7.284-7.912
          c-16.027-0.663-32.252-1.664-48.223-2.972c-4.186-0.34-7.856,2.772-8.199,6.958c-0.343,4.185,2.772,7.856,6.958,8.199
          c5.73,0.469,11.496,0.885,17.27,1.274v58.632C174.259,191.69,177.664,195.095,181.863,195.095z"/>
      <path
        fill="#4b586c"
        d="M393.631,189.493c4.2,0,7.604-3.405,7.604-7.604v-59c5.877-0.709,11.747-1.444,17.576-2.234
          c4.161-0.564,7.078-4.394,6.515-8.555c-0.564-4.162-4.397-7.082-8.556-6.515c-15.905,2.154-32.072,4.013-48.055,5.523
          c-4.18,0.395-7.249,4.105-6.855,8.285c0.372,3.938,3.684,6.889,7.561,6.889c0.239,0,0.482-0.011,0.725-0.034
          c5.279-0.499,10.579-1.048,15.881-1.621v57.261C386.027,186.088,389.43,189.493,393.631,189.493z"/>
      <path
        fill="#4b586c"
        d="M344.334,140.402v-2.251c0-6.557-2.568-12.603-7.231-17.026c-4.711-4.469-10.956-6.747-17.626-6.383
          c-6.317,0.335-12.708,0.623-18.992,0.857c-4.087,0.151-7.322,3.509-7.322,7.599v35.91v29.834c0,4.199,3.404,7.604,7.604,7.604
          s7.604-3.405,7.604-7.604v-22.499c5.57,0.049,10.703,2.073,14.558,5.771c3.995,3.831,6.196,9.106,6.196,14.853
          c0,4.199,3.404,7.604,7.604,7.604s7.604-3.405,7.604-7.604c0-9.929-3.864-19.102-10.878-25.829
          c-0.011-0.011-0.024-0.021-0.035-0.032C339.985,156.537,344.334,148.857,344.334,140.402z M308.371,130.501
          c3.969-0.171,7.955-0.363,11.911-0.573c2.43-0.134,4.702,0.662,6.356,2.232c1.604,1.522,2.488,3.65,2.488,5.992v2.251
          c0,5.279-4.417,9.972-9.648,10.248h-0.001c-3.676,0.195-7.39,0.375-11.106,0.537V130.501z"/>
      <path
        fill="#4b586c"
        d="M266.856,197.327c4.2,0,7.604-3.405,7.604-7.604v-48.287c0-13.723-11.243-24.951-25.064-25.03
          c-6.787-0.008-13.105,2.548-17.856,7.273c-4.699,4.674-7.287,10.911-7.287,17.563v48.287c0,4.199,3.405,7.604,7.604,7.604
          c4.199,0,7.604-3.405,7.604-7.604v-22.704c5.519,0.075,11.051,0.127,16.576,0.127c1.075,0,2.141-0.011,3.215-0.014v22.786
          C259.252,193.923,262.656,197.327,266.856,197.327z M239.462,151.626v-10.384c0-2.575,0.996-4.983,2.804-6.782
          c1.86-1.85,4.347-2.823,7.047-2.847c5.48,0.031,9.939,4.437,9.939,9.822v10.301C252.665,151.754,246.052,151.717,239.462,151.626z"
      />
      <path
        fill="#4b586c"
        d="M103.969,124.335c1.442-1.277,3.466-1.833,5.699-1.561c6.141,0.746,12.381,1.456,18.551,2.11
          c4.179,0.441,7.92-2.584,8.362-6.761c0.442-4.176-2.585-7.92-6.761-8.362c-6.091-0.645-12.254-1.345-18.318-2.082
          c-6.618-0.804-12.873,1.072-17.618,5.276c-4.693,4.159-7.278,10.055-7.278,16.605c0,13.061,10.132,24.52,23.065,26.089l2.043,0.246
          c5.259,0.632,9.702,5.549,9.702,10.737c0,2.206-0.808,4.139-2.276,5.44c-1.453,1.289-3.446,1.856-5.614,1.595
          c-6.073-0.728-12.229-1.514-18.295-2.335c-4.165-0.563-7.992,2.354-8.555,6.515c-0.564,4.162,2.353,7.992,6.515,8.555
          c6.143,0.831,12.376,1.627,18.524,2.364c0.971,0.117,1.934,0.174,2.887,0.174c5.471,0,10.584-1.902,14.631-5.492
          c4.697-4.167,7.392-10.297,7.392-16.817c0-12.932-10.146-24.28-23.099-25.836l-2.024-0.243c-5.161-0.627-9.687-5.763-9.687-10.992
          C101.815,127.39,102.56,125.584,103.969,124.335z"/>
      <path
        fill="#4b586c"
        d="M509.379,52.256c-1.665-1.445-3.875-2.098-6.055-1.785C362.914,70.454,220.173,73.339,79.069,59.042
          c-4.176-0.427-7.908,2.621-8.332,6.799c-0.424,4.178,2.621,7.908,6.799,8.332C216.962,88.3,357.959,85.797,496.792,66.74v146.232
          c-159.823,22.136-321.76,22.136-481.584,0V66.74c11.705,1.606,23.504,3.105,35.186,4.472c4.181,0.494,7.949-2.498,8.435-6.669
          c0.488-4.171-2.498-7.948-6.669-8.435c-14.449-1.689-29.08-3.585-43.485-5.636c-2.186-0.311-4.391,0.341-6.055,1.785
          C0.956,53.7,0,55.796,0,57.999v161.584c0,3.786,2.785,6.995,6.532,7.528c5.587,0.795,11.177,1.558,16.769,2.299v224.592
          c0,4.199,3.405,7.604,7.604,7.604h30.436c4.199,0,7.604-3.405,7.604-7.604V234.866c62.188,6.609,124.621,9.915,187.054,9.915
          c62.433,0,124.864-3.305,187.05-9.915v67.591c0,4.199,3.404,7.604,7.604,7.604c4.2,0,7.604-3.405,7.604-7.604v-69.275
          c5.078-0.584,10.154-1.191,15.229-1.82v215.036h-15.229V327.804c0-4.199-3.404-7.604-7.604-7.604c-4.2,0-7.604,3.405-7.604,7.604
          v126.198c0,4.199,3.404,7.604,7.604,7.604h30.437c4.2,0,7.604-3.405,7.604-7.604V229.412c5.593-0.741,11.184-1.505,16.772-2.3
          c3.747-0.533,6.532-3.742,6.532-7.528V57.999C512,55.796,511.044,53.7,509.379,52.256z M53.738,446.398H38.509V231.361
          c5.074,0.629,10.15,1.236,15.228,1.82V446.398z"/>
    </svg>
    <div class="welcome-message-title">
      No tickets
    </div>
    <div class="welcome-message-subtitle">
      Create your first ticket. Let's start!
    </div>
    <div class="welcome-message-button-container">
      <swift-button
        color="blue"
        @click.native="routeToCreateTicket()">New ticket</swift-button>
    </div>
  </div>
</template>

<script>
import SwiftButton from './SwiftButton.vue'

export default {
  components: {
    SwiftButton
  },
  methods: {
    routeToCreateTicket () {
      this.$router.push({ name: 'create-ticket' })
    }
  }
}
</script>

<style scoped>
.tickets-list-welcome-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.welcome-image {
  margin-top: 70px;
  height: 100px;
}

.welcome-message-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
}

.welcome-message-subtitle {
  margin-top: 0px;
  font-size: 16px;
  color: #8290a6;
}

.welcome-message-button-container {
  margin-top: 10px;
}
</style>
