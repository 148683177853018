<template>
  <div class="project-create">
    <div>
      <input
        class="global-input project-name-input"
        name="project"
        type="text"
        v-model="projectName"

        autocomplete="off"
        placeholder="Project name..."
        ref="name"
        @keyup.enter.stop="$event.target.blur()"
        @blur="createProject()"
      >
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data: function () {
    return {
      projectName: ''
    }
  },
  mounted () {
    this.$refs.name.focus()
  },
  methods: {
    createProject () {
      if (!this.projectName) {
        this.$emit('close')
        return
      }
      const projectData = {
        name: this.projectName
      }
      this.$store.dispatch('createProject', projectData)
      this.projectName = ''
      this.$emit('close')
    }

  }
}
</script>

<style scoped>

.project-name-input {
  font-size: 14px;
  font-weight: 600;
  width: 130px;
  margin-left: -10px;
  padding-left: 10px;
  padding-right: 5px;
}

.project-name-input:focus {
  border: 1px solid #282f39;
  box-shadow: 0 0 0 2px #60718a;
  /* ensure shadows overlays surroundign element */
  z-index: 1;
  transition-duration: 0.3s;
}

.project-name-input::placeholder {
  font-size: 14px;
  font-weight: 400;
}

</style>
