<template>
  <div class="widgets">
    <div class="loading" v-if="!$store.state.widgets.widgetsAreLoaded">
      <icon-spinner width="20px" height="20px" fill="#fff" />
    </div>
    <div class="global-panel" v-if="$store.state.widgets.widgetsAreLoaded">
      <div class="title-row">
        <icon-widget class="title-icon" height="20px" width="20px" fill="#347be5"/>
        <h1>
          Widgets
        </h1>
        <div class="new-widget-button-aligner">
          <swift-button
            color="blue"
            @click.native="routeToCreateWidget()">New widget</swift-button>
        </div>
      </div>
      <h2 class="subtitle">
        Current Widgets ({{ $store.state.widgets.widgets.length}})
      </h2>
      <div class="global-table">
        <div class="global-table-head-row">
          <div class="global-table-column">
            Name
          </div>
          <div class="global-table-column">
            Active
          </div>
          <div class="global-table-column">
            Default Status
          </div>
          <div class="global-table-column">
            Widget Key
          </div>
        </div>
        <div class="global-table-row widgets-row" v-for="widget in $store.state.widgets.widgets" :key="widget.id" @click="routeToWidget(widget.id)">
          <div class="global-table-column">
            {{ widget.name }}
          </div>
          <div class="global-table-column">
            {{ widget.active }}
          </div>
          <div class="global-table-column">
            <div class="status" v-if="widget.defaultStatusId && $store.state.status.statusesAreLoaded">
              <div
                class="status-color"
                :style="{ 'background-color': getStatusById(widget.defaultStatusId).color }"/>
              <div class="status-name">
                {{ getStatusById(widget.defaultStatusId).name }}
              </div>
            </div>
          </div>
          <div class="global-table-column">
            {{ widget.key }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiftButton from './SwiftButton.vue'
import IconWidget from './icons/IconWidget.vue'
import IconSpinner from './icons/IconSpinner.vue'

export default {
  components: {
    SwiftButton,
    IconWidget,
    IconSpinner
  },
  async created () {
    await this.$store.dispatch('getWidgetsOfProject', this.$route.params.projectId)
    await this.$store.dispatch('getStatusesOfProject', this.$route.params.projectId)
  },
  computed: {
    getStatusById () {
      return this.$store.getters.getStatusById
    }
  },
  methods: {
    routeToCreateWidget () {
      this.$router.push({ name: 'create-widget' })
    },
    routeToWidget (widgetId) {
      this.$router.push({
        name: 'widget',
        params: {
          widgetId: widgetId.toString()
        }
      })
    }
  }
}
</script>

<style scoped>

.title-row {
  display: flex;
  align-items: center;
}

.title-icon {
  margin-right: 7px;
}

.new-widget-button-aligner {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.subtitle {
  margin-top: 50px;
  margin-bottom: 20px;
}

.widgets-row {
  cursor: pointer;
}
.widgets-row:hover {
  background-color: #f5f9ff;
  transition: 0.1s;
}

.status {
  display: flex;
  align-items: center;
}
.status-color {
  display: flex;
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-right: 4px;
}

</style>
