<template>
  <div
    class="swift-priority-tag"
    :style="{ backgroundColor: priority.color, width: width }">
    {{ priority.name }}
  </div>
</template>

<script>

export default {
  props: {
    priority: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      default: '54px'
    }
  }
}
</script>

<style scoped>
.swift-priority-tag {
  display: flex;
  justify-content: center;
  font-size: 12px;
  border-radius: 4px;
  color: #FFF;
}

</style>
