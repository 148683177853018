import TicketsApi from '../../api/TicketsApi.js'

const state = {
  showCreateTicket: false,
  reporterId: null,
  assigneeId: null,
  title: null,
  description: null,
  projectId: null,
  statusId: null,
  priorityValue: null,
  attachments: []
}

const mutations = {
  showCreateTicket: state => {
    state.showCreateTicket = true
  },
  setCreateTicketReporterId: (state, currentUserId) => {
    state.reporterId = currentUserId
  },
  setCreateTicketProjectId: (state, projectId) => {
    state.projectId = projectId
  },
  clearCreateTicket: state => {
    state.reporterId = null
    state.assigneeId = null
    state.title = null
    state.description = null
    state.statusId = null
    state.priorityId = null
    state.attachments = []
    state.projectId = null
  },
  closeCreateTicket: state => {
    state.showCreateTicket = false
    state.statusId = null
    state.assigneeId = null
  },
  setCreateTicketPriorityValue: (state, priorityValue) => {
    if (priorityValue === null) {
      state.priorityValue = null
    } else {
      state.priorityValue = priorityValue
    }
  },
  updateCreateTicketTitle: (state, value) => {
    state.title = value
  },
  updateCreateTicketDescription: (state, value) => {
    state.description = value
  },
  setCreateTicketAssignee: (state, assignee) => {
    if (assignee === null) {
      state.assigneeId = null
    } else {
      state.assigneeId = assignee.id
    }
  },
  setCreateTicketStatus: (state, status) => {
    if (status === null) {
      state.statusId = null
    } else {
      state.statusId = status.id
    }
  },
  pushCreateTicketAttachments: (state, attachments) => {
    // push new array to existing array with spread operator
    state.attachments.push(...attachments)
  }
}

const actions = {
  async createTicket ({ commit, state, rootState, dispatch }) {
    // defaults to current project and user if no explicit value is given
    if (!state.projectId) {
      commit('setCreateTicketProjectId', rootState.projects.currentProjectId)
    }
    if (!state.reporterId) {
      commit('setCreateTicketReporterId', rootState.user.user.id)
    }

    const response = await TicketsApi.createTicket({
      reporterId: state.reporterId,
      assigneeId: state.assigneeId,
      projectId: state.projectId,
      title: state.title,
      description: state.description,
      statusId: state.statusId,
      priorityValue: state.priorityValue,
      attachments: state.attachments
    })
    commit('clearCreateTicket')

    return response
  }
}

export default {
  state,
  mutations,
  actions
}
