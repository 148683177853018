import axios from 'axios'
import store from '@/store/store'

export default {
  deleteUserById (userId) {
    return axios.delete(`/api/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  }
}
