import axios from 'axios'
import store from '../store/store'

export default function execute () {
  axios.interceptors.response.use(
    function (response) {
      if (response.data.successMessage) {
        const notification = {
          type: 'success',
          text: response.data.successMessage
        }
        store.dispatch('createNotification', notification)
      }
      return response
    },
    function (error) {
      // handle error
      const errorNotification = {
        type: 'error',
        statusCode: error.response.status,
        statusText: error.response.statusText,
        text: error.response.data.error
      }
      if (error.response) {
        store.dispatch('createNotification', errorNotification)
        if (error.response.status === 401) {
          store.dispatch('removeInvalidLogin')
        }
      }
      throw new Error(error) // forward errors to stop executing calling functions
    }
  )
}
