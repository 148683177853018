import AuthenticationApi from '../../api/AuthenticationApi.js'
import router from '../../router.js'

const state = {
  token: null,
  isUserLoggedIn: false,
  entryPath: null
}

const getters = {

}

const mutations = {
  setToken: (state, token) => {
    state.token = token
    if (token) {
      state.isUserLoggedIn = true
    } else {
      state.isUserLoggedIn = false
    }
  },
  routeToLogin: (state) => {
    router.push({ name: 'login' })
  },
  logout: (state) => {
    state.token = null
    state.isUserLoggedIn = false
  },
  setEntryPath: (state, entryPath) => {
    state.entryPath = entryPath
  }
}

const actions = {
  async register ({ commit, dispatch, rootState }, credentials) {
    const response = (await AuthenticationApi.register({
      email: credentials.email,
      password: credentials.password
    })).data
    commit('setToken', response.token)
    commit('saveUser', response.user)
    await dispatch('getOrganizationUsersProjectsUser')
    dispatch('selectProject', rootState.projects.currentProjectId)
  },
  async registerInvite ({ commit, dispatch, rootState }, credentials) {
    const response = (await AuthenticationApi.registerInvite({
      email: credentials.email,
      password: credentials.password,
      inviteToken: credentials.inviteToken
    })).data
    commit('setToken', response.token)
    commit('saveUser', response.user)
    await dispatch('getOrganizationUsersProjectsUser')
    dispatch('selectProject', rootState.projects.currentProjectId)
  },
  async login ({ commit, dispatch, state, rootState }, credentials) {
    const response = (await AuthenticationApi.login({
      email: credentials.email,
      password: credentials.password
    })).data
    commit('setToken', response.token)
    commit('saveUser', response.user)

    // if user first came to different url forward to that url after login otherwise show default project
    if (state.entryPath || !state.entryPath === null) {
      router.push({ path: state.entryPath })
      commit('setEntryPath', null)
    } else {
      await dispatch('getOrganizationUsersProjectsUser')
      dispatch('selectProject', rootState.projects.currentProjectId)
    }
  },
  async forwardLoggedInUser ({ commit, dispatch, state, rootState }) {
    // if user first came to different url forward to that url after login otherwise show default project
    if (state.entryPath || !state.entryPath === null) {
      router.push({ path: state.entryPath })
      commit('setEntryPath', null)
    } else {
      await dispatch('getOrganizationUsersProjectsUser')
      dispatch('selectProject', rootState.projects.currentProjectId)
    }
  },
  async logout ({ commit }) {
    // removes current token on the server-side
    await AuthenticationApi.logout()
    commit('logout') // this will cause the vue-persist-store plugin to set all state to empty
    location.reload()
  },
  removeInvalidLogin ({ commit }) {
    // triggered by 401 respons eof server in apierrorhandler
    commit('logout')
    location.reload()
  },
  async forgotPassword ({ commit }, email) {
    const response = (await AuthenticationApi.forgotPassword(email)).data
    return response
  },
  async resetPassword ({ commit }, credentials) {
    const response = (await AuthenticationApi.resetPassword(credentials)).data
    if (response.successMessage) {
      router.push({ path: '/login' })
    }
  },
  async impersonate ({ commit, dispatch, state, rootState }, email) {
    const response = (await AuthenticationApi.impersonate(email)).data
    commit('setToken', response.token)
    commit('saveUser', response.user)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
