<template>
  <div class="tickets-header">
    <div class="tickets-header-left">
      <div
        @click="sortByCreatedAt()"
        class="tickets-header-date tickets-header-item"
        :class="{ 'tickets-header-item-active': $route.query.sortBy && $route.query.sortBy.includes('createdAt')}">
        Created on
        <icon-sort-down
          width="13px"
          height="13px"
          fill="#4b586c"
          v-if="$route.query.sortBy == 'createdAt_desc'" />
        <icon-sort-up
          width="13px"
          height="13px"
          fill="#4b586c"
          v-if="$route.query.sortBy == 'createdAt_asc'" />
      </div>
      <div class="tickets-header-reporter tickets-header-item">
        <ticket-list-sorting-filter-reporter
          @selectedReporter="filterByReporter($event)"
          :initial-reporter="getUserById(parseInt($route.query.reporter))" />
      </div>
      <div
        class="tickets-header-updated tickets-header-item"
        @click="sortByUpdatedAt()"
        :class="{ 'tickets-header-item-active':  $route.query.sortBy && $route.query.sortBy.includes('updatedAt') }">
        Updated on
        <icon-sort-down
          width="13px"
          height="13px"
          fill="#4b586c"
          v-if="$route.query.sortBy == 'updatedAt_desc'" />
        <icon-sort-up
          width="13px"
          height="13px"
          fill="#4b586c"
          v-if="$route.query.sortBy == 'updatedAt_asc'" />
      </div>
    </div>
    <div class="tickets-header-assignee tickets-header-item">
      <ticket-list-sorting-filter-assignee
        @selectedAssignee="filterByAssignee($event)"
        :initial-assignee="getUserById(parseInt($route.query.assignee))" />
    </div>
    <div
      class="tickets-header-prio tickets-header-item"
      @click="sortByPriority()"
      :class="{ 'tickets-header-item-active':  $route.query.sortBy && $route.query.sortBy.includes('priority') }">
      Priority
      <icon-sort-down
        width="13px"
        height="13px"
        fill="#4b586c"
        v-if="$route.query.sortBy == 'priority_desc'"  />
      <icon-sort-up
        width="13px"
        height="13px"
        fill="#4b586c"
        v-if="$route.query.sortBy == 'priority_asc'"  />
    </div>
    <div class="tickets-header-thumb tickets-header-item"/>
    <div
      class="tickets-header-comment tickets-header-item"
      @click="sortByComments()"
      :class="{ 'tickets-header-item-active':  $route.query.sortBy && $route.query.sortBy.includes('comments') }">
      Comments
      <icon-sort-down
        width="13px"
        height="13px"
        fill="#4b586c"
        v-if="$route.query.sortBy == 'comments_desc'" />
      <icon-sort-up
        width="13px"
        height="13px"
        fill="#4b586c"
        v-if="$route.query.sortBy == 'comments_asc'" />
    </div>
  </div>
</template>

<script>
import IconSortDown from './icons/IconSortDown.vue'
import IconSortUp from './icons/IconSortUp.vue'
import TicketListSortingFilterAssignee from './TicketListSortingFilterAssignee.vue'
import TicketListSortingFilterReporter from './TicketListSortingFilterReporter.vue'

export default {
  components: {
    IconSortDown,
    IconSortUp,
    TicketListSortingFilterAssignee,
    TicketListSortingFilterReporter
  },
  computed: {
    getUserById () {
      return this.$store.getters.getUserById
    }
  },
  methods: {
    sortByCreatedAt () {
      let direction = 'createdAt_desc' // default
      if (this.$route.query.sortBy && this.$route.query.sortBy.includes('createdAt_desc')) {
        direction = 'createdAt_asc'
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          sortBy: direction
        } })
    },
    sortByUpdatedAt () {
      let direction = 'updatedAt_desc' // default
      if (this.$route.query.sortBy && this.$route.query.sortBy.includes('updatedAt_desc')) {
        direction = 'updatedAt_asc'
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          sortBy: direction
        } })
    },
    sortByComments () {
      let direction = 'comments_desc' // default
      if (this.$route.query.sortBy && this.$route.query.sortBy.includes('comments_desc')) {
        direction = 'comments_asc'
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          sortBy: direction
        } })
    },
    sortByPriority () {
      let direction = 'priority_desc' // default
      if (this.$route.query.sortBy && this.$route.query.sortBy.includes('priority_desc')) {
        direction = 'priority_asc'
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          sortBy: direction
        } })
    },
    filterByAssignee (assignee) {
      // remove from query if no assignee
      if (!assignee) {
        if (!this.$route.query.assignee) return // prevent duplicate navigaiton
        let query = Object.assign({}, this.$route.query)
        delete query.assignee
        query.page = 1
        this.$router.push({ query })
        return
      }
      // prevent duplicate navigaiton
      if (parseInt(this.$route.query.assignee) === assignee.id) return

      // add to query
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          assignee: assignee.id
        } })
    },
    filterByReporter (reporter) {
      console.log('reporter', reporter)

      // remove from query if no reporter
      if (!reporter) {
        if (!this.$route.query.reporter) return // prevent duplicate navigaiton
        let query = Object.assign({}, this.$route.query)
        delete query.reporter
        query.page = 1
        this.$router.push({ query })
        return
      }
      // prevent duplicate navigaiton
      if (parseInt(this.$route.query.reporter) === reporter.id) return

      // add to query
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          reporter: reporter.id
        } })
    }

  }
}
</script>

<style scoped>
.tickets-header {
    display: flex;
    padding-bottom: 9px;
    padding-top: 9px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 8px hsla(216, 18%, 19%, 0.03), 0 1px 2px hsla(216, 18%, 19%, 0.2);
    margin-bottom: 5px;
    border-radius: 2px;
    font-weight: 600;
    color: #9ba8bb;
    font-size: 13px;
     -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
@media (max-width: 600px) {
    .tickets-header {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.tickets-header-item:hover,
.tickets-header-item:focus {
    color: #1764d9;
    cursor: pointer;
    transition-duration: 0.3s;
}
.tickets-header-item-active {
    color: #1764d9;
}
.tickets-header-left {
    flex-basis: 100px;
    flex-grow: 4;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
}
.tickets-header-date {
    margin-left: 0px;
}

.tickets-header-reporter {
    margin-left: 20px;
}
@media (max-width: 300px) {
    .tickets-header-reporter {
        display: none;
    }
}
.tickets-header-updated {
    margin-left: 20px;
    flex-grow: 1;
}
@media (max-width: 420px) {
    .tickets-header-updated {
        display: none;
    }
}
.tickets-header-assignee {
    flex-basis: 100px;
    flex-grow: 0.7;
    margin-left: 10px;
    display: flex;
    justify-content: center;
}
@media (max-width: 900px) {
    .tickets-header-assignee {
        flex-basis: 50px;
    }
}
.tickets-header-prio {
    flex-basis: 70px;
    flex-grow: 0.2;
    flex-shrink: 0;
    margin-left: 10px;
    display: flex;
    justify-content: center;
}
@media (max-width: 600px) {
    .tickets-header-prio {
        display: none;
    }
}
.tickets-header-thumb {
    margin-left: 10px;
    flex-basis: 100px;
    flex-grow: 0.1;
    flex-shrink: 0;
}
@media (max-width: 700px) {
    .tickets-header-thumb {
        display: none;
    }
}
.tickets-header-comment {
    flex-basis: 50px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 10px;
    display: flex;
    justify-content: center;
}
@media (max-width: 800px) {
    .tickets-header-comment {
        display: none;
    }
}
</style>
