<template>
  <div class="global-panel widget-settings">
    <div class="global-title">
      Create New Feedback Widget
    </div>
    <div class="name-area">
      <div class="global-label">
        Widget Name
      </div>
      <input class="global-input name-input" v-model="name" placeholder="Add a name">
    </div>
    <div class="default-status-area"
         v-if="$store.state.status.statuses.length > 0">
      <div class="global-label">
        Assign incoming feedback to this status
      </div>
      <swift-select-status
        @selectedStatus="setDefaultStatus($event)" />
    </div>
    <swift-button
      color="blue"
      @click.native="createWidget()"
      :is-loading="widgetButtonIsLoading">Create widget</swift-button>

  </div>
</template>

<script>
import WidgetsApi from '../api/WidgetsApi.js'
import SwiftButton from './SwiftButton.vue'
import SwiftSelectStatus from './SwiftSelectStatus.vue'

export default {
  components: {
    SwiftButton,
    SwiftSelectStatus
  },
  data () {
    return {
      name: '',
      defaultStatusId: null,
      widgetButtonIsLoading: false
    }
  },
  async created () {
    await this.$store.dispatch('getStatusesOfProject', this.$store.state.projects.currentProjectId)
  },
  methods: {
    setDefaultStatus (status) {
      if (!status) {
        return
      }

      this.defaultStatusId = status.id
    },
    async createWidget () {
      this.widgetButtonIsLoading = true

      // send data to server

      const response = await WidgetsApi.createWidget({
        projectId: this.$store.state.projects.currentProjectId,
        name: this.name,
        active: true,
        defaultStatusId: this.defaultStatusId,
        buttonBackgroundColor: '#1764d9',
        buttonTextColor: '#FFFFFF',
        buttonText: 'Feedback',
        buttonPosition: 'bottom-right',
        showDescription: true,
        descriptionIsMandatory: false,
        showEmail: true,
        emailIsMandatory: false
      })

      // failed to created widget
      if (response.status !== 201) {
        this.widgetButtonIsLoading = false
        return
      }
      // successfully created forward to widget page
      this.widgetButtonIsLoading = false
      const widgetId = response.data.id
      this.$router.push({ name: 'widget', params: { widgetId: widgetId.toString() } })
    }

  }
}
</script>

<style scoped>
.name-area {
  margin-bottom: 20px;
  width: 300px;
}
.name-input {
  width: 100%;
}
.default-status-area {
  width: 300px;
  margin-bottom: 20px;
}

</style>
