<template>
  <div class="ticket-attachments">
    <div class="selected-attachment" v-if="selectedAttachment">
      <a :href="toCloudImageFullsizeUrl(selectedAttachment.url)" target="_blank">
        <img :src="toCloudImageUrl(selectedAttachment.url)" draggable="false" />
      </a>
      <div>
        {{ selectedAttachment.name }}
      </div>
    </div>
    <div class="attachments">
      <div
        v-for="attachment in attachments"
        :key="attachment.id">
        <div class="attachment">
          <div
            class="attachment-image"
            :class="{ active: attachment.id === selectedAttachmentId}"
            @click="selectAttachment(attachment.id)"
            :style="{ backgroundImage: toCloudImageBackgroundUrl(attachment.url) }" />
          <div class="attachment-info">
            {{ attachment.name }}
          </div>
          <div class="attachment-info-subtitle">
            {{ formatBytes(attachment.size) }}
          </div>
        </div>
      </div>
      <div class="attachment" v-if="$store.state.tickets.ticketIsLoaded && $store.state.tickets.ticket.html !== null">
        <a class="attachment-html" :href="$store.state.tickets.ticket.html.url" target="_blank">
          <icon-file fill="#1764d9" width="30px" height="30px"/>
        </a>
        <div class="attachment-info">
          HTML document
        </div>
        <div class="attachment-info-subtitle">
          {{ formatBytes($store.state.tickets.ticket.html.size) }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import IconFile from './icons/IconFile.vue'

export default {
  components: {
    IconFile
  },
  data: function () {
    return {
      selectedAttachmentId: null
    }
  },
  props: {
    attachments: Array,
    selectable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedAttachment () {
      if (!this.selectable) {
        return null
      }
      // if no selection return first element by default
      if (this.selectedAttachmentId) {
        return this.attachments.find(element => element.id === this.selectedAttachmentId)
      } else {
        if (this.attachments.length) {
          return this.attachments[0]
        } else {
          return null
        }
      }
    }
  },
  methods: {
    selectAttachment (id) {
      this.selectedAttachmentId = id
    },
    formatBytes (bytes, decimals = 0) {
      if (bytes === 0) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    },
    toCloudImageBackgroundUrl (origUrl) {
      const aliasUrl = origUrl.replace('https://swiftbug-useruploads.s3.amazonaws.com', '_swiftbug_')
      const cloudImageTokenUrl = 'https://cnvcbcfiua.cloudimg.io/'
      const sizeParameters = 'width=126&height=94&sharp=1&func=bound'
      const url = `url(${cloudImageTokenUrl}${aliasUrl}?${sizeParameters})`
      return url
    },
    toCloudImageUrl (origUrl) {
      const aliasUrl = origUrl.replace('https://swiftbug-useruploads.s3.amazonaws.com', '_swiftbug_')
      const cloudImageTokenUrl = 'https://cnvcbcfiua.cloudimg.io/'
      const sizeParameters = 'width=890&height=800&func=bound&org_if_sml=1'
      const url = `${cloudImageTokenUrl}${aliasUrl}?${sizeParameters}`
      return url
    },
    toCloudImageFullsizeUrl (origUrl) {
      const aliasUrl = origUrl.replace('https://swiftbug-useruploads.s3.amazonaws.com', '_swiftbug_')
      const cloudImageTokenUrl = 'https://cnvcbcfiua.cloudimg.io/'
      const url = `${cloudImageTokenUrl}${aliasUrl}`
      return url
    }
  }
}
</script>

<style scoped>
.ticket-attachments {
    display: flex;
    flex-direction: column;
}

.selected-attachment {
  margin-bottom: 20px;
}

.selected-attachment img {
   border: 1px solid #e0e4eb;
   display: flex;
   background-color: #e6eaef;
   border-radius: 4px;
   max-width: 100%;
   max-height: 800px;
   cursor: zoom-in;
}

.attachments {
    display: flex;
    flex-wrap: wrap;
}
.attachment {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 128px;
    word-break: break-all;
}

.attachment-image {
    display: flex;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 128px;
    height: 96px;
    background-color: #e6eaef;
    border-radius: 4px;
    border: 1px solid #e0e4eb;
}

.active {
  border: 1px solid #8290a6;
  transition-duration: 0.3s;
  cursor: pointer;
}

.attachment-image:hover {
    border: 1px solid #9ba8bb;
    transition-duration: 0.3s;
    cursor: pointer;
}

.attachment-html {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #60718a;
  font-size: 13px;
  width: 128px;
  height: 96px;
  background-color: #e6eaef;
  border-radius: 4px;
  border: 1px solid #e0e4eb;
}

.attachment-html:hover {
    border: 1px solid #9ba8bb;
    transition-duration: 0.3s;
    cursor: pointer;
}

.attachment-info {
  font-weight: 600;
  color: #546278;
}

.attachment-info-subtitle {
  color: #9ba8bb;
}

</style>
