<template>
  <div>
    <swift-modal v-if="$store.state.showTicketModal && $store.state.showModal">
      <template v-slot="{closeModal}">
        <ticket-modal :close-modal="closeModal" />
      </template>
    </swift-modal>

    <div class="ticket-list">
      <div class="ticketlist-searchrow">

        <!-- stop on click prevents navbar from closing instantly again-->
        <swift-button
          class="navi-button"
          v-if="$store.state.userIsMobile"
          color="link"
          size="small"
          @click.native.stop="showNavbar()">
          <icon-hamburger
            height="20px"
            width="20px"
            fill="#4b586c" />
        </swift-button>
        <ticket-list-search/>
        <div class="new-ticket-button-aligner">
          <swift-button
            color="blue"
            id="create-ticket"
            @click.native="routeToCreateTicket()">New ticket</swift-button>
          <swift-modal v-if="$store.state.createTicket.showCreateTicket && $store.state.showModal">
            <template v-slot="{closeModal}">
              <ticket-create :close-modal="closeModal" />
            </template>
          </swift-modal>
        </div>
      </div>

      <div class="tickets" v-if="!$store.state.tickets.ticketsAreLoaded">
        <icon-spinner width="20px" height="20px" fill="#fff" />
      </div>

      <div class="tickets" v-if="$store.state.tickets.ticketsAreLoaded">
        <div class="tickets-status-row">
          <ticket-list-statuses />
        </div>
        <div class="tickets-content">
          <ticket-list-sorting />

          <ticket-list-item
            v-for="(ticket) in $store.state.tickets.tickets"
            :key="ticket.id"
            :ticket="ticket"
            @click.native="routeToTicketModal(ticket.id)" />

          <ticket-list-pagination v-if="$store.state.tickets.pagination.lastPage > 1"/>
        </div>

        <ticket-list-welcome-message v-if="$store.state.tickets.totalTickets === 0"/>
      </div>

    </div>
  </div>
</template>

<script>

import TicketListSearch from './TicketListSearch.vue'
import TicketListPagination from './TicketListPagination.vue'
import TicketListStatuses from './TicketListStatuses.vue'
import TicketListSorting from './TicketListSorting.vue'
import TicketListItem from './TicketListItem.vue'
import TicketListWelcomeMessage from './TicketListWelcomeMessage.vue'
import SwiftButton from './SwiftButton.vue'
import IconHamburger from './icons/IconHamburger.vue'
import IconSpinner from './icons/IconSpinner.vue'
import TicketCreate from './TicketCreate.vue'
import TicketModal from './TicketModal.vue'
import SwiftModal from './SwiftModal.vue'

var getScrollPositionY = function () {
  if (window.pageYOffset !== undefined) {
    return pageYOffset
  } else {
    var scrollY = document.documentElement.scrollTop || document.body.scrollTop || 0
    return scrollY
  }
}

export default {
  components: {
    TicketListSearch,
    TicketListPagination,
    TicketListStatuses,
    TicketListSorting,
    TicketListItem,
    TicketListWelcomeMessage,
    SwiftButton,
    IconHamburger,
    IconSpinner,
    TicketCreate,
    TicketModal,
    SwiftModal
  },
  async created () {
    // responsible to set correct project when linked directly
    if (this.$route.params.projectId) {
      this.$store.commit('setCurrentProjectId', this.$route.params.projectId)
    }
    if (this.$route.name === 'create-ticket') {
      this.$store.commit('showModal')
      this.$store.commit('showCreateTicket')
    }
    // responsible to open Modal if directly linked
    if (this.$route.params.ticketId) {
      let scrollPositionY = getScrollPositionY()
      this.$store.commit('saveScrollPositionBeforeModal', scrollPositionY)
      this.$store.commit('showTicketModalById', parseInt(this.$route.params.ticketId))
      this.$store.commit('showModal')
    }
    // loads all data
    this.$store.dispatch('getTicketsStatusesPrioritiesOfProject', {
      projectId: this.$store.state.projects.currentProjectId,
      query: this.$route.query
    })
  },
  watch: {
    '$route' () {
      // watches for back button change to than load the right project data
      if (this.$route.params.projectId) {
        this.$store.commit('setCurrentProjectId', this.$route.params.projectId)

        // loads all data
        this.$store.dispatch('getTicketsStatusesPrioritiesOfProject', {
          projectId: this.$store.state.projects.currentProjectId,
          query: this.$route.query
        })
      }

      // watches changes on route to open or close modals
      if (this.$route.params.ticketId) {
        let scrollPositionY = getScrollPositionY()
        this.$store.commit('saveScrollPositionBeforeModal', scrollPositionY)
        this.$store.commit('showTicketModalById', parseInt(this.$route.params.ticketId))
        this.$store.commit('showModal')
      } else if (this.$route.name === 'create-ticket') {
        let scrollPositionY = getScrollPositionY()
        this.$store.commit('saveScrollPositionBeforeModal', scrollPositionY)
        this.$store.commit('showModal')
        this.$store.commit('showCreateTicket')
      } else {
        this.$store.commit('hideTicketModal')
        this.$store.commit('closeCreateTicket')
        this.$store.commit('hideModal')
      }
    }
  },
  computed: {
    getTicketById () {
      return this.$store.getters.getTicketById
    }
  },
  methods: {
    showTicketModal () {
      this.$store.commit('showTicketModal')
    },
    showNavbar () {
      this.$store.commit('showNavbar')
    },
    showCreateTicket () {
      this.$store.commit('showModal')
      this.$store.commit('showCreateTicket')
    },
    routeToTicketModal (ticketId) {
      this.$router.push({ name: 'ticket-modal',
        query: {
          ...this.$route.query
        },
        params: {
          ticketId: ticketId
        }
      })
    },
    routeToCreateTicket () {
      this.$router.push({ name: 'create-ticket',
        query: {
          ...this.$route.query
        }
      })
    }
  }
}
</script>

<style scoped>

.tickets {
    margin-top: 15px;
    margin-bottom: 30px;
}
.navi-button {
  margin-right: 5px;
}

.tickets-status-row {
     -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.ticketlist-searchrow {
  display: flex;
}
.new-ticket-button-aligner {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}
@media (max-width: 1025px) {
    .new-ticket-button-aligner {
        margin-right: 10px;
    }
}

.tickets-content {
    background-color: #e6eaef;
    padding-bottom: 1px;
    border-radius: 4px;
    box-shadow: 0px 3px 5px hsla(216, 18%, 19%, 0.04);
}

</style>
