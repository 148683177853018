<template>
  <div class="temp-status" key="show">
    <input
      class="global-input temp-status-name-input"
      name="status"
      type="text"
      v-model="statusName"
      autocomplete="off"
      placeholder="Status name..."
      ref="name"
      @keyup.enter="$event.target.blur()"
      @blur="createStatus()"
    >
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      showTempStatus: false,
      statusName: '',
      statusColor: ''
    }
  },
  mounted () {
    this.$refs.name.focus()
  },
  computed: {
    sortedStatuses () {
      return this.$store.getters.sortedStatuses
    }
  },
  methods: {
    createStatus () {
      if (!this.statusName) {
        this.$emit('close')
        return
      }
      var orderForCreatedStatus = ''
      if (this.sortedStatuses.length > 0) {
        orderForCreatedStatus = this.sortedStatuses[this.sortedStatuses.length - 1].order + 1
      } else {
        orderForCreatedStatus = 1
      }

      if (this.statusColor === '') {
        // colors already in use
        var colorsAlreadyUsed = this.sortedStatuses.map(function (element) {
          return element.color
        })
        // all colors
        var allColors = Object.values(this.$store.state.colors)

        // colors that are still available
        var colorsNotUsed = allColors.filter(function (i) { return colorsAlreadyUsed.indexOf(i) < 0 })

        // randomly select color if still available otherwise choose from all colors
        var randomColor = ''
        if (colorsNotUsed.length) {
          randomColor = colorsNotUsed[Math.floor(Math.random() * colorsNotUsed.length)]
        } else {
          randomColor = allColors[Math.floor(Math.random() * allColors.length)]
        }
        this.statusColor = randomColor
      }

      this.$store.dispatch('createStatus', {
        projectId: this.$store.state.projects.currentProjectId,
        name: this.statusName,
        order: orderForCreatedStatus,
        color: this.statusColor
      })

      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.temp-status {
  display: flex;
  align-items: center;
}

.temp-status-name-input {
  font-size: 14px;
  font-weight: 600;
  width: 160px;
  margin-right: 6px;
  margin-left: 6px;
}
.temp-status-name-input::placeholder {
  font-size: 14px;
  font-weight: 400;
}

</style>
