<template>
  <div class="ticket-modal-comment">
    <template v-if="ticket.comments">
      <div class="comments-count">
        Comments ({{ ticket.comments.length }})
      </div>
      <comment-base
        v-for="(comment) in ticket.comments"
        :comment="comment"
        :key="comment.id"
        :ticket-id="ticket.id"
      />
    </template>

    <div class="new-comment">
      <div class="new-comment-container">
        <textarea
          class="global-input-resizeable new-comment-textarea"
          placeholder="Write comment"
          tabindex="1"
          ref="textarea"
          v-model="commentText"
          @input="resizeTextarea()"
        />
        <div class="new-comment-button-bar">
          <swift-button
            @click.native="createComment($event.target)"
            color="blue"
            :is-loading="commentButtonIsLoading"
          >Comment</swift-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentBase from './CommentBase.vue'
import SwiftButton from './SwiftButton.vue'

export default {
  components: {
    CommentBase,
    SwiftButton
  },
  data: function () {
    return {
      commentText: '',
      commentButtonIsLoading: false
    }
  },
  computed: {
    ticket () {
      return this.$store.getters.getTicket
    }
  },
  methods: {
    async createComment (clickTarget) {
      // check if comment is empty
      if (!this.commentText) {
        return
      }

      this.commentButtonIsLoading = true
      await this.$store.dispatch('createComment', {
        ticketId: this.ticket.id,
        authorId: this.$store.state.user.user.id,
        text: this.commentText
      })
      clickTarget.blur()
      this.commentText = ''
      this.commentButtonIsLoading = false
      // resize the new textarea after it's being rendered
      this.$nextTick(() => this.resizeTextarea())
    },
    resizeTextarea () {
      this.$refs.textarea.style.height = ''
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px'
    }

  }
}
</script>

<style scoped>
.ticket-modal-comment {
    margin-top: 5px;
    margin-left: 35px;
    margin-right: 35px;
    margin-bottom: 20px;
}

.comments-count {
    color: #9ba8bb;
    margin-bottom: 15px;
}
.new-comment {
    border-radius: 4px;
    display: flex;
}
.new-comment-container {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.new-comment-textarea {
  height: 60px;
}
.new-comment-textarea:hover {
  border: 1px solid #9ba8bb;
}

.new-comment-textarea:focus {
  border: 1px solid #1764d9;
  box-shadow: 0 0 0 2px #c7ddff;
  /* ensures shadows overlays surroundign element */
  z-index: 1;
}

.new-comment-button-bar {
  display: flex;
  padding: 5px;
  justify-content: flex-end;
}

</style>
