<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 3700 716"
    xmlns="http://www.w3.org/2000/svg">
    <g :fill="iconFill">
      <path d="M729.3,276.4H122.2c0,0,0,71.4,71.4,71.4h448.2c52.1,0,87.5,0,87.5,0c9.9,0,17.9,8,17.9,17.9 c0,9.8-8,17.9-17.9,17.9h-500c0,0,0,71.4,71.4,71.4H642c51,0,87.4,0,87.4,0c49.2,0,89.3-40.1,89.3-89.3 C818.6,316.5,778.5,276.4,729.3,276.4"/>
      <path d="M729.3,169.3H50.7c0,0,0,71.4,71.5,71.4h455.7c87.1,0,151.4,0,151.4,0c68.9,0,125,56.1,125,125 c0,68.9-56.1,125-125,125H390c0,0,0,71.4,71.4,71.4c71.5,0,267.9,0,267.9,0c108.3,0,196.4-88.1,196.4-196.4 C925.7,257.4,837.6,169.3,729.3,169.3"/>
      <path d="M729.3,44.3c-86.2,0-164.5,34-222.2,89.3h222.2c128,0,232.1,104.2,232.1,232.2 c0,128-104.1,232.1-232.1,232.1H507.2c57.7,55.3,135.9,89.3,222.2,89.3c177.5,0,321.4-144,321.4-321.5 C1050.7,188.1,906.9,44.3,729.3,44.3"/>
    </g>
    <g :fill="textFill">
      <path d="m1342.3 567.6c-19.6 0-39.3-1.6-59.3-4.6-19.9-3.1-38.5-7.6-55.8-13.4v-63.9c12 4.1 24.6 7.5 37.9 10.2s26.5 4.8 39.7 6.2c13.2 1.5 25.7 2.2 37.5 2.2 31.2 0 53.6-5.2 67.4-15.7 13.7-10.5 20.6-25.3 20.6-44.4 0-16.5-5.1-29.2-15.3-38.2s-28.6-16.9-55.3-23.7l-29-7.6c-37.2-9.5-65.2-24.1-84.1-43.7-18.9-19.7-28.3-45.6-28.3-77.7 0-39.5 14.1-70.5 42.4-93s70.7-33.7 127.2-33.7c18.4 0 36.2 1.1 53.3 3.3s32.5 5.4 46 9.4v63.9c-14.1-4.5-29.4-7.8-45.9-10s-32.9-3.3-49.4-3.3c-23.4 0-42.2 2.4-56.2 7.3-14 4.8-24.2 11.7-30.5 20.5s-9.4 19.3-9.4 31.5c0 15.5 4.6 27.6 13.8 36.5 9.2 8.8 25.8 16.4 49.8 22.8l29 7.6c27.3 6.8 49.8 15.6 67.5 26.6 17.7 10.9 30.8 24.3 39.4 40.1 8.5 15.8 12.8 34.5 12.8 56.2 0 26.9-6.6 50-19.8 69.1-13.2 19.2-32.1 33.9-56.8 44.2-24.6 10.2-54.3 15.3-89.2 15.3zm283.8-5.5-88-316.9h72.9l64.5 244.3h-4.6l68.8-244.3h85.7l68 244.3h-5.8l65.9-244.3h70l-87.4 316.9h-85.7l-71.5-253h5.2l-72.3 253h-85.7zm487.8-371c-16.3 0-28.4-3.7-36.5-11-8-7.4-12.1-17.5-12.1-30.5 0-13.2 4-23.4 12.1-30.6 8-7.3 20.2-10.9 36.5-10.9 16.5 0 28.7 3.6 36.6 10.9s11.9 17.5 11.9 30.6c0 13-4 23.1-11.9 30.5-8 7.4-20.2 11-36.6 11zm-38.4 371v-316.9h76.4v316.9h-76.4zm121.1-256.2v-60.7h219.4v60.7h-219.4zm54.4 256.2v-348.3c0-26.9 5-49.8 15.1-68.7s25-33.3 44.7-43.3c19.8-10 44.1-15 72.9-15 7.9 0 15.6 0.3 23.1 0.9s14.8 1.5 21.9 2.6v60.7c-6-1-11.8-1.7-17.3-2.2s-11.2-0.7-17-0.7c-23.2 0-40.3 4.9-51.3 14.7-10.9 9.8-16.4 25.6-16.4 47.5v351.8h-75.7z"/>
      <path d="m2395.9 305.9v-60.7h219.6v60.7h-219.6zm167.6 262c-36.2 0-64.2-9.1-83.8-27.3-19.7-18.2-29.5-47.2-29.5-86.9v-208.5l13.7-113h62.7v309.7c0 23.4 4.5 39.7 13.7 48.8 9.1 9.1 23.4 13.7 43 13.7 5.4 0 10.9-0.3 16.6-1 5.6-0.7 11.8-1.7 18.6-3.1v61.3c-8.1 1.7-17 3.2-26.6 4.5-9.6 1.2-19.1 1.8-28.4 1.8zm216.8 0c-19 0-38.3-1.4-58.1-4.1s-38.3-6.9-55.5-12.5v-458.6h76.4v184.2h3.8c9.5-11.2 21.3-20.1 35.4-26.7s30.3-9.9 48.5-9.9c22.1 0 42.4 5.3 61 16s33.5 27.5 44.6 50.5 16.7 53.3 16.7 90.6c0 56.4-14.4 98.9-43.1 127.5-28.8 28.7-72 43-129.7 43zm6.3-59c29.4 0 51.7-8.1 66.8-24.4s22.7-43.2 22.7-80.8c0-26.1-3.1-46.5-9.3-61s-14.8-24.7-25.7-30.5-23.6-8.7-37.9-8.7c-11 0-21.9 2-32.5 6.1-10.7 4.1-19.9 10.4-27.6 18.9v174.9c5.8 1.6 12.5 2.9 20 3.9 7.6 1.1 15.4 1.6 23.5 1.6zm330.6 58.7c-20.7 0-39.1-4-55.1-11.9s-28.5-20.7-37.6-38.2-13.7-40.8-13.7-69.9v-202.4h76.1v197.8c0 21.9 4.7 37.1 14.1 45.6s21.6 12.8 36.7 12.8c7.6 0 15.4-1.1 23.7-3.3 8.2-2.2 16.1-5.7 23.7-10.5 7.6-4.7 14.2-10.7 20-17.9v-224.5h76.4v316.9h-63.3l-5.2-32.8h-3.5c-12.4 12.6-26.5 22.1-42.4 28.6-15.8 6.5-32.4 9.7-49.9 9.7zm347.7 128.1c-15.9 0-32.7-1.1-50.4-3.2s-34.7-5.5-51-10.2v-60.7c17.2 5 34 8.7 50.3 11s31.5 3.5 45.6 3.5c32.1 0 55.6-6.3 70.4-19s22.2-34.1 22.2-64.3v-24.4h-3.8c-9.3 11-21 19.9-35.2 26.4-14.1 6.6-30.4 9.9-48.8 9.9-21.7 0-41.8-5.3-60.4-16-18.6-10.6-33.6-27.3-44.9-50s-17-52.2-17-88.6c0-55.6 14.4-97.8 43.3-126.7s72.1-43.3 129.9-43.3c12.6 0 25.6 0.6 38.9 1.9 13.4 1.3 26.4 3.2 39.1 5.7s24.4 5.5 35.3 9v282.4c0 55.4-13.5 95.3-40.5 119.8-26.9 24.6-68 36.8-123 36.8zm27.1-193.7c11 0 21.9-2 32.7-6.1 10.7-4.1 19.9-10.5 27.5-19.2v-172.6c-5.6-1.5-12.2-2.8-19.8-3.8s-15.5-1.5-23.8-1.5c-28.9 0-51 8.1-66.4 24.3s-23.1 42.9-23.1 80.3c0 25.6 3.1 45.5 9.3 59.8s14.8 24.4 25.7 30.1c10.9 5.8 23.5 8.7 37.9 8.7z"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    iconFill: {
      type: String,
      default: '#1764d9'
    },
    textFill: {
      type: String,
      default: '#36404e'
    }
  }
}
</script>

<style scoped>
</style>
