<template>
  <div class="swift-select">
    <swift-dropdown>
      <template slot="dropdown-button">
        <div class="selected-value" v-if="!selectedOption">Please select</div>
        <div
          v-else
          class="selected-value">
          <div class="select-item">
            <div class="select-item-name">
              {{ selectedOption }}
            </div>
          </div>
        </div>
        <icon-sort-down
          width="14px"
          height="14px"
          fill="#4b586c" />
      </template>
      <swift-dropdown-item
        v-for="option in options"
        :key="option.index"
        :value="option"
        @selected="selectOption"
        slot="dropdown-items">
        <div class="select-item">
          <div class="select-item-name">
            {{ option }}
          </div>
        </div>
      </swift-dropdown-item>
    </swift-dropdown>
  </div>
</template>

<script>
import SwiftDropdown from './SwiftDropdown.vue'
import IconSortDown from './icons/IconSortDown.vue'
import SwiftDropdownItem from './SwiftDropdownItem.vue'

export default {
  components: {
    SwiftDropdown,
    IconSortDown,
    SwiftDropdownItem
  },
  props: ['options', 'initialOption'],
  data () {
    return {
      selectedOption: ''
    }
  },
  mounted () {
    if (this.initialOption) {
      this.selectOption(this.initialOption)
    }
  },
  methods: {
    selectOption (option) {
      this.selectedOption = option
      this.$emit('selected', option)
    }
  }
}
</script>

<style scoped>
.swift-select {
  width: 100%;
}
.select-item {
  display: flex;
  align-items: center;
}

.selected-value {
  display: flex;
  align-items: center;
}

</style>
