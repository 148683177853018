<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 1792 1792"
    xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="fill"
      d="M1216 832c0-123.334-43.833-228.834-131.5-316.5C996.833 427.833 891.333 384 768 384s-228.833 43.833-316.5 131.5C363.833 603.166 320 708.666 320 832c0 123.333 43.833 228.833 131.5 316.5C539.167 1236.166 644.667 1280 768 1280s228.833-43.834 316.5-131.5c87.667-87.667 131.5-193.167 131.5-316.5zm512 832c0 34.666-12.667 64.666-38 90-25.333 25.333-55.333 38-90 38-36 0-66-12.667-90-38l-343-342c-119.333 82.666-252.333 124-399 124-95.333 0-186.5-18.5-273.5-55.5s-162-87-225-150-113-138-150-225S64 927.333 64 832c0-95.334 18.5-186.5 55.5-273.5s87-162 150-225 138-113 225-150S672.667 128 768 128s186.5 18.5 273.5 55.5 162 87 225 150 113 138 150 225S1472 736.666 1472 832c0 146.666-41.333 279.666-124 399l343 343c24.667 24.666 37 54.666 37 90z"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: '#4b586c'
    }
  }
}
</script>

<style scoped>
</style>
