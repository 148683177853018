<template>
  <div class="statustab">
    <div
      class="statustab-status status-all"
      @click="filterByStatus(null)"
      :class="{ 'active-all': !$route.query.status }"
    >
      <div class="statustab-status-name">
        All
      </div>
      <div class="statustab-status-amount">
        {{ totalTickets }}
      </div>
    </div>
    <div
      class="statustab-status"
      draggable="true"
      @dragstart="dragStart(status.id, $event)"
      @dragover.prevent="dragOver($event)"
      @drop="drop(status.id)"
      @dragend="dragEnd(status.id)"
      @dragenter="dragEnter($event)"
      v-for="status in sortedStatuses"
      :key="status.id"
      @click="filterByStatus(status.id)"
      :class="{ 'active': $route.query.status == status.id, 'dragging': checkIfStatusIsDragged(status.id)}"
    >
      <div class="statustab-status-name" v-if="editableStatusNameId !== status.id">
        {{ status.name }}
      </div>
      <div class="status-name-edit" v-if="editableStatusNameId == status.id">
        <input
          class="global-input editable-status-name-input"
          name="status"
          type="text"
          v-model="editableStatusName"
          autocomplete="off"
          ref="name"
          @keyup.enter="$event.target.blur()"
          @blur="updateStatusName()"
        >
      </div>
      <div
        class="statustab-status-amount"
        :style="{ 'background-color': status.color}"         >
        <transition name="bounce" mode="out-in">
          <span :key="status.count">
            {{ status.count }}
          </span>
        </transition>
      </div>
      <div class="statustab-edit" :class="{visible: $route.query.status == status.id}">
        <ticket-list-statuses-edit @edit-name="makeStatusNameEditable" :status="status" />
      </div>
    </div>
    <div class="statustab-add" key="key2">
      <div
        class="add-status"
        v-if="!showCreateStatus"
        @click="showCreateStatus = true"
        type="button"
        key="fade1">
        + Add Status
      </div>
      <ticket-list-statuses-create v-if="showCreateStatus" @close="showCreateStatus = false" />
    </div>
  </div>

</template>

<script>
import TicketListStatusesCreate from './TicketListStatusesCreate.vue'
import TicketListStatusesEdit from './TicketListStatusesEdit.vue'

export default {
  components: {
    TicketListStatusesCreate,
    TicketListStatusesEdit
  },
  data: function () {
    return {
      currentDragStatusId: '',
      showEditStatusForId: null,
      showCreateStatus: false,
      editableStatusNameId: null,
      editableStatusName: ''
    }
  },
  computed: {
    sortedStatuses () {
      return this.$store.getters.sortedStatuses
    },
    totalTickets () {
      return this.$store.getters.totalTickets
    },
    getStatusById () {
      return this.$store.getters.getStatusById
    }
  },
  methods: {
    filterByStatus (statusId) {
      // already on this status
      if (statusId === parseInt(this.$route.query.status)) return

      // If 'all' status remove status from query
      if (statusId === null) {
        if (!this.$route.query.status) return // already on this status

        let query = Object.assign({}, this.$route.query)
        delete query.status
        query.page = 1
        this.$router.push({ query })
        return
      }

      // new status
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          status: statusId
        } })
    },
    checkIfStatusIsDragged (statusId) {
      if (this.currentDragStatusId === statusId) {
        return true
      } else {
        return false
      }
    },
    dragStart (statusId, event) {
      this.currentDragStatusId = statusId
      event.dataTransfer.setData('text', '') // this gives the datatransfer empty data to make it work in firefox
    },
    dragOver () {
      // don't delete this empty function because the prevent modifier on the event prevents the forbidden symbol when dragging over a dragable area
    },
    dragEnter (event) {
    },
    drop (statusIdDroppedOn) {
      let orderOfDroppedOnStatus = this.getStatusById(statusIdDroppedOn).order
      let orderOfDraggedStatus = this.getStatusById(this.currentDragStatusId).order
      if (orderOfDroppedOnStatus < orderOfDraggedStatus) {
        // if dropped on order is lower this will move all statuses between the dropped one and the dragged one, one place to the right
        this.$store.dispatch('moveOrderOfStatusesToRight', { orderOfDraggedStatus, orderOfDroppedOnStatus, droppedStatus: this.getStatusById(this.currentDragStatusId) })
        // gives status that was dropped the order of the one it was dropped on
      } else {
        // if dropped on order is higher this will move all statuses between the dropped one and the dragged one, one place to the left
        this.$store.dispatch('moveOrderOfStatusesToLeft', { orderOfDraggedStatus, orderOfDroppedOnStatus, droppedStatus: this.getStatusById(this.currentDragStatusId) })
        // gives status that was dropped the order of the one it was dropped on
      }
    },
    dragEnd (statusId) {
      this.currentDragStatusId = ''
    },
    makeStatusNameEditable (status) {
      this.editableStatusName = status.name
      this.editableStatusNameId = status.id
      this.$nextTick(() => this.$refs.name[0].focus())
    },
    async updateStatusName () {
      // check if name changed or empty
      if (this.editableStatusName === this.getStatusById(this.editableStatusNameId)) {
        this.editableStatusNameId = null
        return
      }
      if (this.editableStatusName === '') {
        this.editableStatusNameId = null
        return
      }

      var updatedStatus = {
        id: this.editableStatusNameId,
        name: this.editableStatusName
      }
      await this.$store.dispatch('updateStatus', updatedStatus)
      this.editableStatusNameId = null
    }
  }
}
</script>

<style scoped>
.statustab {
  display: flex;
  background-color: #e6eaef;
  align-items: center;
  flex-wrap: wrap;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
@media (max-width: 600px) {
    .statustab {
        padding-left: 0px;
    }
}

.statustab-status {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  height: 100%;
}

.statustab-status:hover:not(.active):not(.active-all) {
  background-color: hsla(216, 0%, 100%, 0.5);
}

.dragging {
  opacity: 0.3;
}

.active {
  cursor: grab;
  background: #ffffff;
  padding-right: 19px;
  padding-left: 10px;
  margin-right: -9px;
  margin-left: 0px;
  z-index: 1;
}

.status-all {
  padding-right: 9px;
  padding-left: 12px;
  margin-right: -3px;

}
.active-all {
  background-color: #ffffff;
  z-index: 1;
}

.statustab-status-name {
  margin-right: 5px;
  text-align: center;
}
.editable-status-name-input {
  font-size: 14px;
  font-weight: 600;
  max-width: 130px;
  margin-right: 6px;
  height: 32px;
  margin-top: -5px;
  margin-bottom: -5px;
}

.statustab-status-amount {
  display: flex;
  height: 22px;
  min-width: 22px;
  align-items: center;
  background-color: #1764d9;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 13px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 0px;
  padding-bottom: 1px;
  border-radius: 50px;
}

.statustab-edit {
  display: none;
  height: 22px;
}

.statustab-add {
  display: flex;
  height: 42px;
  padding-right: 5px;
  padding-left: 10px;
  align-items: center;
}
.add-status {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: #9ba8bb;
  cursor: pointer;
}
.add-status:hover {
  color: #1764d9;
  transition-duration: 0.3s;
}

.visible {
  display: flex;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

</style>
