<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 1792 1792"
    xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="fill"
      d="M896 384c-136 0-263.167 23.166-381.5 69.5-118.333 46.333-212.333 108.833-282 187.5C162.833 719.666 128 804.666 128 896c0 74.666 23.833 145.833 71.5 213.5 47.667 67.666 114.833 126.166 201.5 175.5l87 50-27 96c-16 60.666-39.333 118-70 172 101.333-42 193-99 275-171l43-38 57 6c46 5.333 89.333 8 130 8 136 0 263.167-23.167 381.5-69.5 118.333-46.334 212.333-108.834 282-187.5 69.667-78.667 104.5-163.667 104.5-255 0-91.334-34.833-176.334-104.5-255-69.667-78.667-163.667-141.167-282-187.5C1159.167 407.166 1032 384 896 384zm896 512c0 116-40 223.166-120 321.5-80 98.333-188.667 176-326 233s-287.333 85.5-450 85.5c-46.667 0-95-2.667-145-8-132 116.666-285.333 197.333-460 242-32.667 9.333-70.667 16.666-114 22h-5c-10 0-19-3.5-27-10.5s-13.333-16.167-16-27.5v-1c-2-2.667-2.167-6.667-.5-12 1.667-5.334 2.333-8.667 2-10-.333-1.334 1.167-4.5 4.5-9.5l6-9 7-8.5 8-9c4.667-5.334 15-16.834 31-34.5 16-17.667 27.5-30.334 34.5-38 7-7.667 17.333-20.834 31-39.5 13.667-18.667 24.5-35.667 32.5-51 8-15.334 17-35 27-59s18.667-49.334 26-76c-104.667-59.334-187.167-132.667-247.5-220C30.167 1089.666 0 996 0 896c0-116 40-223.167 120-321.5 80-98.334 188.667-176 326-233S733.333 256 896 256s312.667 28.5 450 85.5 246 134.666 326 233c80 98.333 120 205.5 120 321.5z"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: '#4b586c'
    }
  }
}
</script>

<style scoped>
</style>
