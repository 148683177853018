import TicketsApi from '../../api/TicketsApi.js'
import CommentsApi from '../../api/CommentsApi.js'
import router from '../../router.js'

const state = {
  tickets: [],
  ticketsAreLoaded: false,
  ticket: null,
  ticketIsLoaded: false,
  totalTickets: null,
  pagination: {
    currentPage: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    nextPage: null,
    previousPage: null,
    lastPage: null
  }

}

const getters = {
  totalTickets: state => {
    return state.totalTickets
  },
  getTicket: (state) => {
    return state.ticket
  },
  getTicketById: (state) => (ticketId) => {
    return state.tickets.find(element => element.id === ticketId)
  }
}

const mutations = {
  saveTickets: (state, tickets) => {
    state.tickets = tickets
    state.ticketsAreLoaded = true
  },
  saveTicket: (state, ticket) => {
    state.ticket = ticket
    state.ticketIsLoaded = true
  },
  setTicket: (state, ticketId) => {
    state.ticket = state.tickets.find(element => element.id === ticketId)
  },
  saveTotalTickets: (state, totalTickets) => {
    state.totalTickets = totalTickets
  },
  saveTicketsPagination: (state, pagination) => {
    state.pagination = pagination
  },
  setTicketsAreLoaded: (state, boolean) => {
    state.ticketsAreLoaded = boolean
  },
  setTicketIsLoaded: (state, boolean) => {
    state.ticketIsLoaded = boolean
  },
  resetTicketsToNull: (state) => {
    state.tickets = []
  }
}

const actions = {
  async getTicketsStatusesPrioritiesOfProject ({ commit, rootState }, params) {
    const response = (await TicketsApi.getTicketsStatusesPrioritiesOfProject(params)).data

    // check if user is still in the same project otherwise do not save
    if (params.projectId !== rootState.projects.currentProjectId) {
      return
    }

    commit('saveTickets', response.tickets)
    commit('saveTotalTickets', response.totalTickets)
    commit('saveTicketsPagination', response.pagination)
    commit('saveStatuses', response.statuses)
  },
  async updateStatusOfTicket ({ commit }, { newStatus, ticketId }) {
    // this needs to go trough an extra action and not directly trough updateTicket api call since we need to handel the null case
    const ticket = {
      id: ticketId
    }

    if (newStatus === null) {
      ticket.statusId = null
    } else {
      ticket.statusId = newStatus.id
    }
    await TicketsApi.updateTicket(ticket)
  },
  async updateAssigneeOfTicket ({ commit }, { newAssignee, ticketId }) {
    // this needs to go trough an extra action and not directly trough updateTicket api call since we need to handel the null case
    const ticket = {
      id: ticketId
    }

    if (newAssignee === null) {
      ticket.assigneeId = null
    } else {
      ticket.assigneeId = newAssignee.id
    }

    await TicketsApi.updateTicket(ticket)
  },
  async updatePriorityValueOfTicket ({ commit }, { newPriorityValue, ticketId }) {
    // this needs to go trough an extra action and not directly trough updateTicket api call since we need to handel the null case

    const ticket = {
      id: ticketId
    }

    if (newPriorityValue === null) {
      ticket.priorityValue = null
    } else {
      ticket.priorityValue = newPriorityValue
    }
    await TicketsApi.updateTicket(ticket)
  },
  async createComment ({ dispatch, state, rootState }, comment) {
    await CommentsApi.createComment({
      ticketId: comment.ticketId,
      authorId: comment.authorId,
      text: comment.text
    })
    await dispatch('updateTicketById', comment.ticketId)
  },
  async deleteTicketById ({ commit }, id) {
    await TicketsApi.deleteTicketById(id)
    router.push({ name: 'tickets' })
  },
  async getTicketById ({ commit }, ticketId) {
    commit('setTicketIsLoaded', false)
    const ticket = (await TicketsApi.getTicketById(ticketId)).data
    commit('saveTicket', ticket)
  },
  // same as getTickt but without setting ticketloaded to false
  async updateTicketById ({ commit }, ticketId) {
    const ticket = (await TicketsApi.getTicketById(ticketId)).data
    commit('saveTicket', ticket)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
