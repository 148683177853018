<template>
  <button
    class="dropdown-item"
    @click="$emit('selected', value)">
    <slot/>
  </button>
</template>

<script>
export default {
  props: ['value'],
  data: function () {
    return {
    }
  }
}
</script>

<style scoped>
.dropdown-item {
  background-color: #fff;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 4px;
  white-space: nowrap;
}

.dropdown-item:focus {
  background-color: #f5f9ff;
  cursor: pointer;
  color: #1764d9;
}

.dropdown-item:hover {
  background-color: #f5f9ff;
  cursor: pointer;
  color: #1764d9;
}
</style>
