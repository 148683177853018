import axios from 'axios'
import store from '@/store/store'

export default {
  createComment (comment) {
    return axios.post('/api/comments', comment, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  },
  updateComment (comment) {
    return axios.put(`/api/comments/${comment.id}`, comment, {
      headers: {
        Authorization: `Bearer ${store.state.authentication.token}`
      }
    })
  }
}
