<template>
  <div class="edit-status">
    <div class="dropdown-positioner">
      <swift-dropdown button-style="invisible-button">
        <template slot="dropdown-button">
          <div class="button-content" @mouseenter="editFillColor = '#1764d9'" @mouseleave="editFillColor = '#60718a'"><icon-ellipsis width="12px" height="12px" :fill="editFillColor" /></div>
        </template>
        <template slot="dropdown-items">
          <swift-dropdown-item @click.native="$emit('edit-name', status)">
            <icon-edit fill="#4b586c" width="14px" height="14px" />
            <div class="edit-name">
              Edit name
            </div>
          </swift-dropdown-item>
          <swift-dropdown-item @click.native.stop="confirmDelete()">
            <icon-delete fill="#4b586c" width="14px" height="14px" />
            <div class="edit-name">
              Delete
            </div>
            <portal v-if="showDeleteConfirmation === true">
              <div class="global-overlay-shadow" @click.stop.self="showDeleteConfirmation = false">
                <div class="global-modal global-confirmation-modal">
                  <div class="global-confirmation-modal-body global-border-bottom">
                    <div class="global-confirmation-modal-title">Delete this status permanently?</div>
                    <div class="confirmation-status-name">
                      {{ status.name }}
                    </div>
                    <div class="confirmation-status-count" v-if="status.count > 0">
                      {{ status.count }} ticket(s) will be reassigned to no current status
                    </div>

                  </div>
                  <div class="global-confirmation-modal-footer">
                    <swift-button color="blue" @click.native="deleteStatus()">Delete status</swift-button>
                    <swift-button color="link" @click.native="showDeleteConfirmation = false">Cancel</swift-button>
                  </div>
                </div>
              </div>
            </portal>
          </swift-dropdown-item>
          <div class="menu-divider">
            Colors
          </div>
          <swift-dropdown-item
            v-for="(color, colorName) in $store.state.colors" :key="color"
            @click.native="updateColorOfStatus(color)">
            <div class="color-box"  :style="{ backgroundColor: color }">
            </div>
            <div class="color-name">
              {{ colorName }}
            </div>
            <div class="color-checkmark" v-if="statusColor === color">
              <icon-checkmark fill="#4b586c" width="14px" height="14px"/>
            </div>
          </swift-dropdown-item>
        </template>
      </swift-dropdown>
    </div>
  </div>
</template>

<script>
import SwiftDropdown from './SwiftDropdown.vue'
import SwiftDropdownItem from './SwiftDropdownItem.vue'
import IconEdit from './icons/IconEdit.vue'
import IconEllipsis from './icons/IconEllipsis.vue'
import IconDelete from './icons/IconDelete.vue'
import IconCheckmark from './icons/IconCheckmark.vue'
import SwiftButton from './SwiftButton.vue'
import { Portal } from '@linusborg/vue-simple-portal'

export default {
  components: {
    SwiftDropdown,
    SwiftDropdownItem,
    IconEdit,
    IconDelete,
    IconEllipsis,
    IconCheckmark,
    SwiftButton,
    Portal
  },
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      statusColor: this.status.color,
      showDeleteConfirmation: false,
      editFillColor: '#60718a'

    }
  },
  methods: {
    confirmDelete () {
      this.showDeleteConfirmation = true
    },
    async deleteStatus () {
      await this.$store.dispatch('deleteStatusById', this.status.id)
    },
    async updateColorOfStatus (color) {
      this.statusColor = color

      if (this.statusColor === '') {
        this.statusColor = '#0dab54'
      }

      var updatedStatus = {
        id: this.status.id,
        color: this.statusColor
      }
      await this.$store.dispatch('updateStatus', updatedStatus)
    }
  }
}
</script>

<style scoped>
.edit-status {
  position: absolute;
}

.dropdown-positioner {
  position: relative;
  top: 3px;
  right: -1px;
}

.button-content {
  padding-left: 0px;
  padding-right: 0px;
  border: 0px solid #e6eaef;
  background-color: transparent;
  border-radius: 2px;
}

.edit-name {
  display: flex;
  width: 100px;
  margin-left: 5px;

}
.delete {
  display: flex;
  width: 100px;
  margin-left: 5px;
}

.color-box {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-checkmark {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.menu-divider {
  cursor: default;
  border-top: 1px solid #e0e4eb;
  color: #4b586c;
  font-size: 14px;
  font-weight: 600;
  padding-left: 12px;
  padding-top: 8px;
  margin-top: 5px;
}
.confirmation-status-name {
  font-weight: 600;
}
</style>
