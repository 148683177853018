import WidgetsApi from '../../api/WidgetsApi.js'

const state = {
  widgets: [],
  widgetsAreLoaded: false
}

const mutations = {
  saveWidgets: (state, widgets) => {
    state.widgets = widgets
    state.widgetsAreLoaded = true
  },
  setWidgetsAreLoaded: (state, boolean) => {
    state.widgetsAreLoaded = boolean
  }
}

const actions = {
  async getWidgetsOfProject ({ commit }, projectId) {
    commit('setWidgetsAreLoaded', false)
    const widgets = (await WidgetsApi.getWidgetsOfProject(projectId)).data
    commit('saveWidgets', widgets)
  },
  async updateWidget ({ commit, dispatch, state, rootState }, widget) {
    await WidgetsApi.updateWidget(widget)
    const widgets = (await WidgetsApi.getWidgetsOfProject(rootState.projects.currentProjectId)).data
    commit('saveWidgets', widgets)
  }
}

export default {
  state,
  mutations,
  actions
}
